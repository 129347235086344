import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'users',
		url: StaticRoutes.Users,
		icon: 'icon-people',
		role: roles.ADMIN,
		domain: 'admin',
		children: [
			{
				name: 'list',
				icon: 'icon-list',
				url: StaticRoutes.UsersList,
				domain: 'admin',
			},
			{
				name: 'add',
				icon: 'icon-plus',
				url: StaticRoutes.UsersAdd,
				role: roles.ADMIN,
				domain: 'admin',
			}
		]
	},
	{
		name: 'users',
		url: StaticRoutes.UsersList,
		icon: 'icon-people',
		role: [roles.EDITOR],
		domain: 'admin',
	},
];

export default menu;
