import React, { Component } from 'react';
import {
	Pagination as ReactstrapPagination,
	PaginationItem,
	PaginationLink,
	Row,
	Col,
	Input
} from 'reactstrap';
import PropTypes from 'prop-types';

class Pagination extends Component {

	render() {

		const unique = (arrArg) => arrArg.filter((elem, pos, arr) => arr.indexOf(elem) === pos);
		const spread_operator = '...';
		let { page, total, prevTxt, nextTxt, onClick, ...other } = this.props;
		if (total < 2)
			return null;
		const prev = Math.max(1, page - 1);
		const next = Math.min(total, page + 1);
		let prevValue = 0;
		let elements = [];
		unique([1, prev, page, next, total]).forEach((value) => {
			if (value !== prevValue + 1)
				elements.push(spread_operator);
			prevValue = value;
			elements.push(value);
		});

		return (
			<div {...other} style={{ width: 'fit-content' }}>
				<Row>
					<Col>
						<Input
							bsSize="sm"
							type="number"
							placeholder="Μετάβαση στην σελίδα"
							onChange={(e) => {
								const page_number = e.target.value
								setTimeout(() => {
									if (page_number < 1)
										onClick(1)
									else if (page_number > total)
										onClick(total)
									else
										onClick(page_number)
								}, 1500)
							}}
							min={1}
							max={total}
							style={{ width: '190px' }}
						/>
					</Col>
					<Col>
						<ReactstrapPagination>
							<PaginationItem disabled={page === 1}>
								<PaginationLink onClick={(e) => {
									onClick(prev);
								}}>
									{prevTxt ? prevTxt : '<'}
								</PaginationLink>
							</PaginationItem>
							{elements.map((elem, index) => (
								<PaginationItem key={'page_button_' + index} active={elem === page} disabled={elem === spread_operator}>
									<PaginationLink onClick={(e) => {
										onClick(elem);
									}}>
										{elem}
									</PaginationLink>
								</PaginationItem>
							))}
							<PaginationItem disabled={page === total}>
								<PaginationLink onClick={(e) => {
									onClick(next);
								}}>
									{nextTxt ? nextTxt : '>'}
								</PaginationLink>
							</PaginationItem>
						</ReactstrapPagination>
					</Col>
				</Row>
			</div>
		);

	}
}

Pagination.propTypes = {
	page: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired,
	onClick: PropTypes.func.isRequired,
	prevTxt: PropTypes.string,
	nextTxt: PropTypes.string,
};

Pagination.displayName = 'Pagination';

export default Pagination;
