import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Loading } from 'core/components';
import T from 'modules/i18n';

class BulkAssign extends Component {

	constructor(props) {
		super(props);
		this.state = {
			
		};
		
	}

	componentDidMount() {
		
	}

	componentDidUpdate(prevProps) {
		
	}

	

	render() {
		const { messages } = this.props.i18n || {messages: {}};
		const { pending, groups } = this.props;

		if (pending || !groups || !groups.data)
			return <Loading />;

		return (
			<div className="grouping">
				
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
	jobs: state.list.application.data.values,
	pending: state.list.application.pending,
	refreshing: state.list.application.refreshing,
	groups: state.list.groups,
	view: state.update.response,
	http_status: state.update.status,
});

BulkAssign = connect(mapStateToProps)(BulkAssign);

export default BulkAssign;
