import { StaticRoutes } from './model/routes';
import { StaticRoutes as CoreStaticRoutes } from 'core/model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'settings',
		url: CoreStaticRoutes.Settings,
		icon: 'icon-wrench',
		role: roles.ADMIN,
		domain: 'admin',
		children: [
			{
				name: 'oauth2',
				url: StaticRoutes.OAuthSettings,
				icon: 'fa fa-id-card-o',
				domain: 'admin',
				role: roles.ADMIN,
			}
		]
	},
];

export default menu;