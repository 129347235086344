import React, { Component } from 'react';
import { connect } from 'react-redux';
import Frame from 'react-frame-component';
import { Button, Row, Col } from 'reactstrap';

import { NodeGraph } from 'react-node-graph';
import { Loading } from 'core/components';
import { Sequence } from 'sequence';
import { requestData } from 'core/ducks/list';
import { updateData } from 'core/ducks/update';
import { pushNotification } from 'core/ducks/notifications';
import { getContent, setContent, resetContent } from 'core/ducks/forms';
import { StaticRoutes, routes } from '../../model/routes';
import { getParameters } from 'core/model/lib/urlTools';
import T from 'modules/i18n';

class EditWorkflows extends Component {

	constructor(props) {
		super(props);
		this.state = {
			nodes: [],
			connections: [],
			unsavedConnections: false,
			items: [],
		};

		this.emptyNode = {
			mname: '',
			label: '',
			type: '',
			content: '',
			workflow_content: '',
			content_text: '',
			options: {},
			content_type: 'I',
			transition: '',
		};

		this.frameRef = React.createRef();

		this.triggerMouseEvent = this.triggerMouseEvent.bind(this);
		this.handleStuctureSubmit = this.handleStuctureSubmit.bind(this);
		this.handleTreeSubmit = this.handleTreeSubmit.bind(this);
		this.handleNodeSelect = this.handleNodeSelect.bind(this);
		this.handleNodeDeselect = this.handleNodeDeselect.bind(this);
		this.saveAppearance = this.saveAppearance.bind(this);
	}

	componentDidMount() {
		let params = getParameters(this.props.location.pathname, routes);
		if (params.workflow) {
			this.requestData(params.workflow);
		} else {
			this.createWorkflowsSequence(this.props.workflow_list);
		}
		this.props.dispatch( setContent('node', this.emptyNode));
		this.props.dispatch( setContent('nodeReadOnly', {readOnly: false}) );
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (!Object.is(this.state.items, nextState.items))
			return true;
		if (
			!Object.is(this.props.forms, nextProps.forms) &&
			Object.is(this.state.nodes, nextState.nodes) &&
			Object.is(this.state.connections, nextState.connections)
		) {
			return false;
		} else {
			return true;
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			let params = getParameters(this.props.location.pathname, routes);
			this.props.dispatch( setContent('node', this.emptyNode));
			if (!params.workflow) {
				this.setState({
					nodes: [],
					connections: []
				});
				this.createWorkflowsSequence(this.props.workflow_list);
			} else {
				this.requestData(params.workflow);
			}
		}
		const { nodes, tree, graph } = this.props;
		if (
			(prevProps.nodes.status === '' || prevProps.tree.status === '' || prevProps.graph.status === '') &&
			(nodes.status === 200 && tree.status === 200 && graph.status === 200)
		) {
			const transformed = this.transform(nodes.data, graph.data);
			const connections = this.createConnections(tree.data);
			this.setState({
				nodes: transformed,
				connections
			});
		}
		if ( prevProps.submitting.node && !this.props.submitting.node && this.props.http_status === 200) {
			if (prevProps.post.node && !this.props.post.node) {
				let node = this.transform([this.props.forms.node]);
				this.setState({
					nodes: [...this.state.nodes, ...node]
				});
			} else {
				const newNode = this.props.forms.node;
				var keyChanged = false;
				var oldKey, newKey;
				let nodes = this.state.nodes.map((node) => {
					if (node.id !== newNode.id) {
						return node;
					} else {
						keyChanged = node.nid !== newNode.mname;
						oldKey = node.nid;
						newKey = newNode.mname;
						return {...this.transform([newNode])[0], x: node.x, y: node.y};
					}
				});
				if ( keyChanged ) {
					let connections = this.state.connections.map((connection) => {
						if (connection.from_node === oldKey) {
							return {...connection, from_node: newKey};
						} else if (connection.to_node === oldKey) {
							return {...connection, to_node: newKey};
						} else {
							return connection;
						}
					});
					this.setState({ nodes, connections });
				} else {
					this.setState({ nodes });
				}
			}
		}
		if (Object.keys(prevProps.workflow_list).length === 0 && Object.keys(this.props.workflow_list).length > 0)
			this.createWorkflowsSequence(this.props.workflow_list);
	}

	requestData(workflow) {
		this.props.dispatch( requestData('graph', `admin/graph/workflow/${workflow}`) );
		this.props.dispatch( requestData('nodes', `admin/nodes/workflow/${workflow}/sort/id`) );
		this.props.dispatch( requestData('tree', `admin/tree/workflow/${workflow}`) );
	}

	/**
	 * Trigger virtual mouse events.
	 *
	 * Mouse events coming from an iframe are not captured by default.
	 * This function triggers virtual mouse events by re-emitting some
	 * of the info contained in the real event.
	 *
	 * @param  {event} e The real event inside the iframe
	 * @return {void}
	 */
	triggerMouseEvent(e) {
		const offsetLeft = this.frameRef.current.node.offsetLeft;
		const offsetTop = this.frameRef.current.node.offsetTop;
		const event = new MouseEvent(e.type, {
			bubbles: true,
			clientX: e.clientX + offsetLeft,
			clientY: e.clientY + offsetTop,
		});
		this.frameRef.current.node.dispatchEvent(event);
	}

	/**
	 * Tranform the list of nodes to a format readable by react-node-graph.
	 *
	 * @param  {array} list       The node list
	 * @param  {object} positions An object containing the coordinates of each node
	 * @return {array}            The transformed array of nodes
	 */
	transform(list, positions=[]) {
		const length = list.length > 2 ? Math.floor(list.length/3) : 1;
		const nodes = list.map((node, index) => {
			let out;
			let posX = positions[node.mname]
				? positions[node.mname].pos_x
				: (index % length) * 220 + Math.floor(Math.random() * 50);
			let posY = positions[node.mname]
				? positions[node.mname].pos_y
				: Math.floor(index / length) * 150 + Math.floor(Math.random() * 50);
			if (node.type === 'categorical') {
				let options = node.options.constructor === {}.constructor ? node.options : JSON.parse(node.options);
				out = Object.keys(options).map((key) => ({
					name: key
				}));
			} else if (node.type === 'boolean') {
				out = [{name: 'true'}, {name: 'false'}];
			} else if (node.type === 'rf') {
				let { options } = node.options.constructor === {}.constructor ? node.options : JSON.parse(node.options);
				out = options.map(e => {
					const name = e.trim();
					return {name: name};
				});
			} else if (node.type === 'certificate') {
				out = [{name: 'positive'}, {name: 'negative'}];
			} else {
				out = [{name: 'out'}];
			}
			return {
				id: node.id,
				nid: node.mname,
				label: node.label,
				content: node.content ? node.content : (node.workflow_content ? node.workflow_content : node.transition),
				content_type: node.content_type,
				type: node.type,
				x: posX,
				y: posY,
				fields: {
					in: [{name:  'in'}],
					out,
				}
			}
		});

		return nodes;
	}

	/**
	 * Creates the spline connections to be used in the graph.
	 * @param  {object} data The connections in terms of parent node,
	 *                       value and child node.
	 * @return {array}       The connections in a format readable by react-graph-node
	 */
	createConnections(data) {
		let connections = Object.keys(data).map((id) => {
			let node = data[id];
			return {
				from_node: node.node,
				from: node.value==='' ? 'out' : node.value,
				to_node: node.child,
				to: 'in'
			};
		});
		this.numberOfInitialConnections = connections.length;

		return connections;
	}

	/**
	 * Submit the current position of each node to the API.
	 * @return {void}
	 */
	handleStuctureSubmit() {
		const { nodes } = this.state;
		const positions = nodes.map((node) => ({
			node: node.nid,
			pos_x: node.x,
			pos_y: node.y
		}));

		this.props.dispatch( updateData('admin/graph', positions) );
	}

	/**
	 * Perform a reverse transformation of the node connections
	 * in order to reconstruct the tree and submit it to server.
	 *
	 * @return {void}
	 */
	handleTreeSubmit() {
		const { dispatch } = this.props;
		const { connections } = this.state;
		const tree = connections.map((connection) => ({
			node: connection.from_node,
			value: connection.from==='out' ? '' : connection.from,
			child: connection.to_node
		}));

		dispatch( updateData('admin/tree', tree) ).then(r => {
			if (this.props.update_status === 200) {
				if (!r.valid)
					dispatch(pushNotification({body: 'not valid workflow', type: 'warning'}));
				dispatch(setContent('workflow', {valid: r.valid}));
				this.setState({unsavedConnections: false});
			}
		});
	}

	/**
	 * Handles the addition of a connector.
	 *
	 * In case the pair parent node - value already exists,
	 * it updates the child, otherwise it adds a new entry.
	 *
	 * @param  {string} fromNode The parent node
	 * @param  {string} fromPin  The starting pin in the parent node
	 * @param  {string} toNode   The child node
	 * @param  {string} toPin    The end point in the child node
	 * @return {void}
	 */
	onNewConnector(fromNode, fromPin, toNode, toPin) {
		let connections = [...this.state.connections];
		let found = false;
		connections = connections.map(connection => {
			if (connection.from_node === fromNode && connection.from === fromPin) {
				connection = {
					...connection,
					to_node: toNode,
					to: toPin
				};
				found = true;
			}
			return connection;
		});
		if (!found) {
			connections = [
				...connections,
				{
					from_node: fromNode,
					from: fromPin,
					to_node: toNode,
					to: toPin
				}
			];
		}

		this.setState({ connections, unsavedConnections: true });
	}

	/**
	 * Remove a connector.
	 *
	 * In case the connector already exists in the server, it just
	 * removes the value of the child node. This way the API will
	 * handle this situation as a delete request. In the opposite
	 * case that the connection has been created by the user but
	 * not submitted, it removes the entire row.
	 *
	 * @param  {object} connector
	 * @return {void}
	 */
	onRemoveConnector(connector) {
		let connections = [...this.state.connections];
		connections = connections.map((connection, index) => {
			if (connection.from_node === connector.from_node && connection.from === connector.from) {
				return index < this.numberOfInitialConnections ? {...connection, to_node: '', to: ''} : null;
			} else {
				return connection;
			}
		});
		connections = connections.filter(connection => connection!==null);

		this.setState({ connections, unsavedConnections: true });
	}

	/**
	 * In case a node is selected, it requests its data.
	 *
	 * @param  {string} nid The selected node (identifier)
	 * @return {void}
	 */
	handleNodeSelect(nid) {
		this.props.dispatch( getContent(`admin/nodes/mname/${nid}`, 'node') );
		let readOnly = this.state.unsavedConnections
			|| this.state.connections.some(connection => (connection.from_node===nid && connection.to_node!==''));
		this.props.dispatch( setContent('nodeReadOnly', {readOnly}) );
	}

	/**
	 * In case a node is deselected, it clears the content of this node.
	 *
	 * @param  {string} nid The selected node (identifier)
	 * @return {void}
	 */
	handleNodeDeselect(nid) {
		const permissions = ['author', 'editor', 'reviewer'].map(role => ({
			node: '',
			role,
			read: false,
			write: false,
		}));
		this.props.dispatch( resetContent('node'));
		this.props.dispatch( setContent('permissions', permissions));
		this.props.dispatch( setContent('node', this.emptyNode) );
		this.props.dispatch( setContent('nodeReadOnly', {readOnly: false}) );
	}

	createWorkflowsSequence(workflow_list) {
		const items = Object.keys(workflow_list)
			.filter((key) => {
				let workflow = workflow_list[key];
				return (workflow.type === 'public' && workflow.category === 'active' && !workflow.hidden && workflow.valid);
			})
			.map((key, index) => ({
				mname: key,
				name: workflow_list[key].name,
				description: workflow_list[key].description
			}));
		this.setState({items});
	}

	saveAppearance() {
		const items = this.state.items
			.map((item, index) => ({mname: item.mname, sequence: index}));
		this.props.dispatch( updateData('workflow', items) );
	}

	render() {
		const { nodes, tree, graph, match, workflow_list } = this.props;
		let isEmpty = (this.props.match.url === StaticRoutes.EditWorkflows);
		let refreshing = (nodes.refreshing || tree.refreshing || graph.refreshing);

		if (!workflow_list)
			return <Loading />;

		if (!match.params.workflow) {
			return (
				<React.Fragment>
					<Sequence items={this.state.items} onDragEnd={(items) => {this.setState({items})}} />
					<Row>
						<Col className="text-right pt-1 pb-3">
							<Button onClick={this.saveAppearance}><T>save structure</T></Button>
						</Col>
					</Row>
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<Frame
					ref={this.frameRef}
					id="draw_frame"
					head={<link rel="stylesheet" href="/static/css/node.css"/>}
					className={refreshing ? 'm-2 semi-transparent' : 'm-2'}
				>
					{ (!isEmpty && (nodes.pending || tree.pending || graph.pending || !this.frameRef.current)) ?
						<Loading />
						:
						<NodeGraph
							data={{nodes: this.state.nodes, connections: this.state.connections}}
							grid={[10, 10]}
							onNewConnector={(n1, o, n2, i) => this.onNewConnector(n1, o, n2, i)}
							onRemoveConnector={connector => this.onRemoveConnector(connector)}
							onNodeSelect={nid => {
								this.handleNodeSelect(nid);
							}}
							onNodeDeselect={nid => {
								this.handleNodeDeselect(nid);
							}}
							frameRef={this.frameRef.current}
							onMouseEvent={this.triggerMouseEvent}
						/>
					}
				</Frame>
				<Row>
					<Col className="text-right pt-1 pb-3">
						<Button disabled={isEmpty || refreshing} onClick={this.handleStuctureSubmit} className="mr-3" color="primary">
							<T>save structure</T>
						</Button>
						<Button disabled={isEmpty || refreshing} onClick={this.handleTreeSubmit} color="success">
							<T>save connections</T>
						</Button>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	nodes: state.list.nodes,
	tree: state.list.tree,
	workflow_list: state.list.workflow.data,
	graph: state.list.graph,
	submitting: state.forms.submitting,
	http_status: state.forms.status,
	update_status: state.update.status,
	update_message: state.update.message,
	forms: state.forms.content,
	post: state.forms.post,
});

EditWorkflows = connect(mapStateToProps)(EditWorkflows);

export default EditWorkflows;
