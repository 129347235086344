import React, { Component } from 'react';
import { Card, CardBody, CardText } from 'reactstrap';

class Delay extends Component {
	render() {
		const { node, className } = this.props;

		return (
			<Card className={className}>
				<CardBody>
					<CardText dangerouslySetInnerHTML={{__html: node.content_text}}/>
				</CardBody>
			</Card>
		);
	}
}

export default Delay;
