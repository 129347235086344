import { StaticRoutes } from './model/routes';
import * as roles from '../../core/model/roles';

const menu = [
	{
		name: 'crews',
		url: StaticRoutes.GroupsDefinition,
		icon: 'fa fa-group',
		role: [roles.ADMIN, roles.EDITOR],
		domain: 'admin'
	},
];

export default menu;
