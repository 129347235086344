import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import { buildPath } from 'core/model/lib/urlTools';
import { DynamicRoutes } from 'app/model/routes';
import * as roles from 'core/model/roles';

const detectFileType = (filename) => {
	if (!extension)
		return '';
	const extension = filename.split('.').pop().toLowerCase();
	if (extension === 'pdf')
		return '-pdf';
	if (['jpg', 'jpeg', 'png', 'tiff', 'tif', 'gif', 'raw', 'eps', 'bmp'].includes(extension))
		return '-image';
	if (extension === 'zip' || extension === 'gz')
		return '-archive';
	if (extension === 'doc' || extension === 'docx' || extension === 'ods')
		return '-word';
	if (extension === 'xls' || extension === 'xlsx' || extension === 'odt')
		return '-excel';
	if (extension === 'txt')
		return '-text';
	return '';
}

export const FileItem = ({filename, filesize, url, className}) => (
	<div
		className={className}
		role="link"
		onClick={() => {
			window.open(url, "_blank");
		}}
	>
		<i className={`d-block fa fa-file${detectFileType(filename)}-o fa-4x`}/>
		<span className="pt-2">{filename}</span><br/>
		<small className="text-muted">{filesize}</small >
	</div>
);

class MessageModal extends Component {

	render() {
		const { isOpen, toggle, body, created_at, attachments, sender, application_serial, application, application_round, role } = this.props;

		return (
			<Modal isOpen={isOpen} toggle={toggle} size="lg">
				<ModalBody className="pt-4 px-4">
					<div className="d-flex justify-content-between border-bottom">
						{ application &&
							<NavLink
								to={buildPath(role !== roles.AUTHORIZED ? DynamicRoutes.AdminApply : DynamicRoutes.Apply, [application_round, application])}
								className="d-inline-block"
								onClick={toggle}
							>
								{application_serial}
							</NavLink>
						}
						<div className="d-inline-block text-muted">{created_at}</div>
					</div>
					{ sender &&
						<p className="p-2 text-muted border-bottom">{sender}</p>
					}
					<article dangerouslySetInnerHTML={{__html: body}}/>
				</ModalBody>
				<ModalFooter className="justify-content-evenly">
					{ attachments.filter(file => file.filename).map((file, index) => (
						<FileItem key={`message_${created_at}_att_${file.filename}_${index}`} className="d-inline-block" {...file}/>
					))}
				</ModalFooter>
			</Modal>
		);
	}
}

MessageModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	body: PropTypes.string,
	created_at: PropTypes.string,
	attachments: PropTypes.array,
	sender: PropTypes.string,
	application: PropTypes.string,
	application_serial: PropTypes.string,
	application_round: PropTypes.string,
	role: PropTypes.string.isRequired,
};

MessageModal.defaultProps = {
	body: '',
	created_at: '',
	attachments: [],
}

export default MessageModal;
