import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CDropdown, CDropdownToggle, CBadge, CDropdownMenu, CDropdownHeader, CDropdownItem } from '@coreui/react';
import { cilEnvelopeOpen, cilEnvelopeClosed } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import truncate from 'truncate-html';
import { getData } from 'core/ducks/update';
import { acknowledge } from 'core/ducks/sse';
import * as roles from 'core/model/roles';
import { getMessages, readMessage, receivedNewMessage } from '../../ducks/messages';
import MessageModal from '../../components/messageModal';

import T from 'modules/i18n';

class MessageHeader extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isMessageModalOpen: false,
			activeMessageContent: {},
		};
	}

	componentDidMount() {
		if (this.props.role !== roles.GUEST)
			this.props.dispatch( getMessages() );
	}

	componentDidUpdate(prevProps) {
		if (this.props.role !== roles.GUEST && prevProps.events.length < this.props.events.length) {
			const { events, dispatch } = this.props;
			events.forEach(e => {
				const { id, event, ...message } = e;
				if (event !== 'message')
					return;
				dispatch(receivedNewMessage(message));
				dispatch(acknowledge(message.uuid));
			});
		}
	}

	closeMessageModal = () => {
		const { isMessageModalOpen, activeMessageContent } = this.state;
		if (isMessageModalOpen) {
			this.props.dispatch(readMessage(activeMessageContent.uuid));
			this.setState({
				isMessageModalOpen: false,
				activeMessageContent: {},
			});
		}
	}

	handleMessageSelect = (uuid) => {
		this.props.dispatch( getData(`appmessages/message/uuid/${uuid}`) )
			.then(activeMessageContent => {
				this.setState({
					isMessageModalOpen: true,
					activeMessageContent,
				});
			});
	}

	render() {
		if (this.props.role === roles.GUEST)
			return null;

		const { messages } = this.props;

		return (
			<CDropdown alignement="end" variant="nav-item">
				<CDropdownToggle caret={false} className="c-header-nav-link">
					<CIcon icon={messages.length > 0 ? cilEnvelopeClosed : cilEnvelopeOpen} className="my-1 mx-2" size="lg"/>
					{ messages.length > 0 &&
						<CBadge className="position-absolute top-0 end-0 mr-2" color="info" shape="rounded-pill">
							{messages.length}
						</CBadge>
					}
				</CDropdownToggle>
				<CDropdownMenu className="pt-0">
					<CDropdownHeader className="bg-light dark:bg-white dark:bg-opacity-10"><T>unread messages</T></CDropdownHeader>
					{ this.props.messages.map(message => (
						<CDropdownItem key={`dropdown-menu-messages-${message.uuid}`} onClick={() => this.handleMessageSelect(message.uuid)}>
							<div className="d-flex justify-content-between">
								<small className="text-medium-emphasis">
									{truncate(message.sender, 4, {byWords: true, reserveLastWord: true})}
									{ message.attachments > 0 &&
										<span><i className="fa fa-paperclip ml-2"/>{message.attachments}</span>
									}
								</small>
								<small className="ml-1 text-info">{message.created_at}</small>
							</div>
							<div dangerouslySetInnerHTML={{__html: truncate(message.body, 7, {stripTags: true, byWords: true, reserveLastWord: true})}}/>
						</CDropdownItem>
					))}
				</CDropdownMenu>
				<MessageModal isOpen={this.state.isMessageModalOpen} toggle={this.closeMessageModal} {...this.state.activeMessageContent} role={this.props.role}/>
			</CDropdown>
		);
	}
}

const mapStateToProps = (state) => ({
	messages: state.messages.unread,
	events: state.sse.events,
	role: state.profile.user.role,
});

MessageHeader = connect(mapStateToProps)(MessageHeader);

export default MessageHeader;
