import { Api } from '../api';

const INITIATE_STREAM = 'sse/STREAM_INITIATE';
const OPENED_STREAM = 'sse/STREAM_OPENED';
const UPDATE_EVENTS = 'sse/EVENTS_UPDATE';
const FAILED_STREAM = 'sse/STREAM_FAILED';
const CLOSE_STREAM = 'sse/STREAM_CLOSED';
const EVENT_ACKNOWLEDGE = 'sse/ACKNOWLEDGE_EVENT';

const initialState = {
	status: 'closed',
	active: false,
	events: [],
}

export default (state=initialState, action) => {
	switch (action.type) {

		case INITIATE_STREAM:
		case OPENED_STREAM:
		case FAILED_STREAM:
			return {
				...state,
				status: action.status,
				active: action.active,
			};

		case UPDATE_EVENTS:
			return {
				...state,
				events: [
					...state.events,
					action.event
				],
			};

		case CLOSE_STREAM:
			return {
				...state,
				status: 'closed',
				active: false,
			};

		case EVENT_ACKNOWLEDGE:
			return {
				...state,
				events: [...state.events.filter(e => e.uuid !== action.uuid)],
			};

		default:
			return state;
	}
}

const initiateStream = () => ({
	type: INITIATE_STREAM,
	status: 'pending',
	active: true,
});

const openedStream = () => ({
	type: OPENED_STREAM,
	status: 'open',
	active: true,
});

const failedStream = () => ({
	type: FAILED_STREAM,
	status: 'error',
	active: false,
});

const updateEvents = (event) => ({
	type: UPDATE_EVENTS,
	event,
});

const closeStream = () => ({
	type: CLOSE_STREAM,
});

export const acknowledge = (uuid) => ({
	type: EVENT_ACKNOWLEDGE,
	uuid,
});

export const closeSSE = () => (dispatch) => {
	dispatch(closeStream());
	let a = new Api('/sse?close=true', false);
	return a.Get();
}

export const listen = () => (dispatch, getState) => {
	if (getState().sse.active)
		return;

	const es = new EventSource('/sse', {withCredentials: true});
	dispatch(initiateStream(es));

	es.onopen = () => {
		dispatch(openedStream());
	}

	es.onerror = () => {
		dispatch(failedStream());
	}

	es.onmessage = (e) => {
		const { lastEventId, data } = e;
		dispatch(updateEvents({id: lastEventId, ...JSON.parse(data)}));
	}

	return es;
}
