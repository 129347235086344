import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, ModalBody, Card, CardBody, Progress } from 'reactstrap';
import { uploadData } from 'core/ducks/upload';
import NewMessage from '../../components/newMessage';

class NewMessageModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showProgress: false,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.progress < 100 && this.props.progress === 100)
			this.setState({
				showProgress: false,
			});
	}

	handleMessageSend = ({message, files}) => {
		if (!message || message === '')
			return;
		const formData = new FormData();
		formData.append('body', message);
		formData.append('application', this.props.application);
		files.forEach(file => {
			formData.append('files[]', file);
		});
		this.setState({showProgress: true}, () => {
			this.props.dispatch(uploadData('appmessages/message', formData, 'post', false))
				.then(() => this.props.onSuccess())
				.catch(error => {
					this.setState({showProgress: false});
					console.warn(error);
				});
		});
	}

	render() {
		const { isOpen, toggle, progress } = this.props;

		return (
			<Modal isOpen={isOpen} size="lg">
				<ModalBody>
					<Card className="my-2 mx-3">
						<CardBody className="p-1">
							<NewMessage disabled={this.state.showProgress} onMessageSend={this.handleMessageSend} onCancel={toggle}/>
						</CardBody>
					</Card>
				</ModalBody>
				{ this.state.showProgress &&
					<Progress animated color="success" value={progress} />
				}
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	progress: state.upload.progress,
});

NewMessageModal = connect(mapStateToProps)(NewMessageModal);

NewMessageModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	application: PropTypes.string.isRequired,
};

export default NewMessageModal;
