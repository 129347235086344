import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, ModalBody, Card, CardBody, CardFooter, Row, Col, Progress, Button } from 'reactstrap';
import { getData } from 'core/ducks/update';
import truncate from 'truncate-html';

import { uploadData } from 'core/ducks/upload';
import { FileItem } from '../../components/messageModal';
import NewMessage from '../../components/newMessage';
import { readMessage } from '../../ducks/messages';
import T from 'modules/i18n';

class Thread extends Component {

	constructor(props) {
		super(props);
		this.state = {
			messages: [],
			selectedMessage: '',
			messageContent: {},
			isNewMessageOpen: false,
			showProgress: false,
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.isOpen && this.props.isOpen)
			this.fetchData();
		if (prevProps.progress < 100 && this.props.progress === 100)
			this.setState({
				showProgress: false,
			});
	}

	fetchData = () => {
		this.props.dispatch( getData(`appmessages/message/application/${this.props.application}`) )
			.then(messages => this.setState({messages}));
	}

	handleMessageSend = ({message, files}) => {
		if (!message || message === '')
			return;
		const formData = new FormData();
		formData.append('body', message);
		formData.append('application', this.props.application);
		files.forEach(file => {
			formData.append('files[]', file);
		});
		this.setState({showProgress: true}, () => {
			this.props.dispatch(uploadData('appmessages/message', formData, 'post', false))
				.then(() => {
					this.props.dispatch( getData(`appmessages/message/application/${this.props.application}`) )
						.then(messages => this.setState({
							messages,
							isNewMessageOpen: false,
						}));
				})
				.catch(error => {
					this.setState({showProgress: false});
					console.warn(error);
				});
		});
	}

	handleMessageSelect = (selectedMessage) => {
		if (selectedMessage in this.state.messageContent) {
			this.setState({
				selectedMessage,
			});
			this.props.dispatch(readMessage(selectedMessage));
			return;
		}
		this.props.dispatch( getData(`appmessages/message/uuid/${selectedMessage}`) )
			.then(messageContent => {
				this.setState({
					selectedMessage,
					messageContent: {
						...this.state.messageContent,
						[selectedMessage]: messageContent
					}
				});
				this.props.dispatch(readMessage(selectedMessage));
			});
	}

	handleNewMessageClick = () => {
		this.setState({
			isNewMessageOpen: !this.state.isNewMessageOpen,
		});
	}

	render() {

		const { isOpen, toggle } = this.props;
		const { selectedMessage, messageContent } = this.state;

		return (
			<Modal isOpen={isOpen} toggle={toggle} size="xl" scrollable>
				<ModalBody>
					{ this.state.messages.map((message, index) => (
						<Card key={`thread_message_preview_${index}`} className={(message.sender === this.props.organization) ? 'mr-5 mb-2' : 'ml-5 mb-3'}>
								<small className="p-2 text-muted"><span>{message.sender}</span>{' '}<T>on</T>{' '}<span>{message.created_at}</span></small>
							{ selectedMessage === message.uuid ?
								<>
									<CardBody className="py-0">
										<article dangerouslySetInnerHTML={{__html: messageContent[message.uuid].body}}/>
									</CardBody>
									{ messageContent[message.uuid].attachments.filter(file => file.filename).length > 0 &&
										<CardFooter className="d-flex justify-content-start">
											{ messageContent[message.uuid].attachments.filter(file => file.filename).map((file, index) => (
												<FileItem key={`message_${message.created_at}_att_${file.filename}_${index}`} className="d-inline-block" {...file}/>
											))}
										</CardFooter>
									}
									<div className="text-right p-2" role="link" onClick={() => this.setState({selectedMessage: ''})}>...<T>less</T></div>
								</>
								:
								<>
									<CardBody className="py-0">
										<article dangerouslySetInnerHTML={{__html: truncate(message.body, 40, {byWords: true})}}/>
									</CardBody>
									<Row>
										<Col className="d-flex justify-content-end">
											{ message.attachments > 0 &&
												<div className="py-2 px-4 w-50 d-inline-block"><i className="fa fa-paperclip"/>{` ${message.attachments}`}</div>
											}
											<div className="text-right p-2 w-50 d-inline-block" role="link" onClick={() => this.handleMessageSelect(message.uuid)}>...<T>more</T></div>
										</Col>
									</Row>
								</>
							}
						</Card>
					))}
					{ this.state.isNewMessageOpen ?
						<Card className="ml-5">
							<NewMessage disabled={this.state.showProgress} onMessageSend={this.handleMessageSend} onCancel={this.handleNewMessageClick}/>
						</Card>
						:
						<Row>
							<Col className="d-flex justify-content-end">
								<Button outline color="warning" className="m-2" onClick={toggle}><i className="fa fa-times"/>{' '}<T>close</T></Button>
								<Button outline color="success" className="m-2" onClick={this.handleNewMessageClick}>
									<i className="fa fa-reply"/>{' '}<T>reply</T>
								</Button>
							</Col>
						</Row>
					}
				</ModalBody>
				{ this.state.showProgress &&
					<Progress animated color="success" value={this.props.progress} />
				}
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	organization: state.ui.settings.values.organization,
	progress: state.upload.progress,
});

Thread = connect(mapStateToProps)(Thread);

Thread.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	application: PropTypes.string.isRequired,
};

export default Thread;
