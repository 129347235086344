import * as roles from 'core/model/roles';
import { StaticRoutes as CoreStaticRoutes } from 'core/model/routes';

const ContentEdit = CoreStaticRoutes.Settings + '/content';

export const StaticRoutes = {
	ContentEdit,
};

const Content = '/content/:content';

export const DynamicRoutes = {
	Content,
};

export const routes = [
	{
		url: ContentEdit,
		name: 'content',
		role: roles.ADMIN,
		component: 'ContentEdit',
		contextComponent: 'ContentEditSidebar',
	},
	{
		url: Content,
		name: 'content',
		component: 'Content'
	},
];

const combined = {StaticRoutes, DynamicRoutes, routes};

export default combined;
