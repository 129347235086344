import * as roles from 'core/model/roles';
import { StaticRoutes as CoreStaticRoutes } from 'core/model/routes';

const OAuthSettings = CoreStaticRoutes.Settings + '/oauth';
const Register = CoreStaticRoutes.Account + '/register';

export const StaticRoutes = {
	OAuthSettings,
};

export const routes = [
	{
		url: OAuthSettings,
		name: 'oauth2',
		component: 'OAuthSettings',
		role: roles.ADMIN,
	},
	{
		url: Register,
		name: 'register',
		component: 'OAuthRegister',
	}
];

const combined = {StaticRoutes, routes};

export default combined;
