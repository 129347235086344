import { Api } from 'core/api';
import { pushNotification } from 'core/ducks/notifications';


// Actions

const REINDEX_REQUEST = 'api/reindex/START';
const REINDEX_FINISHED = 'api/reindex/FINISHED';
const REINDEX_FAILED = 'api/reindex/FAILED';


// Initial state

const initialState = {
	pending: false,
};


// Reducer

export default (state=initialState, action) => {
	switch (action.type) {
		case REINDEX_REQUEST:
		case REINDEX_FINISHED:
		case REINDEX_FAILED:
			return {
				...state,
				pending: action.pending
			};

		default:
			return state;
	}
}


// Action Creators

const requestingReindex = () => ({
	type: REINDEX_REQUEST,
	pending: true
});

const finishReindex = () => ({
	type: REINDEX_FINISHED,
	pending: false,
});

const failedReindex = () => ({
	type: REINDEX_FAILED,
	pending: false,
});


// Thunk action Creators

export const requestReindex = () => (dispatch) => {
	dispatch( requestingReindex() );
	let a = new Api('admin/reindex_db');
	let status = '';
	let promise = a.Get().then((response) => {
		status = response.status;
		return response.json();
	});
	promise.then((json) => {
		if (status === 200) {
			dispatch( finishReindex() );
			dispatch(pushNotification({body: 're-indexing finished', type: 'success'}));
		} else {
			dispatch( failedReindex() );
			dispatch(pushNotification({body: 're-indexing failed', type: 'warning'}));
		}
	});

	return promise;
};
