import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Collapse, FormGroup, Label, Input, FormText, InputGroup, InputGroupAddon } from 'reactstrap';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import { requestData } from 'core/ducks/list';

class ForeignConditionInput extends Component {

	constructor(props) {
		super(props);

		this.operators = ['==', '<=', '>=', '<', '>', '!='];

		this.key = nanoid();

		this.state = {
			isCollapseOpen: props.value !== '',
			values: {...this.splitValue(props.value)},
			fields: [],
			valid: undefined,
		}
	}

	splitValue = (completeValue) => {
		if (completeValue === '')
			return {form: '', field: '', operator: '', value: ''};
		const operator = this.operators.find(op => completeValue.includes(op));
		const [formfield, value] = completeValue.split(operator);
		const [form, field] = formfield.split('.');
		return {form, field, operator, value};
	}

	componentDidMount() {
		if (this.state.values.form !== '')
			this.getFormFields();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.values.form !== this.state.values.form)
			this.getFormFields();
	}

	getFormFields = () => {
		const { form } = this.state.values;
		this.props.dispatch(requestData('fields', `admin/forms/form/${form}/fields/name`))
			.then(fields => this.setState({fields}));
	}

	handleInputChange = (e) => {
		const { name, value } = e.target;
		this.setState({values: {
			...this.state.values,
			[name]: value,
		}}, () => {
			if (Object.values(this.state.values).filter(v => v === '').length === 0) {
				const { values } = this.state;
				const value = values.form + '.' + values.field + values.operator + values.value;
				this.props.onChange({target: {type: 'text', name: this.props.name, value}})
			}
		});
	}

	handleTogglerChange = (e) => {
		const { checked } = e.target;
		if (checked) {
			this.setState({isCollapseOpen: checked});
		} else {
			this.setState({isCollapseOpen: checked, values: {...this.splitValue('')}});
			this.props.onChange({target: {type: 'text', name: this.props.name, value: ''}});
		}
	}

	render() {
		const { isCollapseOpen, values } = this.state;
		return (
			<>
				<FormGroup>
					<Label check>
						<Input
							type="checkbox"
							checked={isCollapseOpen}
							onChange={this.handleTogglerChange}
						/>
						Show only under foreign form condition
					</Label>
				</FormGroup>
				<Collapse isOpen={isCollapseOpen}>
					<Row>
						<Col>
							<FormText>
								Hide this field in case condition is not fulfilled.
							</FormText>
							<FormGroup>
								<InputGroup>
									<Label>
										<Input
											type="select"
											name="form"
											value={values.form}
											onChange={this.handleInputChange}
										>
											<option value="">Select form</option>
											{ this.props.forms.map((form, index) => (
												<option key={`foreign-form-option-${index}-${this.key}`} value={form}>{form}</option>
											))}
										</Input>
									</Label>
									<Label>
										<InputGroupAddon addonType="prepend">.</InputGroupAddon>
									</Label>
									<Label>
										<Input
											type="select"
											name="field"
											value={values.field}
											onChange={this.handleInputChange}
										>
											<option value="">Select field</option>
											{ this.state.fields.map((field, index) => (
												<option key={`foreign-field-option-${index}-${this.key}`} value={field}>{field}</option>
											))}
										</Input>
									</Label>
									<Label>
										<Input
											type="select"
											name="operator"
											value={values.operator}
											onChange={this.handleInputChange}
										>
											<option value=""></option>
											{ this.operators.map((operation, index) => (
												<option key={`foreign-operation-option-${index}-${this.key}`} value={operation}>{operation}</option>
											))}
										</Input>
									</Label>
									<Label>
										<Input
											type="text"
											name="value"
											value={values.value}
											onChange={this.handleInputChange}
											valid={this.state.valid}
										/>
									</Label>
								</InputGroup>
							</FormGroup>
						</Col>
					</Row>
				</Collapse>
			</>
		);
	}
}

ForeignConditionInput.propTypes = {
	forms: PropTypes.array,
	name: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
}

ForeignConditionInput.defaultProps = {
	forms: [],
	name: '',
	value: '',
	onChange: () => {},
}

ForeignConditionInput = connect(null)(ForeignConditionInput);

export default ForeignConditionInput;
