// Actions

const INVITATIONS_MODAL_OPEN = 'app/state/OPEN_INVITATIONS_MODAL';
const INVITATIONS_MODAL_CLOSE = 'app/state/CLOSE_INVITATIONS_MODAL';
const INVITATIONS_MODAL_TOGGLE = 'app/state/TOGGLE_INVITATIONS_MODAL';


// Initial state

const initialState = {
	isInvitationsModalOpen: false,
	excludePostponed: true,
}


// Reducer

export default (state=initialState, action) => {
	switch (action.type) {
		case INVITATIONS_MODAL_OPEN:
		case INVITATIONS_MODAL_CLOSE:
			return {
				...state,
				isInvitationsModalOpen: action.isInvitationsModalOpen,
				excludePostponed: action.excludePostponed,
			}

		case INVITATIONS_MODAL_TOGGLE:
			return {
				...state,
				isInvitationsModalOpen: !state.isInvitationsModalOpen,
			}

		default:
			return state;
	}
}


// Action creators

export const openInvitationsModal = (excludePostponed=true) => ({
	type: INVITATIONS_MODAL_OPEN,
	isInvitationsModalOpen: true,
	excludePostponed,
});

export const closeInvitationsModal = () => ({
	type: INVITATIONS_MODAL_CLOSE,
	isInvitationsModalOpen: false,
	excludePostponed: true,
});

export const toggleInvitationsModal = () => ({
	type: INVITATIONS_MODAL_TOGGLE,
});
