import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Row, Col, Nav, NavItem, NavLink, TabContent, TabPane,
	Form, FormGroup, Input, Collapse, Button
} from 'reactstrap';

import { StaticRoutes, DynamicRoutes, routes } from '../../model/routes';
import { buildPath, getParameters } from 'core/model/lib/urlTools';
import { getData, postData } from 'core/ducks/update';
import T from 'modules/i18n';

class PdfTemplates extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTab: '0',
			currentWorkflow: '',
			currentTemplate: '',
			workflowList: [],
			templateList: [],
			newNameValue: '',
			isNewTemplateFormOpen: false,
		}
	}

	componentDidMount() {
		this.initiate();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			const { workflow, template } = getParameters(this.props.location.pathname, routes);
			this.setState({currentWorkflow: workflow, currentTemplate: template})
		}
		if (prevState.currentWorkflow !== this.state.currentWorkflow) {
			this.getTemplates(this.state.currentWorkflow);
		}
		if (prevState.currentTemplate !== this.state.currentTemplate) {
			const { currentWorkflow, currentTemplate } = this.state;
			if (!currentTemplate || currentTemplate === '') {
				this.props.history.push(StaticRoutes.PdfTemplates);
			} else {
				this.props.history.push(buildPath(DynamicRoutes.PdfTemplates, [currentWorkflow, currentTemplate]))
			}
		}
	}

	toggle = (tab) => {
		if (this.state.activeTab !== tab)
			this.setState({activeTab: tab});
	}

	handleWorkflowSelect = (event) => {
		this.setState({
			currentWorkflow: event.target.value,
			currentTemplate: '',
			templateList: [],
		});
	}

	getTemplates = (workflow) => {
		if (!workflow || workflow === '') {
			this.setState({currentWorkflow: '', templateList: []});
			return;
		}
		this.props.dispatch(getData(`admin/pdftemplate/names/${workflow}`))
			.then(templateList => this.setState({currentWorkflow: workflow, templateList, isNewTemplateFormOpen: templateList.length === 0}));
	}

	initiate() {
		this.props.dispatch( getData('flows/workflow/type/public/hidden/false/fields/name;mname') )
			.then(workflowList => this.setState({workflowList}));
		const { workflow, template } = getParameters(this.props.location.pathname, routes);
		this.setState({
			currentWorkflow: (workflow)
				? workflow
				: '',
			currentTemplate: (template)
				? template
				: '',
		});
	}

	handleNewTemplateSubmit = (e) => {
		e.preventDefault();
		const { currentWorkflow, newNameValue } = this.state;
		this.props.dispatch(postData('admin/pdftemplate', {workflow: currentWorkflow, name: newNameValue, html: ' '}))
			.then(() => {
				this.getTemplates(currentWorkflow);
				this.setState({currentTemplate: newNameValue, newNameValue: ''});
			});
	}

	render() {

		const { workflowList, isNewTemplateFormOpen, currentWorkflow } = this.state;
		const { messages } = this.props.i18n || {messages: {}};
		const TabHeader = () => (
			<Nav tabs>
				<NavItem>
					<NavLink
						className={this.state.activeTab==='0' ? 'active text-info p-2' : 'border border-secondary p-2'}
						onClick={() => { this.toggle('0'); }}
					>
						<T>flows</T>
					</NavLink>
				</NavItem>
			</Nav>
		);
		if ( workflowList.length === 0 )
			return (<TabHeader/>);

		return (
			<>
				<TabHeader/>
				<TabContent activeTab={this.state.activeTab} className="scroller mx-2 mt-2">
					<TabPane tabId="0">
						<FormGroup>
							<Input
								type="select"
								value={currentWorkflow}
								onChange={this.handleWorkflowSelect}
							>
								<option value="">---{messages['select workflow']}---</option>
								{ workflowList.map(({mname, name}) =>
									<option key={`workflows_${mname}`} value={mname}>
										{name}
									</option>
								) }
							</Input>
						</FormGroup>
						{ currentWorkflow && currentWorkflow !== '' &&
							<>
								<Collapse isOpen={!isNewTemplateFormOpen}>
									<Row className="mb-2">
										<Col>
											<p className="font-weight-bold"><T>templates</T></p>
											{ this.state.templateList.map(name => (
												<NavLink
													key={`template_list_${name}`}
													href={'#'}
													active={this.state.currentTemplate === name}
													onClick={() => this.setState({currentTemplate: name})}
												>
													{name}
												</NavLink>
											))}
										</Col>
									</Row>
									<Row>
										<Col className="d-flex justify-content-center">
											<Button color="primary" id="toggler" onClick={() => this.setState({isNewTemplateFormOpen: true})}><T>new</T></Button>
										</Col>
									</Row>
								</Collapse>
								<Collapse isOpen={isNewTemplateFormOpen}>
									<Form onSubmit={this.handleNewTemplateSubmit}>
										<FormGroup row>
											<Col>
												<Input
													placeholder={messages['new template name']}
													value={this.state.newNameValue}
													onChange={e => this.setState({newNameValue: e.target.value})}
												/>
											</Col>
										</FormGroup>
										<Row>
											<Col className="d-flex justify-content-evenly">
												<Button onClick={() => this.setState({isNewTemplateFormOpen: false, newNameValue: ''})} className="mx-2" color="warning">
													<T>cancel</T>
												</Button>
												<Button color="success" className="mx-2"><T>submit</T></Button>
											</Col>
										</Row>
									</Form>
								</Collapse>
							</>
						}
					</TabPane>
				</TabContent>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
});

PdfTemplates = connect(mapStateToProps)(PdfTemplates);

export default PdfTemplates;
