import { Api } from 'core/api';
import { checkCookie } from 'core/ducks/profile';
import { updateData } from 'core/ducks/update';


// Actions

const DEFAULT_PROFILE_REQUEST = 'app/profile/DEFAULT_REQUEST';
const FAILED_DEFAULT_PROFILE_REQUEST = 'app/profile/FAILED_DEFAULT_REQUEST';
const DEFAULT_PROFILE_FETCHED = 'app/profile/DEFAULT_FETCHED';
const PROFILE_UP_TO_DATE = 'app/profile/UP_TO_DATE';
const PROFILE_SHOULD_UPDATE = 'app/profile/SHOULD_UPDATE';
const POSTPONE_INVITATION = 'app/profile/INVITATION_POSTPONE';
const ACCEPT_INVITATION = 'app/profile/INVITATION_ACCEPT';
const REJECT_INVITATION = 'app/profile/INVITATION_REJECT';


// Initial state

const initialState = {
	userid: null,
	type: null,
	membership: [],
	active: null,
	activeLocality: null,
	roles: '',
	request: {
		status: null,
		pending: false,
		error: '',
		failed: false,
	},
	invitations: [],
	shouldUpdate: false,
	postponedInvitations: [],
	openApplications: [],
	pendingApplications: [],
};


// Reducer

export default (state=initialState, action) => {
	switch (action.type) {

		case DEFAULT_PROFILE_REQUEST:
		case FAILED_DEFAULT_PROFILE_REQUEST:
		case PROFILE_UP_TO_DATE:
			return {
				...state,
				request: {
					...state.request,
					...action.request,
				},
				shouldUpdate: false
			};

		case DEFAULT_PROFILE_FETCHED:
			const { userid, type, membership, active, activeLocality, roles, invitations, openApplications, pendingApplications } = action.profile;
			return {
				...state,
				userid,
				type,
				membership,
				active,
				activeLocality,
				roles,
				invitations,
				openApplications,
				pendingApplications,
				request: {
					status: 200,
					pending: false,
					error: initialState.request.error,
					failed: false,
				},
				shouldUpdate: false
			};

		case PROFILE_SHOULD_UPDATE:
			return {
				...state,
				shouldUpdate: action.shouldUpdate,
			};

		case POSTPONE_INVITATION:
			return {
				...state,
				postponedInvitations: [...state.postponedInvitations, action.identifier+action.role],
			}

		case REJECT_INVITATION:
			const { identifier, role } = action;
			return {
				...state,
				invitations: state.invitations
					.filter(invitation => (invitation.identifier !== identifier || invitation.role !== role)),
			}

		default:
			return state;
	}
};


// Action creators

const requestingDefaultProfile = () => ({
	type: DEFAULT_PROFILE_REQUEST,
	request: {
		status: initialState.request.status,
		error: initialState.request.error,
		pending: true,
	}
});

const defaultProfileRequestFailed = (status, error) => ({
	type: FAILED_DEFAULT_PROFILE_REQUEST,
	request: {
		status,
		error,
		pending: false,
		failed: true,
	}
});

const profileIsUpToDate = () => ({
	type: PROFILE_UP_TO_DATE,
	request: {
		pending: false,
	}
});

const fetchedDefaultProfile = (profile) => ({
	type: DEFAULT_PROFILE_FETCHED,
	profile
});

export const updateProfile = () => ({
	type: PROFILE_SHOULD_UPDATE,
	shouldUpdate: true,
});

export const postponeInvitation = (identifier, role) => ({
	type: POSTPONE_INVITATION,
	identifier,
	role,
});

const acceptingInvitation = () => ({
	type: ACCEPT_INVITATION,
});

const rejectingInvitation = (identifier, role) => ({
	type: REJECT_INVITATION,
	identifier,
	role
});


// Thunk action creators

export const getDefaultProfile = (force=false) => (dispatch, getState) => {
	const shouldUpdate = !(!force && (getState().profile.user.user_id === getState().appProfile.userid) && !getState().appProfile.shouldUpdate);
	dispatch(requestingDefaultProfile());
	let promise = new Promise((resolve, reject) => {
		dispatch(checkCookie()).then(() => {
			if (!shouldUpdate) {
				dispatch(profileIsUpToDate());
				resolve();
			} else {
				let a = new Api('app/profile'), status;
				a.Get().then(response => {
					status = response.status;
					return response.json();
				}).then(json => {
					if (status === 200) {
						dispatch(fetchedDefaultProfile(json));
						resolve(json);
					} else {
						dispatch(defaultProfileRequestFailed(status, json.Error));
						reject(status);
					}
				});
			}
		});
	});

	return promise;
};

export const acceptInvitation = ({identifier, role}) => (dispatch) => {
	dispatch(acceptingInvitation);
	const url = `app/member/invitation/response/organization/${identifier}/role/${role}`;
	dispatch(updateData(url, {accept: true}, false)).then(() => {
		dispatch(getDefaultProfile(true));
	});
}

export const rejectInvitation = ({identifier, role}) => (dispatch) => {
	const url = `app/member/invitation/response/organization/${identifier}/role/${role}`;
	dispatch(updateData(url, {accept: false}, false)).then(() => {
		dispatch(rejectingInvitation(identifier, role));
	});
}
