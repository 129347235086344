import React from 'react';
import { Row, Col, Button, Card, CardBody } from 'reactstrap';

import { Form, Boolean, Categorical, RfPayment, FinalSubmit, Options, Certificate, Delay } from '../components';
import T from 'modules/i18n';

const Content = ({node, onSubmit, disabled, values, content}) => {
	const commonClassName = "mb-3 border-top-primary border-top-3 w-xxl-75 mx-auto mt-4";

	if (node.content_type === 'O')
		return (
			<Card className={commonClassName}>
				<CardBody dangerouslySetInnerHTML={{__html: node.content_text}}/>
			</Card>
		)

	switch (node.type) {
		case 'form':
		case 'register':
			return (
				<Form fields={content} onSubmit={onSubmit} disabled={disabled} values={values}>
					<Row>
						<Col className="d-flex justify-content-end">
							<Button className="mr-5" color="info"><T>{node.content_type === 'O' ? 'continue' : 'submit'}</T></Button>
						</Col>
					</Row>
				</Form>
			);

		case 'boolean':
			return (
				<Boolean node={node} onSubmit={onSubmit} disabled={disabled} values={values}
					className={commonClassName}
				/>
			);

		case 'categorical':
			return (
				<Categorical node={node} onSubmit={onSubmit} disabled={disabled} values={values} className={commonClassName}/>
			);

		case 'rf':
			return (
				<RfPayment node={node} onSubmit={onSubmit} disabled={disabled} values={values} className={commonClassName}/>
			);

		case 'submit':
			return (
				<FinalSubmit node={node} onSubmit={onSubmit} disabled={disabled} content={node.content_text} values={values}/>
			);

		case 'transition':
		case 'assignation':
			return (
				<Options node={node} content={content} disabled={disabled} values={values} onSubmit={onSubmit}/>
			);

		case 'certificate':
			return <Certificate node={node} content={content} disabled={disabled} values={values} onSubmit={onSubmit}/>

		case 'delay':
			return <Delay node={node} className={commonClassName}/>;

		default:
			return null;
	}
}

export default Content;
