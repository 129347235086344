export const applicationStatusStyle = {
	'progressing': { color: 'darkgoldenrod', badge: 'secondary' },
	// 'deposit_payment_in_progress': { color: 'blue', badge: 'primary' },
	'no_deposit_payment': { color: 'red', badge: 'danger' },
	'with_editor': { color: 'blue', badge: 'primary' },
	'under_review': { color: 'blue', badge: 'primary' },
	'under_editor_evaluation': { color: 'blue', badge: 'primary' },
	'accepted': { color: 'lightgreen', badge: 'info' },
	'rejected': { color: 'red', badge: 'danger' },
	// 'first_payment_in_progress': { color: 'blue', badge: 'primary' },
	// 'approved': { color: 'green', badge: 'success' },
	// 'no_first_payment': { color: 'red', badge: 'danger' },
	'completed': { color: 'green', badge: 'success' },
	'suspended': { color: 'red', badge: 'danger' },
	'revoked': { color: 'orange', badge: 'warning' },
}

export const applicationStatus = Object.keys(applicationStatusStyle);