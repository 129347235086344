import * as roles from '../model/roles';

const Root = '/';
const Admin = '/admin';
const Profile = Admin + '/profile';

/**
 * Static routes
 */
const Policy = '/termsofuse';
const Login = '/login';
const AdminDashboard = Admin + '/dashboard';
const Settings = Admin + '/settings';
const GeneralSettings = Settings + '/general';
const FrontPageSettings = Settings + '/frontpage';
const Templates =  Settings + '/templates';
const PolicySettings = Settings + '/policy';
const Account = '/account';
const Logout = '/logout';

export const StaticRoutes = {
	Root,
	Policy,
	Admin,
	AdminDashboard,
	Settings,
	GeneralSettings,
	FrontPageSettings,
	Templates,
	PolicySettings,
	Account,
	Profile,
	Logout,
};

const TemplatesDynamic = Templates + '/(template)?/:template?';
const Activation = Account + '/activation/:token([0-9a-f]{64})';

export const DynamicRoutes = {
	Templates: TemplatesDynamic,
	Activation,
};

/**
 * Routes for error pages
 */

const Forbidden = '/error/403';
const NotFound = '/error/404';

export const ErrorPages = {
	Forbidden,
	NotFound,
};

export const routes = [
	{
		url: Root,
		name: 'home',
		component: 'Front',
		exact: true,
	},
	{
		url: Policy,
		name: 'policy',
		component: 'Policy',
	},
	{
		url: Admin,
		name: 'administration',
		to: Login,
		role: [roles.GUEST]
	},
	{
		url: Admin,
		name: 'administration',
		to: Forbidden,
		role: [roles.AUTHORIZED]
	},
	{
		url: Forbidden,
		name: 'forbidden',
		component: 'ErrorPage',
		exact: true,
		props: {status: 403},
	},
	{
		url: NotFound,
		name: 'not found',
		component: 'ErrorPage',
		exact: true,
		props: {status: 404},
	},
	{
		url: Admin,
		name: 'administration',
		to: AdminDashboard,
		role: roles.REVIEWER,
		exact: true,
	},
	{
		url: AdminDashboard,
		name: 'dashboard',
		component: 'Dashboard',
		role: roles.REVIEWER
	},
	{
		url: Settings,
		name: 'settings',
		to: GeneralSettings,
		exact: true,
	},
	{
		url: GeneralSettings,
		name: 'general',
		component: 'GeneralSettings',
		role: roles.ADMIN,
	},
	{
		url: FrontPageSettings,
		name: 'front',
		component: 'FrontPageSettings',
		role: roles.ADMIN,
	},
	{
		url: Profile,
		name: 'profile',
		component: 'Profile',
		role: roles.REVIEWER,
	},
	{
		url: TemplatesDynamic,
		name: 'email templates',
		contextComponent: 'SelectTemplate',
		component: 'Templates',
		role: roles.ADMIN,
		exact: true,
	},
	{
		url: PolicySettings,
		name: 'policy',
		component: 'PolicySettings',
		role: roles.ADMIN,
	},
	{
		url: Logout,
		name: 'logout',
		to: '/api/oauth/oauth2/logout/',
		externalUrl: true,
	},
	{
		url: Activation,
		name: 'activation',
		component: 'Activation',
		role: roles.GUEST,
	}
];

export default routes;
