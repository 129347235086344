import React, { Component } from 'react';
import {
	Card, CardHeader, CardBody, FormGroup, Label, Modal, ModalBody, Button, Progress, Row, Col
} from 'reactstrap';
import { CNav, CNavItem, CNavLink, CTabContent } from '@coreui/react';

import { initContext, setContext } from 'core/ducks/context';
import * as roles from 'core/model/roles';
import { Content } from 'flows/views/components';
import { getData } from 'core/ducks/update';
import { uploadData, cancelUpload } from 'core/ducks/upload';
import { buildPath } from 'core/model/lib/urlTools';
import { ErrorPage } from 'core/views/pages';
import { DynamicRoutes } from 'app/model/routes';
import { ThreadModal } from 'app-messages';
import withApp from './app';

import T from 'modules/i18n';

class ApplyWrapper extends Component {
	render() {
		return <Apply {...this.props} key={`apply_${this.props.match.params.round}_${this.props.match.params.application}`}/>
	}
}

class Apply extends Component {

	constructor(props) {
		super(props);
		this.state = {
			round: {},
			node: {},
			history: [],
			tree: [],
			values: {},
			content: [],
			messages: 0,
			readOnly: true,
			ready: false,
			httpStatus: 200,
			current: '',
			registrationNumber: null,
			showProgress: false,
			isMessageThreadOpen: false,
		}
	}

	componentDidMount() {
		this.props.dispatch(initContext({round: {...this.state.round}}));
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.match.params.round !== this.props.match.params.round) {
			this.fetchData();
		}
		if (prevProps.match.params.step !== this.props.match.params.step) {
			this.fetchData();
		}
		if (prevState.node !== this.state.node) {
			if (this.state.node.type === 'form' && this.state.node.content === 'geoserver_details') {
				const content = this.state.content.map(field => ({...field, final: true}));
				this.setState({content});
			}
		}
		if (prevProps.messages.unread.length < this.props.messages.unread.length ) {
			const prev = prevProps.messages.unread.map(m => m.uuid);
			const newMessages = this.props.messages.unread
				.filter(m => !prev.includes(m.uuid))
				.filter(m => m.application === this.props.match.params.application).length;
			if (newMessages > 0)
				this.setState({messages: this.state.messages + newMessages});
		}
		if (prevState.round !== this.state.round)
			this.props.dispatch(setContext({round: {...this.state.round}}));
	}

	fetchData = () => {
		const url = this.createUrl();
		this.props.dispatch( getData(url) )
			.then(data => this.setState({
				...data,
				ready: true,
			}))
			.catch(httpStatus => this.setState({httpStatus}));
	}

	handleSubmit = (data) => {
		if (this.state.current && this.state.node.mname !== this.state.current)
			data.append('_method', 'put');
		const url = this.createUrl();
		this.setState({showProgress: true}, () => {
			this.props.dispatch( uploadData(url, data) ).then(response => {
				this.setState({showProgress: false});
				const { application, current_node } = response;
				this.props.history.push(
					buildPath(DynamicRoutes.Apply, [this.state.round.mname, application, current_node])
				);
			})
			.catch(error => {
				this.setState({showProgress: false});
				console.warn(error);
			});
		});
	}

	createUrl = () => {
		let { round, step, application } = this.props.match.params;
		if (!step)
			step = this.state.node.mname;
		const url = ['flows/apply'];
		url.push(`round/${round}`);
		if (application)
			url.push(`application/${application}`);
		if (step)
			url.push(`node/${step}`);
		return url.join('/')
	}

	handleWFS = (application) => {
		this.props.dispatch(getData(`app/wfs/application/${application}`))
			.then(values => {
				const content = this.state.content.map(field => ({...field, final: true}));
				this.setState({values, content});
			})
			.catch(err => console.warn(err));
	}

	toggleMessageThread = () => {
		this.setState({
			isMessageThreadOpen: !this.state.isMessageThreadOpen,
		});
	}

	render() {
		const { progress } = this.props.upload;
		const { application } = this.props.match.params;
		const { round, httpStatus } = this.state;
		if (httpStatus !== 200)
			return (<ErrorPage status={httpStatus}/>);

		return (
			<Card className="m-2 p-2 w-md-75">
				<CardHeader tag="h4">{round.label}</CardHeader>
				<CardBody>
					{ (this.props.role === roles.AUTHORIZED && this.state.messages > 0) &&
						<Row>
							<Col className="d-flex justify-content-end py-2">
								<Button outline color="info" onClick={this.toggleMessageThread}>
									<i className="icon-envelope-letter"/>{` ${this.state.messages}`}
								</Button>
							</Col>
						</Row>
					}
					<CNav variant="pills" layout="justified" role="tablist">
						{ this.state.tree.map(step => (
							<CNavItem key={`nav-item-${step.node}`}>
								<CNavLink
									href="#"
									active={step.active}
									disabled={step.disabled}
									onClick={() => this.props.history.push(buildPath(this.props.role !== roles.AUTHORIZED ? DynamicRoutes.AdminApply : DynamicRoutes.Apply, [round.mname, application, step.node]))}
								>
									{step.label}
								</CNavLink>
							</CNavItem>
						))}
					</CNav>
					<div className="mt-2"><div className="mx-auto border-bottom w-75"/></div>
					<CTabContent>
						{ (this.state.node.type === 'form' && this.state.node.content === 'geoserver_details') && (
							<Row>
								<Col className="d-flex justify-content-end">
									<Button outline color="warning" className="my-2 mx-5" onClick={() => this.handleWFS(application)}>WFS</Button>
								</Col>
							</Row>
						)}
						{ this.state.registrationNumber && (
							<FormGroup className="text-right m-2 font-weight-bold">
								<Label className="mr-1"><T>registration_number</T>:</Label>
								{this.state.registrationNumber}
							</FormGroup>
						)}
						<Content node={this.state.node} content={this.state.content} onSubmit={this.handleSubmit} disabled={this.state.readOnly} values={this.state.values}/>
						<Modal isOpen={this.state.showProgress} className="modal-md">
							<ModalBody>
								<p>Παρακαλούμε περιμένετε τη μεταφόρτωση των δεδομένων...</p>
								<Progress animated value={progress} />
								<div className="text-right p-1">
									<Button type="button" onClick={() => {
										cancelUpload('Operation canceled by the user.');
										this.setState({showProgress: false});
									}}>
										Ακύρωση
									</Button>
								</div>
							</ModalBody>
						</Modal>
					</CTabContent>
				</CardBody>
				{ (application && this.props.role === roles.AUTHORIZED) &&
					<ThreadModal isOpen={this.state.isMessageThreadOpen} toggle={this.toggleMessageThread} application={application}/>
				}
			</Card>
		);
	}
}

export default withApp(ApplyWrapper);
