import React, { Component } from 'react';
import PropTypes from 'prop-types';
import T from 'modules/i18n';

class StatusIndicator extends Component {

	constructor(props) {
		super(props);
		this.state = {
			hover: false,
		};
	}

	render() {
		const { status } = this.props;
		const { hover } = this.state;
		let color = "info";
		let icon = "fa-hourglass-half";
		let text = <T>pending invitation</T>;
		if (status === true) {
			color = "success";
			icon = "fa-check";
			text = <T>accepted invitation</T>;
		} else if (status === false) {
			color = "danger";
			icon = "fa-user-times";
			text = <T>rejected invitation</T>;
		}
		return (
			<div className={`d-inline-block ${hover ? `border rounded border-${color} text-${color}` : 'status'}`}>
				<span className={`p-2 rounded-left bg-${color} text-light animated fadeIn d-inline-block ${hover ? 'visible' : 'invisible'}`}>{text}</span>
				<div
					className={`d-inline-block ${!hover ? `border rounded border-${color} text-${color}` : ''}`}
					onMouseEnter={() => this.setState({hover: true})}
					onMouseLeave={() => this.setState({hover: false})}
				>
					<i className={`fa ${icon} p-2`}/>
				</div>
			</div>
		);
	}
}

StatusIndicator.propTypes = {
	status: PropTypes.bool,
};

export default StatusIndicator;
