import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane, Input, Form, FormGroup, FormFeedback, Button } from 'reactstrap';

import { Table, Tbody, Td, Thead, Tr } from 'css-table';
import { requestData } from 'core/ducks/list';
import { updateData, postData } from 'core/ducks/update';
import { getValidation, validate } from 'core/ducks/forms';

import T from 'modules/i18n';

class ContentEdit extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTab: 0,
			newValue: '',
			selected: null,
		};
	}

	componentDidMount() {
		this.fetchData();
		this.props.dispatch( getValidation('items') );
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.selected !== this.state.selected)
			this.props.context.set({selected: this.state.selected});

		if (!prevProps.context.forceUpdate && this.props.context.forceUpdate) {
			this.fetchData();
			this.props.context.set({forceUpdate: false});
		}
	}

	fetchData = () => {
		return this.props.dispatch(
			requestData('items', 'appcontent/items')
		);
	}

	toggleTab = (tab) => {
		if (tab !== this.state.activeTab)
			this.setState({activeTab: tab});
	}

	onNewValueSubmit = (e) => {
		e.preventDefault();
		const { newValue } = this.state;
		if (newValue === '')
			return;
		this.props.dispatch(validate({item: newValue}, this.props.rules, 'items')).then(() => {
			if (this.props.valid)
				this.props.dispatch(
					postData('appcontent/items', {item: newValue})
				).then(selected => {
					this.fetchData().then(() => {
						this.setState({
							activeTab: 0,
							newValue: '',
							selected,
						});
					});
				});
		});
	}

	handleContextChange = (e, key) => {
		const { checked, name} = e.target;
		this.props.dispatch(
			updateData(`appcontent/items/uuid/${key}`, {[name]: checked})
		).then(() => this.fetchData());
	}

	render() {
		const { activeTab, selected } = this.state;
		const { items, rules, messages } = this.props;

		if (this.props.validationPending || this.props.validationScope !== 'items' || !items || items.pending)
			return null;

		return (
			<>
				<Nav tabs>
					<NavItem>
						<NavLink
							className={activeTab===0 ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggleTab(0); }}
						>
							<T>select</T>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={activeTab===1 ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggleTab(1); }}
						>
							<T>add</T>
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={activeTab} className="scroller mx-2 mt-2">
					<TabPane tabId={0}>
						<Table>
							<Thead>
								<Tr>
									<Td>item</Td>
									<Td>auth</Td>
									<Td>no-auth</Td>
								</Tr>
							</Thead>
							<Tbody>
								{ Object.keys(items.data).map(key => (
									<Tr key={`row_${key}`} className={selected===key ? 'bg-light' : null}>
										<Td>
											<span role="link" onClick={() => this.setState({selected: key})}>{items.data[key].item}</span>
										</Td>
										<Td className="text-center">
											<Input
												name="authorized"
												className="mx-auto"
												type="checkbox"
												checked={items.data[key].authorized}
												onChange={(e) => this.handleContextChange(e, key)}
											/>
										</Td>
										<Td className="text-center">
											<Input
												name="unauthorized"
												className="mx-auto"
												type="checkbox"
												checked={items.data[key].unauthorized}
												onChange={(e) => this.handleContextChange(e, key)}
											/>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</TabPane>
					<TabPane tabId={1}>
						<Form onSubmit={this.onNewValueSubmit}>
							<FormGroup>
								<Input
									onChange={(e) => this.setState({newValue: e.target.value})}
									value={this.state.newValue}
									minLength={rules.item.min_size}
									maxLength={rules.item.max_size}
									invalid={messages.item !== ''}
								/>
								<FormFeedback><T>{messages.item}</T></FormFeedback>
							</FormGroup>
							<div className="text-center">
								<Button>
									<T>submit</T>
								</Button>
							</div>
						</Form>
					</TabPane>
				</TabContent>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	items: state.list.items,
	messages: state.forms.validation_msgs,
	rules: state.forms.validation.rules,
	validationPending: state.forms.validation.pending,
	validationScope: state.forms.validation.scope,
	valid: state.forms.valid,
});

ContentEdit = connect(mapStateToProps)(ContentEdit);

export default ContentEdit;
