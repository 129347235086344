import React, { Component } from 'react';

class Forbidden extends Component {
	render() {
		return (
			<div>You are not allowed to access this page.</div>
		);
	}
}

export default Forbidden;
