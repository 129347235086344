import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';

import { Form } from 'flows/views/components';
import T from 'modules/i18n';

class AddProxy extends Component {
	render() {

		return (
			<Modal size="lg" isOpen={this.props.isOpen}>
				<ModalHeader toggle={this.props.toggle}><T>add proxy</T></ModalHeader>
				<ModalBody>
					<Form form="member_addition" onSubmit={(data) => this.props.onSubmit(data)}>
						<Row className="border-top pt-4 my-2">
							<Col className="d-flex justify-content-end">
								<Button color="warning" className="mr-3" onClick={this.props.toggle}><T>cancel</T></Button>
								<Button color="info" className="mr-3"><T>submit</T></Button>
							</Col>
						</Row>
					</Form>
				</ModalBody>
			</Modal>
		);
	}
}

AddProxy.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
}

export default AddProxy;
