import React, { Component } from 'react';
import { Search } from 'fulltext-search/views/components';

class AdminDashboard extends Component {

	render() {
		return <Search/>
	}
}

export default AdminDashboard;
