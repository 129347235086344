import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Button, Form, FormGroup, Label, Row, Col} from 'reactstrap';
import { Input } from 'core/components';

import { getData, postData, updateData, deleteData } from 'core/ducks/update';
import { DateInput } from 'input-fields';
import T from 'modules/i18n';

class SetDelay extends Component {

	constructor(props) {
		super(props);
		this.state = {
			delays: {},
			values: {
				mname: '',
				start: '',
			},
			method: 'post',
		};
	}

	componentDidMount() {
		this.props.dispatch(getData('flows/delay'))
			.then(delays => this.setState({delays}));
	}

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({
			values: {
				...this.state.values,
				[name]: value,
			},
		});
		if ( name === 'mname' && value !== '') {
			this.props.dispatch(getData(`flows/delay/application/${this.props.application.index}/mname/${value}`))
				.then(({start}) => {
					if (start) {
						this.setState({
							values: {
								...this.state.values,
								start,
							},
							method: 'put',
						});
					} else {
						this.setState({method: 'post'});
					}
				})
		}
	}

	handleFormSubmit = (e) => {
		e.preventDefault();
		if (this.state.method === 'post') {
			this.props.dispatch(postData(`flows/delay/application/${this.props.application.index}`, this.state.values))
				.then(() => this.props.toggle());
		} else {
			const { start } = this.state.values;
			this.props.dispatch(updateData(`flows/delay/application/${this.props.application.index}/mname/${this.state.values.mname}`, {start}))
				.then(() => this.props.toggle());
		}
	}

	unset = () => {
		this.props.dispatch(deleteData(`flows/delay/application/${this.props.application.index}/mname/${this.state.values.mname}`))
			.then(() => this.props.toggle());
	}

	render() {

		const { isOpen, toggle, application } = this.props;
		const { values } = this.state;

		return (
			<Modal isOpen={isOpen} toggle={toggle}>
				<ModalHeader toggle={toggle}>Set Delay</ModalHeader>
				<ModalBody>
					<p className="text-center text-info">
						<span>{application.round_label}</span> - <span>{application.organization}</span>
					</p>
					<Form onSubmit={this.handleFormSubmit}>
						<FormGroup>
							<Input required name="mname" type="select" value={values.mname} onChange={this.handleChange}>
								<option value=''>Select delay</option>
								{ Object.entries(this.state.delays).map(([mname, name]) => (
									<option key="mname" value={mname}>{name}</option>
								))}
							</Input>
						</FormGroup>
						<FormGroup row>
							<Label htmlFor="set_delay_date_input" sm="2"><T>start</T></Label>
							<Col sm="10">
								<DateInput
									required
									autoComplete="off"
									id="set_delay_date_input"
									name="start"
									value={values.start}
									onChange={this.handleChange}
									showTimeSelect={true}
									dateFormat="d MMMM yyyy (HH:mm)"
									internalDateFormat="yyyy-MM-dd HH:mm:ss"
								/>
							</Col>
						</FormGroup>
						<Row>
							<Col className="d-flex justify-content-end">
								{ this.state.method === 'put' &&
									<Button className="mr-2" outline color="warning" onClick={this.unset}>
										<i className="text-danger fa fa-times mr-1"/><T>delete</T>
									</Button>
								}
								<Button color="success"><T>submit</T></Button>
							</Col>
						</Row>
					</Form>
				</ModalBody>
			</Modal>
		);
	}
}

SetDelay = connect(null)(SetDelay);

export default SetDelay;
