import { default as routes } from './model/routes';
import { default as menuItems } from './menu';
import * as layout from './views/layout';
import Selector from './components/selector';
import InvitationsBell from './views/components/invitationsBell';
import withOrganizations from './views/components/withOrganizations';
import * as reducers from './ducks';
import * as sidebars from './views/sidebars';

export default { reducers, ...routes, menuItems, layout, headerItems: [InvitationsBell, withOrganizations(Selector)], sidebars };
