import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'edit',
		url: StaticRoutes.Edit,
		icon: 'icon-pencil',
		role: roles.ADMIN,
		domain: 'admin',
		children: [
			{
				name: 'forms',
				url: StaticRoutes.EditForms,
				domain: 'admin',
			},
			{
				name: 'draw workflow',
				url: StaticRoutes.EditWorkflows,
				domain: 'admin',
			},
			{
				name: 'modals',
				url: StaticRoutes.EditModals,
				domain: 'admin',
			},
			{
				name: 'pdf templates',
				url: StaticRoutes.PdfTemplates,
				icon: 'fa fa-file-pdf-o',
				domain: 'admin',
			}
		]
	},
	{
		name: 'rounds',
		url: StaticRoutes.Rounds,
		icon: 'fa fa-calendar-check-o',
		role: roles.EDITOR,
		domain: 'admin',
	}
];

export default menu;
