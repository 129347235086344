import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Input, Row, Col, Button, Label } from 'reactstrap';

import { getData, updateData } from 'core/ducks/update';
import { Editor } from 'editor'
import { default as T, getLocale } from 'modules/i18n';

class Certificate extends Component {

	constructor(props) {
		super(props);
		this.state = {
			values: {
				choice: '',
				template: '',
				subject: '',
				html: null,
				certificate: null,
			},
			availableChoices: [],
		};
	}

	componentDidMount() {
		const { mname } = this.props.node;
		const values = Object.entries(this.props.values)
			.filter(([key, value]) => value)
			.reduce((obj, [key, value]) => ({
				...obj,
				[key]: value,
			}), {});
		if (values[mname])
			values.choice = values[mname];
		const availableChoices = ['positive', 'negative'].filter(choice => this.props.node.options[choice].templates.length > 0);
		if (availableChoices.length === 1)
			values.choice = availableChoices[0]
		this.setState({
			values: {
				...this.state.values,
				...values,
			},
			availableChoices,
		});
		if (this.props.node.application) {
			this.props.dispatch( getData(`flows/certificate/application/${this.props.node.application}`) )
				.then(certificate => {
					if (Object.keys(certificate).includes('uuid')) {
						this.setState({certificate});
					}
				});
		}
	}

	fetchTemplateContent = (template) => {
		if (template === '')
			return;
		this.props.dispatch( getData(`flows/certificate/template/${template}/application/${this.props.node.application}`) )
			.then(({subject, html}) => this.setState({values: {
				...this.state.values,
				subject,
				html,
			}}))
			.catch(err => console.warn(err));
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.handleTempSave();
		const { choice, template, subject, html } = this.state.values;
		const { mname } = this.props.node;
		this.props.onSubmit({[mname]: choice, template, subject, html});
	}

	handleRadioChange = (e) => {
		const { checked, name } = e.target;
		if (checked && name !== this.state.choice)
			this.setState({
				values: {
					...this.state.values,
					choice: name,
					template: '',
					subject: '',
					html: null,
				}
			});
	}

	handleInputChange = (e) => {
		const { value, name } = e.target;
		this.setState({
			values: {
				...this.state.values,
				[name]: value,
			}
		});
		if (name === 'template')
			this.fetchTemplateContent(value);
	}

	handleTempSave = () => {
		const { choice, template, subject, html } = this.state.values;
		this.props.dispatch(updateData(`flows/tempcertificate/application/${this.props.node.application}`, { choice, template, subject, html }));
	}

	handleTempRecovery = () => {
		this.props.dispatch(getData(`flows/tempcertificate/application/${this.props.node.application}`))
			.then(values => {
				if (Object.keys(values).length > 0)
					this.setState({values: {...this.state.values, ...values}});
			});
	}

	handlePreview = () => {
		const { choice, template, subject, html } = this.state.values;
		this.props.dispatch(updateData(`flows/tempcertificate/application/${this.props.node.application}`, { choice, template, subject, html }))
			.then(uuid => {
				const url = `/preview/certificate/${uuid}.pdf`;
				window.open(url, "_blank");
			});
	}

	openExternalDoc = () => {
		window.open(this.state.certificate.external_url, "_blank");
	}

	render() {
		const { node, disabled } = this.props;
		const { values, certificate, availableChoices } = this.state;
		const locale = this.props.dispatch(getLocale());

		if (typeof node.options === 'string' || node.options instanceof String)
			node.options = JSON.parse(node.options);

		return (
			<Form onSubmit={this.handleSubmit} className="m-2">
				{ certificate &&
					<Row>
						<Col className="d-flex justify-content-end">
							<Button className="mx-2" color="info" onClick={this.openExternalDoc}>ΔΙΑΥΓΕΙΑ {certificate.serial}</Button>
						</Col>
					</Row>
				}
				<FormGroup tag="fieldset" disabled={disabled}>
					<FormGroup>
						{ !certificate &&
							<Row>
								<Col className="d-flex justify-content-end">
									<Button className="mx-2" color="warning" onClick={this.handleTempRecovery}><T>recover temporary</T></Button>
									<Button className="mx-2" color="info" onClick={this.handlePreview}><T>temp_save_and_preview</T></Button>
								</Col>
							</Row>
						}
						<Label className="font-weight-bold">{node.content_text}</Label>
						{ availableChoices.map(choice => (
							<FormGroup key={`certificate_form_${choice}`} className="ml-4" check>
								<Label check>
									<Input type="radio" name={choice} checked={values.choice===choice} onChange={this.handleRadioChange}/>{` ${node.options[choice].text}`}
								</Label>
							</FormGroup>
						))}
					</FormGroup>
					<FormGroup row>
						<Label sm="3"><T>select template</T></Label>
						<Col sm="9">
							<Input type="select" name="template" value={values.template} onChange={this.handleInputChange}>
								<option value="">---</option>
								{ values.choice!=='' ? node.options[values.choice].templates.map((template, index) => (
									<React.Fragment key={`certificate_form_${values.choice}_opt_${index}`}>
										<option value={template}>{template}</option>
									</React.Fragment>
								)) : null }
							</Input>
						</Col>
					</FormGroup>
					<FormGroup>
						<Label htmlFor="certificate_subject"><T>subject</T></Label>
						<Input id="certificate_subject" name="subject" value={values.subject} onChange={this.handleInputChange}/>
					</FormGroup>
					<FormGroup>
						<Editor
							locale={locale}
							value={values.html || ''}
							onChange={(html) => this.setState({values: {...this.state.values, html}})}
							name="html"
							disabled={disabled || !values.template || !values.html}
							height="480"
						/>
					</FormGroup>
					<Row>
						<Col className="d-flex justify-content-end">
							{ !certificate &&
								<Button disabled={!values.template} color="info" onClick={this.handleTempSave} className="mr-2"><T>temporary save</T></Button>
							}
							{ node.options.temporary ?
								<Button disabled={!values.template} color="success" className="mr-2"><T>continue</T></Button>
								:
								<Button disabled={!values.template} color="warning" className="mr-2"><T>continue with external</T></Button>
							}
						</Col>
					</Row>
				</FormGroup>
			</Form>
		);
	}
}

const mapStateToProps = (state) => ({});

Certificate = connect(mapStateToProps)(Certificate);

export default Certificate;
