import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form as ReactForm } from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';

import FormContent from '../../components/formContent';
import { getData } from 'core/ducks/update';

class Form extends Component {

	constructor(props) {
		super(props);
		this.state = {
			fields: null,
			values: {},
			validation: {},
			readOnly: [],
			accept: {},
			hasAccepted: false,
		};
	}

	componentDidMount() {
		this.getForm(this.props.form, this.props.fields);
	}

	setStateFromFields = (fields) => {
		this.props.getFormDetails(fields);
		const values = this.recursiveReduce(fields, (field) => (this.props.values[field.name] || (field.type === 'conditional' ? false : '')));
		const validation = this.recursiveReduce(fields, (field) => null);
		const newFields = fields.map(field => ({
			...field,
			readOnly: this.props.readOnly.includes(field.name) ? true : false,
			loadCountries: this.loadCountries,
			loadApplications: this.loadApplications,
			loadDams: this.loadDams,
		}));
		const accept = fields.filter(field => field.type === "accept").reduce((obj, field) => ({[field.name]: false}), {});
		const hasAccepted = Object.keys(accept).length === 0
		const readOnly = fields.filter(field => field.final);
		this.setState({fields: newFields, values, validation, readOnly: [...this.props.readOnly, ...readOnly], accept, hasAccepted});
	}

	getForm = (form, fields) => {
		if (form === null) {
			this.setStateFromFields(fields);
		} else {
			this.props.dispatch( getData(`flows/forms/form/${form}`) )
				.then(fields => this.setStateFromFields(fields));
		}
	}

	recursiveReduce = (fields, apply) => {
		return fields.reduce((obj, field) => ({
			...obj,
			[field.name]: apply(field),
			...this.recursiveReduce(field.children || [], apply),
		}), {});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.readOnly !== this.props.readOnly) {
			const remove = prevProps.readOnly.filter(e => !this.props.readOnly.includes(e));
			const add = this.props.readOnly.filter(e => !prevProps.readOnly.includes(e));
			const fields = this.state.fields.map(field => ({
				...field,
				readOnly: remove.includes(field.name) ? false : (add.includes(field.name) ? true : field.readOnly)
			}));
			const readOnly = this.state.readOnly.filter(name => !remove.includes(name));
			this.setState({
				fields,
				readOnly: [...readOnly, ...add],
			});
		}

		if (prevState.hasAccepted !== this.state.hasAccepted) {
			this.props.onAcceptanceChange(this.state.hasAccepted);
		}

		if (prevProps.form !== this.props.form || prevProps.fields !== this.props.fields)
			this.getForm(this.props.form, this.props.fields);

		if (prevProps.values !== this.props.values)
			this.getForm(this.props.form, this.props.fields);
	}

	loadCountries = async (search, loadedOptions, { page }) => {
		const url = search === '' ? `authoritative/countries/page/${page}` : `authoritative/countries/search/${search}/page/${page}`;
		const response = await this.props.dispatch( getData(url) );
		return response;
	}

	loadApplications = async (search, loadedOptions, { page }) => {
		const url = search === '' ? `flows/applicationformfield/page/${page}` : `flows/applicationformfield/query/${search}/page/${page}`;
		const response = await this.props.dispatch( getData(url) );
		return response;
	}

	loadDams = async (search, loadedOptions, { page }) => {
		const url = search === '' ? `flows/damformfield/page/${page}` : `flows/damformfield/query/${search}/page/${page}`;
		const response = await this.props.dispatch( getData(url) );
		return response;
	}

	handleChange = ({target}) => {
		const { name, type, value, checked, files } = target;
		const hasAccepted = Object.keys(this.state.accept).includes(name) ? checked : this.state.hasAccepted;
		this.setState({
			values: {
				...this.state.values,
				[name]: type === 'checkbox' ? checked : (type === 'file' ? files[0] : value),
			},
			hasAccepted,
		});
		this.props.onChange(target);
	}

	handleSubmit = (e) => {
		e.preventDefault();
		const validation = Object.entries(this.state.validation)
			.filter(([name, value]) => (!this.state.readOnly.includes(name) && value === false))
		if (validation.length > 0) return
		const data = Object.entries(this.state.values)
			.filter(([name, value]) => !this.state.readOnly.includes(name))
			.reduce((obj, [name, value]) => ({
				...obj,
				[name]: value
			}), {});

		const formData = new FormData();
		Object.entries(data).forEach(([key, value]) => {
			formData.append(key, _.isPlainObject(value) ? JSON.stringify(value) : Array.isArray(value) ? JSON.stringify(value) : value)
		});
		this.props.onSubmit(formData);
	}

	handleHide = (fields) => {
		const emptyValues = fields.reduce((obj, field) => ({
			...obj,
			[field]: '',
		}), {});
		this.setState({
			readOnly: [...this.state.readOnly, ...fields],
			values: {
				...this.state.values,
				...emptyValues,
			},
		});
	}

	handleShow = (fields) => {
		const readOnly = this.state.readOnly.filter(e => !fields.includes(e));
		this.setState({
			readOnly
		});
	}

	handleValidationChange = (changed) => {
		this.setState({
			validation: {...this.state.validation, ...changed}
		});
		this.props.onValidationChange(changed);
	}

	render() {
		if (!this.state.fields) return null;

		return (
			<ReactForm onSubmit={this.handleSubmit}>
				<fieldset disabled={this.props.disabled}>
					<FormContent
						fields={this.state.fields}
						onChange={this.handleChange}
						values={this.state.values}
						onHide={this.handleHide}
						onShow={this.handleShow}
						onValidationChange={this.handleValidationChange}
						urlPrefix={this.props.urlPrefix}
						disabled={this.props.disabled}
					/>
					{this.props.children}
				</fieldset>
			</ReactForm>
		);
	}
}

const mapStateToProps = (state) => ({});

Form.propTypes = {
	form: PropTypes.string,
	fields: PropTypes.array,
	onSubmit: PropTypes.func.isRequired,
	readOnly: PropTypes.array,
	onChange: PropTypes.func,
	onValidationChange: PropTypes.func,
	onAcceptanceChange: PropTypes.func,
	values: PropTypes.object,
	disabled: PropTypes.bool,
	urlPrefix: PropTypes.string,
	getFormDetails: PropTypes.func,
};

Form.defaultProps = {
	form: null,
	fields: [],
	readOnly: [],
	onChange: (target) => {},
	onValidationChange: (e) => {},
	onAcceptanceChange: (e) => {},
	values: {},
	disabled: false,
	urlPrefix: 'flows',
	getFormDetails: () => {},
}

Form = connect(mapStateToProps)(Form);

export default Form;
