import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import T from 'modules/i18n';

class Notifications extends Component {

	constructor(props) {
		super(props);
		this.state = {
			ids: [],
			top: 0,
			clientHeight: 0
		};
	}

	setAlert(id, alert) {
		if (this.state.ids.indexOf(id) === -1) {
			this.setState({ids: [...this.state.ids, id]});
			if (this.state.top === 0)
				this.setState({top: parseInt(document.defaultView.getComputedStyle(alert,null).getPropertyValue('top'))});
			if (this.state.clientHeight === 0)
				this.setState({clientHeight: parseInt(alert.clientHeight)});
		}
	}

	render() {
		let counter = -1;
		const notifications = this.props.messages.map((message) => {
			counter++;
			return (
				<div
					className="alert-container animated fadeInRight"
					key={'notification_'+message.id}
					ref={(alert) => this.setAlert(counter, alert)}
					style={{top: counter>0 && (counter*this.state.clientHeight + this.state.top + 'px')}}
				>
					<Alert color={message.type} className="animated fadeInRight">
						{ message.status &&
							<span>[{message.status}] </span>
						}
						<T placeholders={message.placeholders}>{message.body}</T>
						<span className="time">{message.time}</span>
					</Alert>
				</div>
			);
		});
		return notifications;
	}

}

export default Notifications;
