import React, { Component } from 'react';
import { Row, Col, Button, Card, CardBody, CardTitle, Form, FormGroup, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';

import T from 'modules/i18n';

class Categorical extends Component {

    constructor(props) {
        super(props);
        const { node, values } = props;
        this.options = JSON.parse(node.options);
        this.state = {
            values: Object.keys(this.options).reduce((obj, k) => ({
                ...obj,
                [k]: Object.keys(values).includes(node.mname) ? values[node.mname] === k : false,
            }), {}), 
        };
    }

    handleChange = ({target}) => {
        const { name, checked } = target;
        if (!checked)
            return;
        this.setState({
            values: Object.keys(this.state.values).reduce((obj, k) => ({
                ...obj,
                [k]: k===name
            }), {}),
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.props.disabled)
            return;
        const { node, onSubmit } = this.props;
        const value = Object.entries(this.state.values).find(([k, v]) => v);
        if (!value)
            return
        const formdata = new FormData();
        formdata.append(node.mname, value[0]);
        onSubmit(formdata);
    }

    render() {
        const { node, className, disabled } = this.props;

        return (
            <Card className={className}>
                <CardBody>
                    <CardTitle className="fw-semibold" dangerouslySetInnerHTML={{__html: node.content_text}}/>
                    <Form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col>
                                { Object.entries(this.options).map(([k, v]) => (
                                    <FormGroup key={`cat_${k}`} check>
                                        <Label check>
                                            <Input type="radio" name={k} checked={this.state.values[k]} onChange={this.handleChange}/>{' '}{v}
                                        </Label>
                                    </FormGroup>
                                )) }
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-end">
                                <Button color="warning" disabled={disabled}><T>submit</T></Button>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        );
    }
}

Categorical.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	node: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	values: PropTypes.object,
	className: PropTypes.string,
};

Categorical.defaultProps = {
	disabled: false,
	values: {},
	className: '',
}

export default Categorical;
