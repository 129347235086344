import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardText, Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap';

import { getData, postData, deleteData, updateData } from 'core/ducks/update';
import { ErrorPage } from 'core/views/pages';
import { StaticRoutes } from 'app/model/routes';
import { StaticRoutes as CoreStaticRoutes } from 'core/model/routes';
import T from 'modules/i18n';

class OAuthRegister extends Component {

	constructor(props) {
		super(props);
		this.initialValues = {
			userid: '',
			email: '',
			fullname: '',
			firstname: '',
			lastname: '',
		};
		this.state = {
			userDetails: {...this.initialValues},
			userExists: false,
			declaration: false,
			httpStatus: 200,
			isSubmitted: false,
			token: null,
		};
	}

	componentDidMount() {
		const params = new URLSearchParams(this.props.location.search);
		const token = params.get('token');
		if (!token) {
			this.setState({httpStatus: 403});
		} else {
			this.setState({token});
			this.props.dispatch(
				getData(`oauth/UserDetails/token/${token}`)
			)
			.then(userDetails => {
				const { user_exists, ...otherDetails } = userDetails;
				this.initialValues = otherDetails;
				this.setState({userDetails: {...this.initialValues}, userExists: user_exists});
			})
			.catch(httpStatus => {
				this.setState({httpStatus});
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.httpStatus !== this.state.httpStatus) {
			if ([403, 410].includes(this.state.httpStatus))
				this.props.history.push(StaticRoutes.App);
		}
	}

	handleFormSubmit = (e) => {
		e.preventDefault();
		const { userDetails, token } = this.state;
		this.props.dispatch(postData('oauth/UserDetails', {...userDetails, token}))
			.then(() => this.setState({isSubmitted: true}));
	}

	handleInputChange = (e) => {
		const { name, value } = e.target;
		this.setState({
			userDetails: {
				...this.state.userDetails,
				[name]: value,
			}
		});
	}

	handleNewEmail = () => {
		this.props.dispatch(deleteData(`oauth/UserDetails/token/${this.state.token}`))
			.then(() => this.props.history.push(StaticRoutes.App));
	}

	handleResend = () => {
		const { token } = this.state;
		this.props.dispatch(updateData(`oauth/UserDetails`, {token}))
			.then(() => this.setState({isSubmitted: true}));
	}

	render() {
		const { userDetails, userExists, declaration, httpStatus, isSubmitted } = this.state;
		if (httpStatus !== 200)
			return (<ErrorPage status={httpStatus}/>);
		if (userExists)
			return (
				<Row className="justify-content-center">
					<Col md="10" lg="9" xl="8">
						<Card className="m-5">
							<CardBody className="p-3">
								<CardText className="text-justify">
									<T>message has been already sent</T> (<code>{userDetails.email}</code>) <T>in order to activate your account</T>. <T>choose_action_message</T>:
								</CardText>
								{ isSubmitted &&
									<CardText className="my-5 text-center text-info">
										<T>email was resent</T>
									</CardText>
								}
								<FormGroup tag="fieldset" disabled={isSubmitted}>
									<Row className="justify-content-center">
										<Col md="10">
											<Row>
												<Col lg="4" className="mb-3">
													<Button outline color="success" className="w-100" onClick={() => this.props.history.push(CoreStaticRoutes.Root)}>
														<T>no_action_message</T>
													</Button>
												</Col>
												<Col lg="4" className="mb-3">
													<Button outline color="warning" className="w-100" onClick={this.handleResend}>
														<T>resend_email_message</T>{' '}<code>{userDetails.email}</code>
													</Button>
												</Col>
												<Col lg="4" className="mb-3">
													<Button outline color="danger" className="w-100" onClick={this.handleNewEmail}>
														<T>define_new_email_message</T>
													</Button>
												</Col>
											</Row>
										</Col>
									</Row>
								</FormGroup>
							</CardBody>
						</Card>
					</Col>
				</Row>
			);

		return (
			<Row className="justify-content-center">
				<Col md="10" lg="8">
					<Card className="mt-5 mx-5">
						<CardBody className="p-3">
							{ isSubmitted ?
								<CardText className="text-justify text-danger">
									<T>email_confirmation_message</T>
								</CardText>
								:
								<>
									<CardText className="text-justify">
										<T>first_time_here</T>
									</CardText>
									<CardText className="text-justify">
										<T>registration_after_auth_message</T>
									</CardText>
								</>
							}
						</CardBody>
					</Card>
					<Card className="my-5 mx-1">
						<CardBody className="p-3">
							<Form onSubmit={this.handleFormSubmit} className="px-5 py-2">
								<FormGroup tag="fieldset" disabled={isSubmitted}>
									{ Object.keys(userDetails).map(field => (
										<FormGroup key={`form_group_${field}`} row>
											<Label htmlFor={`input_${field}`} lg="2">
												<T>{field}</T>
											</Label>
											<Col lg="10">
												<Input
													id={`input_${field}`}
													readOnly={this.initialValues[field]!=='' && field !== 'email'}
													type={field==='email' ? 'email' : 'text'}
													name={field}
													value={userDetails[field]}
													onChange={this.handleInputChange}
													required
												/>
											</Col>
										</FormGroup>
									))}
									<FormGroup check>
										<Label check>
											<Input type="checkbox" checked={declaration} onChange={(e) => this.setState({declaration: e.target.checked})}/>{' '}
											<T>declare_true_and_correct</T>
										</Label>
									</FormGroup>
									<Row>
										<Col className="text-center">
											<Button disabled={!declaration} color="success"><T>submit</T></Button>
										</Col>
									</Row>
								</FormGroup>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	}
}

OAuthRegister = connect(null)(OAuthRegister);

export default OAuthRegister;
