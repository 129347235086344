import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import Loadable from 'react-loadable';

import { login } from '../ducks/profile';
import { listen, closeSSE } from '../ducks/sse';
import { Loading } from '../components';
import { requestSettings } from '../ducks/ui/settings';
// import Home from './home';
// import Page404 from './pages/page404';

const Home = Loadable({
	loader: () => import('./home'),
	loading: Loading
});

const Page404 = Loadable({
	loader: () => import('./pages/page404'),
	loading: Loading
});

class Content extends Component {

	constructor(props) {
		super(props);
		this.eventSource = null;
	}

	handleUnload = (e) => {
		e.preventDefault();
		if (this.eventSource)
			this.eventSource.close();
		this.props.dispatch(closeSSE());
		// e.returnValue = '';
	}

	componentDidMount() {
		const { dispatch, role } = this.props;
		dispatch( login() );
		dispatch( requestSettings() );
		if (role && role !== 'guest') {
			this.eventSource = this.props.dispatch( listen() );
		}
		window.addEventListener('beforeunload', this.handleUnload);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.role !== this.props.role && this.props.role !== 'guest') {
			if (this.eventSource)
				this.eventSource.close();
			this.eventSource = this.props.dispatch( listen() );
		}
	}

	componentWillUnmount() {
		window.removeEventListener(this.handleUnload);
	}

	render() {

		const { settings_pending } = this.props;

		if (settings_pending)
			return <Loading/>;

		return (
			<Switch>
				<Route path="/" name="home" component={Home}/>
				<Route component={Page404} />
			</Switch>
		);
	}
}

const mapStateToProps = (state) => ({
	settings_pending: state.ui.settings.pending,
	role: state.profile.user.role,
});

Content = connect(mapStateToProps)(Content);

export default withRouter(Content);
