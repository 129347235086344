import { menuReducers, menuInitialState } from 'modules';

// Actions
const TOGGLE_SIDEBAR = 'menu/TOGGLE_SIDEBAR';
const MINIMIZE_SIDEBAR = 'menu/MINIMIZE_SIDEBAR';
const SET_SIDEBAR_STATE = 'menu/SET_SIDEBAR_STATE';
const TOGGLE_ASIDE = 'menu/TOGGLE_ASIDE';
const OPEN_ASIDE = 'menu/OPEN_ASIDE';
const CLOSE_ASIDE = 'menu/CLOSE_ASIDE';
const SET_ASIDE_STATE = 'aside/SET_ASIDE_STATE';
const CLEAR_ASIDE_STATE = 'aside/CLEAR_STATE';
const UPDATE_MENU_ITEMS = 'menu/UPDATE_MENU_ITEMS';

// Reducer
const initialState = {
	isSidebarOpen: true,
	isSidebarMinimized: false,
	isAsideMenuOpen: false,
	asideState: {},
	items: [],
	...menuInitialState
};

export default (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_SIDEBAR:
	  		return {
				...state,
				isSidebarOpen: !state.isSidebarOpen
			};

		case MINIMIZE_SIDEBAR:
			return {
				...state,
				isSidebarMinimized: !state.isSidebarMinimized
			}

		case SET_SIDEBAR_STATE:
			return {
				...state,
				isSidebarOpen: action.sidebarState
			}

		case TOGGLE_ASIDE:
			return {
				...state,
				isAsideMenuOpen: !state.isAsideMenuOpen
			}

		case OPEN_ASIDE:
			return {
				...state,
				isAsideMenuOpen: true,
			}

		case CLOSE_ASIDE:
			return {
				...state,
				isAsideMenuOpen: false,
			}

		case SET_ASIDE_STATE:
			return {
				...state,
				asideState: {
					...state.asideState,
					...action.asideState
				}
			}

		case CLEAR_ASIDE_STATE:
			return {
				...state,
				asideState: {}
			}

		case UPDATE_MENU_ITEMS:
			return {
				...state,
				items: [...action.items]
			}

		default:
			let moduleState;
			if (menuReducers)
				menuReducers.forEach(reducer => {
					if (!moduleState && typeof reducer === 'function')
						moduleState = reducer(state, action);
				});
			let finalState = moduleState
				? {...state, ...moduleState}
				: state;
	  		return finalState;
  	}
};

// Action creators
export const toggleSidebar = () => ({
	type: TOGGLE_SIDEBAR,
});

export const setSidebarState = (sidebarState) => ({
	type: SET_SIDEBAR_STATE,
	sidebarState,
});

export const minimizeSidebar = () => ({
	type: MINIMIZE_SIDEBAR,
});

export const toggleAside = () => ({
	type: TOGGLE_ASIDE,
});

export const openAside = () => ({
	type: OPEN_ASIDE,
});

export const closeAside= () => ({
	type: CLOSE_ASIDE,
});

export const setAsideState = (asideState) => ({
	type: SET_ASIDE_STATE,
	asideState
});

export const clearAsideState = () => ({
	type: CLEAR_ASIDE_STATE,
});

export const updateMenuItems = (items) => ({
	type: UPDATE_MENU_ITEMS,
	items,
});
