import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import PropTypes from 'prop-types';
import T from 'modules/i18n';
import * as roles from 'core/model/roles';

const shorten = (string) => {
	const limit = 25;
	if (string.length <= limit)
		return string;
	return string.substring(0, 22) + '...';
}

class Selector extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isDropdownOpen: false,
		};
	}

	toggleDropdown = () => {
		this.setState({isDropdownOpen: !this.state.isDropdownOpen});
	}

	render() {
		const { isDropdownOpen } = this.state;
		const { userid, membership, active } = this.props.appProfile;
		if (!userid || this.props.role !== roles.AUTHORIZED)
			return null;

		return (
			<Dropdown nav isOpen={isDropdownOpen} toggle={() => this.toggleDropdown()}>
				<DropdownToggle caret outline color="info" className="align-text-top">
					{(active !== null && active !== '') ? shorten(membership[active].name) : '--- ---'}
				</DropdownToggle>
				<DropdownMenu className="p-0 scroller max-vh-75" right>
					{ this.props.appProfile.type === 'natural' &&
						<DropdownItem
							className="border border-bottom"
							tag="div"
							onClick={() => this.props.handleSelect('')}
							active={active==='--- ---'}
						>
							<T>no representation</T>
						</DropdownItem>
					}
					{ Object.entries(membership).map(([identifier, org]) => (
						<DropdownItem
							className="border border-bottom"
							tag="div"
							key={`org_selector_${identifier}`}
							onClick={() => this.props.handleSelect(identifier)}
							active={active===identifier}
						>
							<div style={{whiteSpace: "normal"}}>
								<i className={org.type === 'person' ? 'mr-2 fa fa-user' : 'mr-2 fa fa-industry'}/>{org.name}
							</div>
							<div className="text-right fw-semibold"><i className="fa fa-id-card-o mr-2"/>{identifier}</div>
							{ org.address &&
								<div><i className="fa fa-map-marker"/>{' '}{org.address}</div>
							}
						</DropdownItem>
					))}
				</DropdownMenu>
			</Dropdown>
		);
	}
}

Selector.propTypes = {
	appProfile: PropTypes.object.isRequired,
	handleSelect: PropTypes.func.isRequired,
	role: PropTypes.string.isRequired,
};

export default Selector;
