import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Table } from 'reactstrap';

class EditModals extends Component {

	handleRowSelect = (uuid) => {
		const { context } = this.props;
		const selectedModal = uuid === context.selectedModal ? '' : uuid;
		context.set({selectedModal});
	}

	render() {
		const { context } = this.props;
		return (
			<>
				<Nav tabs>
					<NavItem>
						<NavLink className="active text-info p-2">Modals</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab="1" className="scroller mx-2 mt-2">
					<TabPane tabId="1">
						<Table className="w-100 mw-100">
							<tbody>
								{context.modals ? Object.entries(context.modals).map(([uuid, name]) => (
									<tr
										key={`tr_${uuid}`}
										onClick={() => this.handleRowSelect(uuid)}
										className={context.selectedModal===uuid ? 'row-selected row-selectable' : 'row-selectable'}
									>
										<td>{name}</td>
									</tr>
								)) : null}
							</tbody>
						</Table>
					</TabPane>
				</TabContent>
			</>
		);
	}
}

export default EditModals;
