import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, CardBody, Row, Col, Input, Label, Form, FormGroup, Button } from 'reactstrap';
import { initContext, setContext } from 'core/ducks/context';
import { getData, updateData, postData, deleteData } from 'core/ducks/update';
import { toggleModal } from 'core/ducks/ui/modal';
import Alert from 'core/views/modals/alert';
import { default as T, getLocale } from 'modules/i18n';
import { Editor } from 'editor';

class EditModals extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedModal: '',
			method: 'put',
			values: {
				name: '',
				content: '',
			},
		};
		this.actions = bindActionCreators({toggleModal}, props.dispatch);
	}

	setContext = (newContext) => {
		this.setState({...newContext});
	}

	handleNew = () => {
		this.setState({method: 'post', values: {name: '', content: ''}})
	}

	getData = () => {
		return this.props.dispatch(getData('flows/modal'))
			.then(modals => {
				this.props.dispatch(setContext({modals}));
			});
	}

	componentDidMount() {
		this.props.dispatch(initContext({
			modals: [],
			selectedModal: this.state.selectedModal,
			set: this.setContext
		}));
		this.getData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.selectedModal !== this.state.selectedModal) {
			const { selectedModal } = this.state;
			this.props.dispatch(setContext({selectedModal}));
			if (selectedModal !== '') {
				this.props.dispatch(getData(`flows/modal/uuid/${selectedModal}/fields/name;content`))
					.then(values => {this.setState({
						values: {
							...this.state.values,
							...values,
						},
						method: 'put',
					})});
			} else {
				this.setState({values: {name: '', content: ''}, method: 'put'});
			}
		}
	}

	handleInputChange = ({target}) => {
		this.setState({
			values: {
				...this.state.values,
				[target.name]: target.value,
			}
		});
	}

	handleEditorChange = (content) => {
		this.setState({
			values: {
				...this.state.values,
				content
			}
		});
	}

	handleSubmit = (event) => {
		event.preventDefault();
		const { method, values, selectedModal } = this.state;
		if (method === 'post') {
			this.props.dispatch(postData('flows/modal', values))
				.then(selectedModal => {
					this.getData().then(() => this.setState({selectedModal, method: 'put'}));
				});
		} else if (method === 'put') {
			this.props.dispatch(updateData(`flows/modal/uuid/${selectedModal}`, values));
		}
	}

	handleDelete = () => {
		this.props.dispatch(deleteData(`flows/modal/uuid/${this.state.selectedModal}`))
			.then(() => {
				this.getData().then(() => this.setState({selectedModal: ''}));
			})
	}

	render() {
		const { values } = this.state;
		if (this.state.method === 'put' && this.state.selectedModal === '')
			return (
				<Card>
					<CardBody>
						Select a modal from the aside menu, or <Button color="success" outline onClick={this.handleNew}>create new</Button>
					</CardBody>
				</Card>
			);

		const locale = this.props.dispatch(getLocale());

		return (
			<Card className="my-3">
				<CardBody>
					<Form onSubmit={this.handleSubmit}>
						<Row>
							<Col>
								<FormGroup inline>
									<Label>
										Name:
										<Input required name="name" value={values.name} onChange={this.handleInputChange}/>
									</Label>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<Label>Content:</Label>
								<FormGroup>
									<Editor
										locale={locale}
										name="content"
										value={values.content}
										onChange={this.handleEditorChange}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col className="text-right">
								{ this.state.method === 'put' &&
									<Button color="danger" className="mr-2" onClick={
										() => this.actions.toggleModal(true,
											<Alert
												toggle={this.actions.toggleModal}
												title="drop confirm"
												message="do you wish to continue"
												onConfirm={() => this.handleDelete()}
											/>
										)
									}>
										<T>delete</T>
									</Button>
								}
								<Button color="success">
									<T>submit</T>
								</Button>
							</Col>
						</Row>
					</Form>
				</CardBody>
			</Card>
		);
	}

}

const mapStateToProps = (state) => ({});

EditModals = connect(mapStateToProps)(EditModals);

export default EditModals;
