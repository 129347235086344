import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	Row, Col, Nav, NavItem, NavLink, TabContent, TabPane,
	Table, Form, FormGroup, FormFeedback, Input, Label, Button
} from 'reactstrap';

import { toggleModal } from 'core/ducks/ui/modal';
import Alert from 'core/views/modals/alert';
import { getData, updateData, postData, deleteData } from 'core/ducks/update';
import { getValidation, validate } from 'core/ducks/forms';
import T from 'modules/i18n';
import { characterConverter as converter } from 'core/model/lib';
import { StaticRoutes, DynamicRoutes } from '../../model/routes';
import { buildPath } from 'core/model/lib/urlTools';

class Editform extends Component {

	constructor(props) {
		super(props);
		this.actions = bindActionCreators({toggleModal}, props.dispatch);
		this.initialValues = {
			mname: '',
			label: ''
		};
		this.state = {
			values: {...this.initialValues},
			data: {},
			ready: false,
			activeTab: '1',
			selectedRow: '',
			mnameEdited: false,
			under_submit: false,
			listOfProtected: [],
			isProtected: false,
		};

		this.toggle = this.toggle.bind(this);
		this.handleSelectRow = this.handleSelectRow.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	toggle(tab) {
		if (this.state.activeTab !== tab)
			this.setState({activeTab: tab});
	}

	handleSelectRow(key) {
		let selectedRow = key===this.state.selectedRow ? '' : key;
		if (selectedRow !== '') {
			let path = buildPath(DynamicRoutes.EditForms, [selectedRow]);
			this.props.history.push(path);
		} else {
			this.props.history.push(StaticRoutes.EditForms);
		}
	}

	handleInputChange(event) {
		const target = event.target;
		let value = target.value;
		if (target.name === 'mname') {
			value = value.toLowerCase();
			value = value.split(' ').join('_');
			value = value.split('-').join('_');
			if (!/^([a-zA-Z0-9_]*)$/.test(value))
				return;
			this.setState({mnameEdited: value!==''});
		}
		if (!this.state.mnameEdited && target.name === 'label') {
			this.setState({
				values: {
					label: value,
					mname: converter(value),
				}
			});
		} else {
			this.setState({
				values: {
					...this.state.values,
					[target.name]: value
				}
			});
		}
	}

	handleFormSubmit(event) {
		event.preventDefault();
		if (JSON.stringify(this.state.values) !== JSON.stringify(this.initialValues)) {
			this.props.dispatch(validate(this.state.values, this.props.rules)).then(() => {
				this.setState({
					under_submit: true,
					mnameEdited: true
				});
			});
		}
	}

	handleDelete() {
		const { form } = this.props.context;
		if (form)
			this.props.dispatch( deleteData(`admin/forms/mname/${form}`) ).then(() => {
				let data = Object.keys(this.state.data)
					.filter((key) => key!==form)
					.reduce((obj, key) => ({
						...obj,
						[key]: this.state.data[key]
					}), {});
				this.setState({data}, () => {
					this.handleSelectRow(form);
					this.props.history.replace(StaticRoutes.EditForms);
				});
			});
	}

	getFormData = (form, data=null) => {
		const newState = data ? {data, ready: true} : {};
		if (!form) {
			this.initialValues = {mname: '', label: ''};
			this.setState({...newState, values: {...this.initialValues}, selectedRow: ''});
			this.props.context.set({fields: {}, fieldsUpdated: true});
			return;
		}
		if (!Object.keys(this.props.list.forms.data).includes(form)) {
			this.props.history.replace(StaticRoutes.EditForms);
		} else {
			const { listOfProtected } = this.state;
			this.props.dispatch(getData(`admin/forms/form/${form}`)).then(fields => {
				this.initialValues = {mname: form, label: data ? data[form]: this.props.list.forms.data[form]};
				this.setState({...newState, values: {...this.initialValues}, selectedRow: form, mnameEdited: true, isProtected: listOfProtected.includes(form)});
				this.props.context.set({fields, fieldsUpdated: true});
			});
		}
	}

	componentDidMount() {
		this.props.dispatch( getValidation('form') );

		this.props.dispatch( getData('admin/protected_forms') ).then(listOfProtected => {
			this.setState({listOfProtected});
			if (this.props.list.forms && this.props.list.forms.data)
				this.getFormData(this.props.context.form, this.props.list.forms.data);
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.context.form !== this.props.context.form)
			this.getFormData(this.props.context.form)

		if (
			(!prevProps.list.forms || prevProps.list.forms.pending || !prevProps.list.forms.data) &&
			(this.props.list.forms && !this.props.list.forms.pending && this.props.list.forms.data)
		)
			this.getFormData(this.props.context.form, this.props.list.forms.data);

		if (!prevState.under_submit && this.state.under_submit) {
			if (!this.props.valid) {
				this.setState({
					under_submit: false,
				});
			}
			this.props.dispatch( this.state.selectedRow
				? updateData(
					`admin/forms/mname/${this.state.selectedRow}`,
					{mname: this.state.values.mname, label: this.state.values.label}
				)
				: postData('admin/forms', this.state.values)
			).then(() => {
				this.initialValues = {...this.state.values};
				this.setState({
					data: {...this.state.data, [this.initialValues.mname]: this.initialValues.label},
					under_submit: false
				}, () => {
					if (this.state.selectedRow !== this.state.values.mname) {
						let data = Object.keys(this.state.data)
							.filter(key => key !== this.state.selectedRow)
							.reduce((obj, key) => ({
								...obj,
								[key]: this.state.data[key]
							}), {});
							this.setState({ data });
					}
					if (!this.state.selectedRow || this.state.selectedRow !== this.state.values.mname)
						this.handleSelectRow(this.state.values.mname);
				})
			}).catch(() => {
				this.setState({under_submit: false});
			});
		}
	}

	render() {

		const { validation_msgs } = this.props;
		const { selectedRow, data } = this.state;
		const { messages } = this.props.i18n || {messages: {}};

		return (
			<React.Fragment>
				<Nav tabs>
					<NavItem>
						<NavLink
							className={this.state.activeTab==='1' ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggle('1'); }}
						>
							<T>forms</T>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={this.state.activeTab==='2' ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggle('2'); }}
						>
							<T>{selectedRow ? 'edit' : 'add'}</T>
						</NavLink>
					</NavItem>
				</Nav>
				{ this.state.ready &&
					<TabContent activeTab={this.state.activeTab} className="scroller mx-2 mt-2">
						<TabPane tabId="1">
							<Table className="w-100 mw-100">
								<tbody>
									{ Object.keys(data).map((key) => (
										<tr
											key={`tr_${key}`}
											onClick={() => this.handleSelectRow(key)}
											className={selectedRow===key ? 'row-selected row-selectable' : 'row-selectable'}
										>
											<td>{data[key]}</td>
										</tr>
									)) }
								</tbody>
							</Table>
						</TabPane>
						<TabPane tabId="2">
							<Form onSubmit={this.handleFormSubmit}>
								<FormGroup>
									<Label className="w-100">
										<T>label</T>
										<Input
											type="text"
											value={this.state.values.label}
											name="label"
											valid={validation_msgs.label===''}
											onChange={this.handleInputChange}
										/>
										<FormFeedback><T>{validation_msgs.label}</T></FormFeedback>
									</Label>
								</FormGroup>
								<FormGroup>
									<Label className="w-100">
										<T>identifier</T>
										<Input
											type="text"
											value={this.state.values.mname}
											name="mname"
											valid={validation_msgs.mname===''}
											onChange={this.handleInputChange}
											readOnly={this.state.isProtected}
										/>
										<FormFeedback><T>{validation_msgs.mname}</T></FormFeedback>
									</Label>
								</FormGroup>
								<Row>
									<Col className="text-center">
										<Button
											color="danger"
											type="button"
											className={selectedRow==='' ? 'd-none' : 'mr-2'}
											title={messages.delete || 'delete'}
											disabled={this.state.isProtected}
											onClick={
												() => this.actions.toggleModal(true,
													<Alert
														toggle={this.actions.toggleModal}
														title="drop confirm"
														message="do you wish to continue"
														onConfirm={this.handleDelete}
													/>
												)
											}
										>
											<i className="fa fa-trash" />
										</Button>
										<Button color="primary" type="submit"><T>save</T></Button>
									</Col>
								</Row>
							</Form>
						</TabPane>
					</TabContent>
				}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
	list: state.list,
	validation_msgs: state.forms.validation_msgs,
	rules: state.forms.validation.rules,
	valid: state.forms.valid,
});

Editform = connect(mapStateToProps)(Editform);

export default Editform;
