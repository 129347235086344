import React, { useState } from 'react';
import { Card, CardBody, Form, Row, Col, Button } from 'reactstrap';

import { Table, Title, Tr, Tbody } from 'table';
import T from 'modules/i18n';

const Options = ({node, content, disabled, values, onSubmit}) => {

	const handleSubmit = (e) => {
		e.preventDefault();
		const formdata = new FormData();
		formdata.append(node.mname, selectedRow);
		onSubmit(formdata);
	}

	const [selectedRow, setSelectedRow] = useState(values[node.mname]);

	return (
		<Card>
			<CardBody>
				<Row>
					<Col>
						<Table>
							<Title>{node.content_text}</Title>
							<Tbody>
								<Tr
									data={content.options}
									onRowClick={disabled ? undefined : (id) => setSelectedRow(id)}
									selectedRow={selectedRow}
								/>
							</Tbody>
						</Table>
					</Col>
				</Row>
				<Form onSubmit={handleSubmit}>
					<input type="hidden" name={node.mname} value={selectedRow}/>
					<Row>
						<Col className="d-flex justify-content-end">
							<Button color="warning" disabled={disabled}><T>submit</T></Button>
						</Col>
					</Row>
				</Form>
			</CardBody>
		</Card>
	);

}

export default Options;
