import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import truncate from 'truncate-html';

class Snippet extends Component {

	constructor(props) {
		super(props);
		this.state = {
			bg: '',
		};
	}

	render() {

		const { body, createdAt, attachments, read, className, ...otherProps } = this.props;

		return (
			<Toast {...otherProps} className={`${className} ${this.state.bg}`} onMouseEnter={() => this.setState({bg: 'bg-light'})} onMouseLeave={() => this.setState({bg: ''})}>
				<ToastHeader className={`text-right ${read ? '' : 'text-warning'} ${this.state.bg}`} icon={<i className={read ? 'fa fa-envelope-open-o' : 'fa fa-envelope-o'}/>}>
					{createdAt}
				</ToastHeader>
				<ToastBody>
					<p className="m-0" dangerouslySetInnerHTML={{__html: truncate(body, 40, {byWords: true})}}/>
					{ attachments > 0 &&
						<p className="m-0 pr-1 text-right">
							<i className="fa fa-paperclip fa-lg"/>{` ${attachments}`}
						</p>
					}
				</ToastBody>
			</Toast>
		);
	}

}

Snippet.propTypes = {
	body: PropTypes.string.isRequired,
	createdAt: PropTypes.string.isRequired,
	attachments: PropTypes.number.isRequired,
	read: PropTypes.bool.isRequired,
};

export default Snippet;
