import React, { Component } from 'react';
import { connect } from 'react-redux';
import withApp from './app';
import { uploadData } from 'core/ducks/upload';
import OrgDetails from '../components/orgDetails';
import AddProxyModal from '../modals/addProxy';

class Administration extends Component {

	constructor(props) {
		super(props);
		this.state = {
			shouldUpdateProxyData: false,
			isProxyModalOpen: false,
		};
	}

	toggleProxyModal = () => {
		this.setState({
			isProxyModalOpen: !this.state.isProxyModalOpen,
		});
	}

	handleProxySubmit = (data) => {
		this.props.dispatch(uploadData('app/member/role/proxy', data))
			.then(() => this.setState({shouldUpdateProxyData: true, isProxyModalOpen: false}));
	}

	setShouldUpdateProxyData = (shouldUpdateProxyData) => {
		this.setState({shouldUpdateProxyData});
	}

	render() {
		return (
			<>
				<OrgDetails
					{...this.props}
					hasProxies={this.props.type==='legal' ? false : true}
					reloadProxies={this.state.reloadProxies}
					handleAddProxy={this.toggleProxyModal}
					shouldUpdateProxyData={this.state.shouldUpdateProxyData}
					setShouldUpdateProxyData={this.setShouldUpdateProxyData}
					canUpdateAdmin={this.props.canUpdateAdmin}
				/>
				<AddProxyModal isOpen={this.state.isProxyModalOpen} toggle={this.toggleProxyModal} onSubmit={this.handleProxySubmit}/>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	locality: state.appProfile.activeLocality,
	type: state.appProfile.type,
	canUpdateAdmin: state.appProfile.roles.includes('owner'),
});

Administration = connect(mapStateToProps)(Administration);

export default withApp(Administration);
