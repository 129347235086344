import React, { Component } from 'react';
import { Card, CardBody, CardTitle, CardFooter } from 'reactstrap';
import Barcode from 'react-barcode';

class RfPayment extends Component {

	constructor(props) {
		super(props);
		this.state = {
			text: '',
			rf: '',
		}
	}

	componentDidMount() {
		this.formatContent();
	}

	formatContent = () => {
		const formatRf = (rf) => {
			if (!rf)
				return rf;
			const elements = rf.split('').map((d, index) => {
				if (index === 0 || index % 4 !== 0) return d;
				if (index % 8 !== 0) return `<b>${d}`;
				if (index % 8 === 0) return `</b>${d}`;
				return '';
			});
			return elements.join('');
		}

		const { node, values } = this.props;
		const amount = String(parseFloat(values.amount).toFixed(2)).replace('.', '').padStart(11, '0');
		const rf = values.rf ? values.rf + amount : values.rf;

		values.rf = formatRf(values.rf);
		const placeholders = {RF: 'rf', amount: 'amount', duration: 'duration', end_datetime: 'expires'};
		let text = node.content_text;
		Object.entries(placeholders).forEach(([placeholder, variable]) => text = text.replace(`[[${placeholder}]]`, values[variable]));

		this.setState({text, rf})
	}

	componentDidUpdate(prevProps) {
		if (prevProps.node !== this.props.node || prevProps.values !== this.props.values)
			this.formatContent();
	}

	render() {
		const { className } = this.props;
		return (
			<Card className={className}>
				<CardBody>
					<CardTitle dangerouslySetInnerHTML={{__html: this.state.text}}/>
				</CardBody>
				{ (this.state.rf && this.state.rf !== '') &&
					<CardFooter className="d-flex justify-content-center"><Barcode value={this.state.rf}/></CardFooter>
				}
			</Card>
		);
	}
}

export default RfPayment;
