import { Api } from '../api';
import { pushNotification } from './notifications';


// Actions
const REQUESTING = 'api/profile/REQUESTING';
const FETCHED = 'api/profile/FETCHED';
const SENDING_CREDENTIALS = 'api/login/SENDING_CREDENTIALS';
const LOGGED_IN = 'api/LOGGED_IN';
const LOGGED_OUT = 'api/LOGGED_OUT';
const TIME_OUT = 'login/TIME_OUT';
const CHECK_COOKIE = 'CHECK_COOKIE';
const CLEAR_TIMEOUT = 'CLEAR_TIMEOUT';
const WRONG_CREDENTIALS = 'WRONG_CREDENTIALS';


// Initial state

const initialState = {
	pending: false,
	login_pending: true,
	user: {},
	loggedin: false,
	time_out: false,
	intervalID: -1,
};


// Reducer

export default (state=initialState, action) => {
	switch (action.type) {
		case SENDING_CREDENTIALS:
		case WRONG_CREDENTIALS:
			return {
				...state,
				login_pending: action.login_pending,
				pending: action.pending
			};

		case FETCHED:
			return {
				...state,
				pending: action.pending,
				user: action.profile
			}

		case LOGGED_IN:
			return {
				...state,
				login_pending: action.login_pending,
				loggedin: action.loggedin,
				time_out: action.time_out
			}

		case LOGGED_OUT:
			return {
				...state,
				pending: action.pending,
				user: action.profile,
				loggedin: action.loggedin,
				login_pending: action.login_pending
			}

		case TIME_OUT:
		case CLEAR_TIMEOUT:
			return {
				...state,
				time_out: action.time_out,
				intervalID: action.intervalID,
			}

		case REQUESTING:
			return {
				...state,
				pending: action.pending
			}

		case CHECK_COOKIE:
		default:
			return state;
	}
}


// Action creators

const requestingProfile = () => ({
	type: REQUESTING,
	pending: true
});

const fetchedProfile = (profile) => ({
	type: FETCHED,
	profile,
	pending: false
});

const sendingCredentials = () => ({
	type: SENDING_CREDENTIALS,
	login_pending: true,
	pending: true
});

const loggedIn = (loggedin) => ({
	type: LOGGED_IN,
	login_pending: false,
	loggedin,
	time_out: !loggedin
});

const loggedOut = () => ({
	type: LOGGED_OUT,
	pending: false,
	profile: {},
	loggedin: false,
	login_pending: false
});

const timeOut = (intervalID) => ({
	type: TIME_OUT,
	time_out: true,
	intervalID,
});

const wrongCredentials = () => ({
	type: WRONG_CREDENTIALS,
	login_pending: false,
	pending: false
});



// Thunk action creators

export const requestProfile = () => (dispatch) => {
	dispatch(requestingProfile());
	let a = new Api('profile');
	let status = '';
	a.Get().then((response) => {
		status = response.status;
		return response.json();
	}).then((json) => {
		if (status === 200) {
			dispatch(fetchedProfile(json));
		} else {
			dispatch(loggedOut());
		}
	});
}

export const login = (credentials={}) => (dispatch) => {
	dispatch(sendingCredentials());
	let a = new Api('login');
	let status = '';
	let promise = new Promise((resolve, reject) => {
		a.Post(credentials).then((response) => {
			status = response.status;
			return response.json();
		}).then((json) => {
			if (status === 200) {
				dispatch(loggedIn(json.loggedin));
				if ((credentials.username || credentials.password) && !json.loggedin) {
					dispatch(pushNotification({body: 'wrong credentials', type: 'warning'}));
					dispatch(wrongCredentials());
					reject();
				} else {
					dispatch(requestProfile());
					resolve();
				}
			} else {
				dispatch(loggedOut());
				reject();
			}
		});
	});

	return promise;
}

export const logout = () => (dispatch) => {
	let promise = new Promise((resolve, reject) => {
		let a = new Api('login');
		if (document.cookie !== '') {
			a.Delete().then((response) => {
				return response.json();
			}).finally((json) => {
				dispatch(loggedOut());
				dispatch(requestProfile());
				resolve();
			});
		} else {
			dispatch(loggedOut());
			dispatch(requestProfile());
			resolve();
		}
	});

	return promise;
}

export const modalLogin = (credentials) => (dispatch, getState) => {
	let promise = new Promise((resolve, reject) => {
		let a = new Api('login');
		let status;
		if (credentials.username === getState().profile.user.user_id) {
			a.Post(credentials).then((response) => {
				status = response.status;
				return response.json();
			}).then((json) => {
				if (status === 200 && json.loggedin) {
					dispatch(loggedIn(json.loggedin));
				} else {
					dispatch(pushNotification({body: 'wrong credentials', type: 'warning'}));
				}
				resolve();
			});
		} else {
			dispatch(pushNotification({body: 'you are trying to login with a different username', type: 'warning'}));
			reject();
		}
	});

	return promise;
}

export const checkCookie = () => (dispatch, getState) => {
	let promise = new Promise((resolve, reject) => {
		if (!getState().profile.loggedin) {
			resolve();
			return promise;
		}
		dispatch({
			type: CHECK_COOKIE
		});

		if (!getCookie('token')) {
			var timeout;
			const checkTimeout = () => {
				timeout = getState().profile.time_out;
				if (!timeout) {
					if (getState().profile.intervalID !== -1) {
						clearInterval(getState().profile.intervalID);
						resolve();
					} else {
						reject();
					}
				}
			}
			let intervalID = setInterval(checkTimeout, 500000);
			dispatch(timeOut(intervalID));
		} else {
			resolve();
		}
	});

	return promise;
}

const getCookie = (cname) => {
	const ca = document.cookie.split(';');
	let value;
	ca.forEach(cookie => {
		let parts = cookie.trim().split('=');
		if (parts[0] === cname)
			value = parts[1];
	});
	return value;
}

export const clearTimeout = () => (dispatch, getState) => {
	let intervalID = getState().profile.intervalID;
	clearInterval(intervalID);
	dispatch({
		type: CLEAR_TIMEOUT,
		time_out: false,
		intervalID: -1
	});
}


// Selectors

export const loggedin = (state) => {
	return state.profile.loggedin;
}

export const getProfile = (state) => {
	return state.profile.user;
}

export const pending = (state) => {
	return state.profile.pending;
}

export const getRole = (state) => {
	return state.profile.user.role;
}
