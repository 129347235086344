import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	Row, Col, FormGroup, Card, CardText, CardBody, Modal, ModalBody,
	InputGroup, InputGroupAddon, InputGroupText,
} from 'reactstrap';

import { search, resetSearch } from '../../ducks/search';
import { getData } from 'core/ducks/update';
import T from 'modules/i18n';
import { Pagination } from 'table';
import { DynamicRoutes } from 'app/model/routes';
import { buildPath } from 'core/model/lib/urlTools';
import QuickView from 'flows/components/quickView';

import '../../scss/search.scss';

const parseHeadline = (headline) => {
	let rows = headline.split('[[stop]]');
	rows = rows.map(row => {
		const elements = row.split('[[:]]');
		if (elements.length > 1)
			elements[0] = `<small class="text-muted">${elements[0]}</small>`
		return elements.join(' ').replace('[[stop', '').replace('stop]]', '').replace('[[:', '').replace(':]]', '');
	});
	return rows.join('<br/>');
}

class Result extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isTooltipOpen: false,
			applicationData: {},
			uuid: null,
		};
		this.timeout = null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.isTooltipOpen && this.state.isTooltipOpen) {
			const { uuid } = this.props;
			if (this.state.uuid !== uuid) {
				this.props.dispatch( getData(`flows/application/view/${uuid}`) ).then(applicationData => {
					this.setState({
						applicationData,
						uuid,
					});
				})
			}
		}
	}

	render() {
		const { uuid, refreshing, headline, round } = this.props;
		return (
			<Row>
				<Col>
					<Card className="mx-5 rounded shadow-sm">
						<CardBody>
							<div className="text-right">
								<i
									className="fa fa-search mt-2 mr-2"
									role="link"
									id={`tooltip_${uuid}`}
									onMouseEnter={() => {
										this.timeout = setTimeout(() => {
											this.setState({isTooltipOpen: true});
										}, 200);
									}}
									onMouseLeave={() => {
										if (this.timeout) {
											clearTimeout(this.timeout);
											this.timeout = null;
										}
									}}
								/>
							</div>
							<CardText
								className={refreshing ? 'semi-transparent text-dark search-results' : 'text-dark search-results'}
								dangerouslySetInnerHTML={{__html: '...' + parseHeadline(headline) + '...'}}
							/>
							<CardText className="text-muted d-flex justify-content-end">
								<Link to={buildPath(DynamicRoutes.AdminApply, [round, uuid])} className="no-decoration" role="link">
									<T>view</T>
								</Link>
							</CardText>
						</CardBody>
					</Card>
				</Col>
				<Modal
					isOpen={this.state.isTooltipOpen}
					className="minw-xs-75 minw-lg-75 minw-xl-50 tooltip-modal"
					toggle={() => this.setState({isTooltipOpen: false})}
				>
					<ModalBody>
						<QuickView {...this.state.applicationData} uuid={uuid}/>
					</ModalBody>
				</Modal>
			</Row>
		);
	}
}
Result = connect(null)(Result);

class Search extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: props.search || '',
			page: 1,
			applicationData: {},
			selectedApplicationData: {},
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.resetSearch = this.resetSearch.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
	}

	componentDidMount() {
		if (this.state.search.trim() !== '')
			this.props.dispatch( search((this.state.search.trim()), this.state.page) );
	}

	componentWillUnmount() {
		this.props.dispatch( resetSearch() );
	}

	handleInputChange(event) {
		let target = event.target;
		this.setState({
			[target.name]: target.value
		});
	}

	resetSearch() {
		this.setState({
			search: '',
			page: 1,
		});
		this.props.dispatch( resetSearch() );
	}

	handlePageChange(page) {
		this.setState({
			page,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.search !== this.props.search)
			this.setState({search: this.props.search, page: 1});
		if (prevState.search.trim() !== this.state.search.trim())
			this.setState({page: 1});
		if (prevState.search.trim() !== this.state.search.trim() || prevState.page !== this.state.page) {
			if (this.state.search.trim() === '') {
				this.props.dispatch( resetSearch() );
			} else {
				this.props.dispatch( search((this.state.search.trim()), this.state.page) );
			}
		}
	}

	render() {
		const { search, page } = this.state;
		const { results, count, results_per_page, total_pages } = this.props;
		const safeResults = (results && Array.isArray(results)) ? results : [];

		return (
			<>
				<Row>
					<Col>
						<FormGroup className="mx-auto mt-5 mb-2 mw-786">
							<InputGroup>
								<input
									className="form-control rounded-left border-right-0 focus-no py-2 px-3"
									type="text"
									placeholder={this.props.i18n['type to search'] + '...'}
									name="search"
									value={search}
									onChange={this.handleInputChange}
									autoComplete="off"
									autoFocus
								/>
								<InputGroupAddon addonType="append">
									<InputGroupText className="bg-white rounded-right">
										{ search === '' ?
											<i className="fa fa-search text-primary"/>
											:
											<i className="fa fa-times" onClick={this.resetSearch}/>
										}
									</InputGroupText>
								</InputGroupAddon>
							</InputGroup>
						</FormGroup>
					</Col>
				</Row>

				{ count >= 0 &&
					<Row>
						<Col>
							<small className="text-muted mx-5 my-2">
								{count > 0 &&
									<span>
										{results_per_page*(page - 1) + 1}{' - '}
										{Math.min(results_per_page*page,count)}{' '}<T>from</T>{' '}
									</span>
								}
								<span>
									{count}{' '}<T>results</T>
								</span>
							</small>
						</Col>
					</Row>
				}

				{ safeResults.map(result =>
					<Result key={`result_${result.a_uuid}`} uuid={result.a_uuid} refreshing={this.props.refreshing} headline={result.headline} round={result.round}/>
				)}

				{ total_pages > 0 &&
					<Row>
						<Col>
							<Pagination
								className="mx-auto mt-3"
								page={this.state.page}
								total={total_pages}
								onClick={(page) => {
									if (page !== this.state.page)
										this.handlePageChange(page)}
								}
							/>
						</Col>
					</Row>
				}
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	search: state.search.query,
	results: state.search.results,
	count: state.search.count,
	results_per_page: state.search.results_per_page,
	page: state.search.page,
	total_pages: state.search.total_pages,
	refreshing: state.search.refreshing,
	view: state.update.response,
	http_status: state.update.status,
	i18n: state.i18n.messages,
});

Search = connect(mapStateToProps)(Search);

export default Search;
