import React, { Component } from 'react';
import { Input, FormGroup } from 'reactstrap';
import { uniqueId } from 'lodash';
import { TableContextConsumer } from './table';

class Filter extends Component {

	constructor(props) {
		super(props);
		this.scope = uniqueId('filter_');
	}

	componentDidMount() {
		const defaultValue = this.props.defaultValue || '';
		this.props.context.registerFilter(this.scope, defaultValue);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.defaultValue !== this.props.defaultValue) {
			const defaultValue = this.props.defaultValue || '';
			this.props.context.onFilterChange(this.scope, {target: {value: defaultValue}});
		}
	}

	render() {

		const {context, defaultValue, ...other} = this.props;

		return (
			<FormGroup>
				<Input
					{...other}
					type="select"
					onChange={(event) => {
						context.onFilterChange(this.scope, event);
						if (this.props.onChange)
							this.props.onChange(event);
					}}
					value={context.filterValue[this.scope]}
				>
					{this.props.children}
				</Input>
			</FormGroup>
		);
	}
}

Filter.displayName = 'Filter';

export default props => (
	<TableContextConsumer>
		{context => <Filter {...props} context={context} />}
	</TableContextConsumer>
);
