import React, { Component } from 'react';
import { Button as ReactstrapButton } from 'reactstrap';
import { TableContextConsumer } from './table';

class Button extends Component {

	render() {
		let { type, disabled, hidden, condition, ...other } = this.props;
		if (condition && !this.props.data[condition])
			return null;
		let button;
		switch (type) {
			case 'toolbox':
				button = (
					<TableContextConsumer>
						{ (context) =>
							<i
								{...other}
								className={`fa fa-1x fa-filter ${this.props.className}`}
								role="button"
								onClick={context.onToolboxButtonClick}
							/>
						}
					</TableContextConsumer>
				);
				break;

			case 'resetFilters':
				button = (
					<TableContextConsumer>
						{ (context) =>
							<ReactstrapButton
								{...other}
								className={`mt-3 ${this.props.className}`}
								type="reset"
							>
								{this.props.children}
							</ReactstrapButton>
						}
					</TableContextConsumer>
				);
			break;

			case 'csv':
			case 'xlsx':
				button = (
					<span
						{...other}
						className={`px-3 ${this.props.className}`}
						role="button"
					>
						<i className={`text-success fa ${type==='csv' ? 'fa-file-text' : 'fa-file-excel-o'} py-3 px-1`} />
						{this.props.children}
					</span>
				);
				break;

			case 'assign':
				button = (
					<i
						{...other}
						className={`fa fa-1x fa-user-plus ${this.props.className}`}
						role="button"
					/>
				)
				break;
			case 'view':
			case 'edit':
			case 'drop':
			case 'activate':
			default:
				let buttonClass;
				if (type === 'view') {
					buttonClass = 'fa fa-eye'
				} else if (type === 'edit') {
					buttonClass = 'fa fa-pencil-square-o'
				} else if (type === 'drop') {
					buttonClass = 'fa fa-trash-o';
				} else if (type === 'activate') {
					buttonClass = 'fa fa-check-circle-o';
				} else {
					buttonClass = `fa fa-${type}`;
				}
				let { index } = this.props.data;
				// let data = type=='view' ? unindexed_data : (type=='drop' ? index : this.props.data);
				let data = (type==='drop' || type==='activate') ? index : this.props.data;
				disabled = (disabled && typeof disabled==='function')
					? disabled(this.props.data)
					: false;
				let disabledClass = disabled ? 'text-muted' : '';
				hidden = (hidden && typeof hidden==='function')
					? hidden(this.props.data)
					: false;
				if (this.props.pressed && this.props.pressed.includes(data.token))
					buttonClass += ' text-warning';
				button = hidden ? null : (
					<i
						{...other}
						id={`table_button_${type || this.props.className.split(' ').join('_')}_${data.index || data}`}
						className={`${buttonClass} ${this.props.className || ''} ${disabledClass || ''}`}
						role={disabled ? '' : 'button'}
						onClick={() => {
							if (disabled)
								return null;
							if (typeof this.props.onClick == 'function')
								this.props.onClick(data);
						}}
						onMouseEnter={() => {
							if (disabled)
								return null;
							if (typeof this.props.onMouseEnter == 'function')
								this.props.onMouseEnter(data);
						}}
					/>
				);
				break;

		}
		return button;
	}
}

Button.displayName = 'Button';

export default Button;
