import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Modal,
	ModalHeader,
	ModalBody
} from 'reactstrap';

import T from 'modules/i18n';

class Message extends Component {

	render() {

		const { onConfirm, toggle, ...otherProps } = this.props;

		return (
			<Modal isOpen={true} className="modal-md" {...otherProps}>
				<ModalHeader toggle={toggle}><T>{this.props.title}</T></ModalHeader>
				<ModalBody>
					<T>{this.props.message}</T>
				</ModalBody>
			</Modal>
		);
	}
}

Message.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string.isRequired,
	toggle: PropTypes.func.isRequired,
}

export default Message;
