import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroup, InputGroupAddon, InputGroupText, FormFeedback } from 'reactstrap';
import T from 'modules/i18n';

const InvalidTypeFeedback = ({message, maxFileSize, accept}) => {
	switch (message) {
		case 'audio':
			return (<T>file content is not a valid audio format</T>);
		case 'image':
			return (<T>file content is not a valid image format</T>);

		case 'size':
			return (<><T>maximum size</T>: {`${maxFileSize} Mb`}</>);

		case 'accept':
			return (<><T>file input accepts</T>: {accept}</>);

		default:
			return null;
	}
};

class File extends Component {

	constructor(props) {
		super(props);
		this.state = {
			files: null,
			value: '',
			valid: null,
			invalidType: null,
		};

		this.fileInputRef = React.createRef();

		this.onChange = this.onChange.bind(this);
		this.handleBrowserClick = this.handleBrowserClick.bind(this);
		this.reader = new FileReader();
	}

	fileTypeValidation = async (file, filetype) => {
		const promise = () => {
			return new Promise((resolve, reject) => {
				this.reader.onload = (e) => {
					const element = filetype === 'audio' ? new Audio() : new Image();
					element.onload = () => {
						resolve(true);
					}
					element.onerror = () => {
						resolve(false);
					}
					element.src = e.target.result;
				}
			});
		}

		this.reader.readAsDataURL(file);
		const valid = await promise();
		return valid;
	}

	onChange(event) {
		let input = event.target;
		let { onChange, maxFileSize, accept } = this.props;
		let files = this.getSelectedFiles(input);
		this.validate(input.files, maxFileSize, accept);
		if (typeof onChange === 'function')
			onChange(event);

		this.setState({files});
	}

	validate = async (files, maxFileSize, accept) => {
		let valid = true;
		var invalidType;
		const filelist = Array.from(files);
		if (maxFileSize !== 0) {
			const validFileSize = filelist.filter(file => file.size > parseFloat(maxFileSize)*1024*1024).length === 0;
			if (!validFileSize) invalidType = 'size';
			valid = validFileSize && valid;
		}
		if (valid && accept) {
			var validAccept = false;
			const checkPromises = accept.split(',').map(rule => {
				return new Promise((resolve, reject) => {
					if (rule.startsWith('.')) {
						validAccept = filelist.filter(file => !file.name.endsWith(rule)).length === 0 || validAccept;
						invalidType = validAccept ? invalidType : 'accept';
						resolve();
					} else if (rule.includes('*')) {
						const filetype = rule.split('/')[0];
						if (filetype === 'video') {
							validAccept = filelist.filter(file => file.type.split('/')[0] !== 'video').length === 0 || validAccept;
							invalidType = validAccept ? invalidType : 'accept';
							resolve();
						} else if (filetype === 'audio' || filetype === 'image') {
							let acceptMimetype = filelist.filter(file => file.type.split('/')[0] !== filetype).length === 0;
							if (acceptMimetype) {
								// Check if the detected mimetype is fooled
								const filetypePromises = filelist.map(file => this.fileTypeValidation(file, filetype));
								Promise.all(filetypePromises).then(values => {
									validAccept = values.filter(v => v).length > 0 || validAccept;
									invalidType = validAccept ? invalidType : filetype;
									resolve();
								});
							} else {
								validAccept = acceptMimetype || validAccept;
								invalidType = validAccept ? invalidType : 'accept';
								resolve();
							}
						} else {
							console.warn(rule, 'is not a valid HTML accept value.');
						}
					} else {
						let acceptMimetype = filelist.filter(file => file.type !== rule).length === 0;
						if (acceptMimetype && ['audio', 'image'].includes(rule.split('/')[0])) {
							// Check if the detected mimetype is fooled in the case of audio and image
							const filetypePromises = filelist.map(file => this.fileTypeValidation(file, rule.split('/')[0]));
							Promise.all(filetypePromises).then(values => {
								validAccept = values.filter(v => v).length > 0 || validAccept;
								invalidType = validAccept ? invalidType : rule.split('/')[0];
								resolve();
							});
						} else {
							validAccept = acceptMimetype || validAccept;
							invalidType = validAccept ? invalidType : 'accept';
							resolve();
						}
					}
				})
			});
			await Promise.all(checkPromises);
			valid = validAccept && valid;
		}
		this.setState({valid, invalidType});
	}

	getSelectedFiles(input) {
		let files = Object.values(input.files).map(file => file.name);
		return files.join(', ');
	}

	handleBrowserClick(event) {
		if (this.props.readOnly) return;
		this.fileInputRef.current.click();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.valid !== this.state.valid) {
			this.props.onValidationChange(this.state.valid);
		}
	}

	render() {
		const {
			className, label, onChange, children, innerRef, buttonText, placeholder, value, required, readOnly, disabled, maxFileSize, onValidationChange, ...attributes
		} = this.props;
		const { files, invalidType, valid } = this.state;
		const invalid = valid === false ? {invalid: true} : {};

		return (
			<div className={this.props.className}>
				<input className="d-none" disabled={readOnly || disabled} type="file" {...attributes} ref={this.fileInputRef} onChange={this.onChange} />
				<InputGroup>
					<Input
						onChange={() => {}}
						value={files || ''}
						placeholder={label || placeholder || 'Choose file'}
						onClick={this.handleBrowserClick}
						valid={this.state.valid}
						{...invalid}
						required={required}
						disabled={readOnly || disabled}
					/>
					<InputGroupAddon addonType="append" onClick={this.handleBrowserClick}>
						<InputGroupText>{buttonText || 'Browse'}</InputGroupText>
					</InputGroupAddon>
					<FormFeedback invalid="true">
						<InvalidTypeFeedback message={invalidType} maxFileSize={maxFileSize} accept={this.props.accept}/>
					</FormFeedback>
				</InputGroup>
			</div>
		);
	}
}

File.propTypes = {
	className: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	label: PropTypes.node,
	onChange: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.func]),
	innerRef: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
		PropTypes.func,
	]),
	buttonText: PropTypes.string,
	placeholder: PropTypes.string,
	valid: PropTypes.bool,
	maxFileSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onValidationChange: PropTypes.func,
};

File.defaultProps = {
	maxFileSize: 0,
	onValidationChange: () => {}
}

export default File;
