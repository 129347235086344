import { Api } from 'core/api';
import { checkCookie } from 'core/ducks/profile';


// Actions

const FETCHED_MESSAGES = 'app-messages/MESSAGES_FETCHED'
const READ_MESSAGE = 'app-messages/MESSAGE_READ';
const RECEIVED_NEW_MESSAGE = 'app-messages/NEW_MESSAGE_RECEIVED';


// Initial state

const initialState = {
	unread: [],
};


// Reducer

export default (state=initialState, action) => {
	switch (action.type) {

		case FETCHED_MESSAGES:
			return {
				...state,
				unread: action.unread,
			};

		case READ_MESSAGE:
			return {
				...state,
				unread: [
					...state.unread.filter(m => m.uuid !== action.message)
				],
			};

		case RECEIVED_NEW_MESSAGE:
			return {
				...state,
				unread: [
					...state.unread.filter(m => m.uuid !== action.message.uuid),
					{...action.message},
				],
			};

		default:
			return state;
	}
};


// Action creators

const fetchedMessages = (unread) => ({
	type: FETCHED_MESSAGES,
	unread,
});

export const readMessage = (message) => ({
	type: READ_MESSAGE,
	message,
});

export const receivedNewMessage = (message) => ({
	type: RECEIVED_NEW_MESSAGE,
	message,
});


// Thunk action creators

export const getMessages = () => (dispatch) => {
	let promise = new Promise((resolve, reject) => {
		dispatch(checkCookie()).then(() => {
			let a = new Api('appmessages/message');
			a.Get().then(response => {
				return response.json();
			}).then(messages => {
				dispatch(fetchedMessages(messages));
			})
		});
	});
	return promise;
};
