import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import components from 'modules/components';
import store from './store';
import App from 'core/App';
import * as serviceWorker from './serviceWorker';

const { IntlProvider } = components;

function AppWithCallbackAfterRender() {
	return (typeof IntlProvider === 'function' || typeof IntlProvider === 'object') ? (
		<Provider store={store}>
			<IntlProvider>
				<App />
			</IntlProvider>
		</Provider>
	) : (
		<Provider store={store}>
			<App />
		</Provider>
	);
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<AppWithCallbackAfterRender />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
