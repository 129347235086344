import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, Form, FormText, FormGroup, FormFeedback, Label, Row, Col, Button } from 'reactstrap';
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react';

import { Input } from 'core/components';
import { DateInput } from 'input-fields';
import T from 'modules/i18n';

class StartRound extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTab: 0,
			selectedWorkflow: null,
			values: {
				workflow: '',
				mname: '',
				label: '',
				starts: '',
				ends: '',
				open: false,
			},
		};
	}

	selectWorkflowSubmit = (e) => {
		e.preventDefault();
		if (this.state.selectedWorkflow !== null)
			this.setState({
				values: {...this.state.values, workflow: this.state.selectedWorkflow},
				activeTab: 1
			});
	}

	handleFormSubmit = (e) => {
		e.preventDefault();
		this.props.onNewRoundSubmit(this.state.values);
	}

	handleInputChange = (e) => {
		const { name, type, value, checked } = e.target;
		this.setState({values: {
			...this.state.values,
			[name]: type==='checkbox' ? checked : value,
		}});
	}

	render() {
		const { isOpen, toggle, workflows, validationMessages } = this.props;
		const { selectedWorkflow, values } = this.state;

		return (
			<Modal isOpen={isOpen} size="lg">
				<ModalHeader toggle={toggle}>
					<T lettercase="capitalize">init round</T>
				</ModalHeader>
				<ModalBody>
					<CNav variant="pills" layout="justified" role="tablist">
						{['select workflow', 'details'].map((title, id) => (
							<CNavItem key={`start_workflow_modal_tab_${id}`}>
								<CNavLink
									href="#"
									onClick={() => this.setState({activeTab: id})}
									active={this.state.activeTab === id}
									disabled={id === 1 && selectedWorkflow === null}
								>
									<T lettercase="capitalize">{title}</T>
								</CNavLink>
							</CNavItem>
						))}
					</CNav>
					<div className="mt-2"><div className="mx-auto border-bottom w-75"/></div>
					<CTabContent>
						<CTabPane role="tabpanel" visible={this.state.activeTab === 0}>
							<Form onSubmit={this.selectWorkflowSubmit}>
								<FormGroup tag="fieldset">
									{ Object.entries(this.props.workflows).filter(([id, values]) => values.valid).map(([id, values]) => (
										<FormGroup check key={`start_workflow_modal_radio_list_${id}`}>
											<Label check title={values.description}>
												<Input
													name="workflow_name"
													type="radio"
													value={this.state.selectedWorkflow === id}
													checked={this.state.selectedWorkflow === id}
													onChange={() => this.setState({selectedWorkflow: id})}
												/>
												{ values.name }
											</Label>
										</FormGroup>
									))}
								</FormGroup>
								<Row>
									<Col className="text-right">
										<Button color="info" className="my-2 mr-4" disabled={selectedWorkflow===null}>
											<i className="fa fa-forward mr-2"/><T>next</T>
										</Button>
									</Col>
								</Row>
							</Form>
						</CTabPane>
						<CTabPane role="tabpanel" visible={this.state.activeTab === 1}>
							{ selectedWorkflow &&
								<Form onSubmit={this.handleFormSubmit}>
									<FormGroup tag="fieldset" className="text-center">
										<FormText className="font-weight-bold">
											{workflows[selectedWorkflow].name}
										</FormText>
										<FormText>
											{workflows[selectedWorkflow].description}
										</FormText>
									</FormGroup>
									<FormGroup row>
										<Label htmlFor="new_round_mname" sm="2">
											<T>identifier</T>
										</Label>
										<Col sm="10">
											<Input
												id="new_round_mname"
												name="mname"
												onChange={this.handleInputChange}
												value={values.mname}
												valid={validationMessages.mname ? validationMessages.mname==='' : undefined}
											/>
											<FormFeedback><T>{validationMessages.mname}</T></FormFeedback>
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label htmlFor="new_round_label" sm="2">
											<T>label</T>
										</Label>
										<Col sm="10">
											<Input id="new_round_label" name="label" onChange={this.handleInputChange} value={values.label}/>
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label htmlFor="new_round_starting_date" sm="2">
											<T>starting date</T>
										</Label>
										<Col sm="10">
											<DateInput
												id="new_round_starting_date"
												name="starts"
												onChange={this.handleInputChange}
												value={values.starts}
												showTimeSelect={true}
												dateFormat="d MMMM yyyy (HH:mm)"
												internalDateFormat="yyyy-MM-dd HH:mm:ss"
												startDate={values.starts}
												endDate={values.ends}
												maxDate={values.ends}
												autoComplete="off"
											/>
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label htmlFor="new_round_ending_date" sm="2">
											<T>ending date</T>
										</Label>
										<Col sm="10">
											<DateInput
												id="new_round_ending_date"
												disabled={values.starts===''}
												name="ends"
												onChange={this.handleInputChange}
												value={values.ends}
												showTimeSelect={true}
												dateFormat="d MMMM yyyy (HH:mm)"
												internalDateFormat="yyyy-MM-dd HH:mm:ss"
												startDate={values.starts}
												endDate={values.ends}
												minDate={values.starts}
												autoComplete="off"
											/>
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label htmlFor="new_round_open" sm="2">
											<T>open</T>
										</Label>
										<Col sm="10">
											<Input
												type="checkbox"
												id="new_round_open"
												className="ml-3"
												name="open"
												onChange={this.handleInputChange}
												value={values.open}
												checked={values.open}
											/>
										</Col>
									</FormGroup>
									<Row>
										<Col className="text-right">
											<Button color="info" className="mr-3"><T>start</T></Button>
										</Col>
									</Row>
								</Form>
							}
						</CTabPane>
					</CTabContent>
				</ModalBody>
			</Modal>
		);
	}
}

StartRound.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	workflows: PropTypes.object.isRequired,
	onNewRoundSubmit: PropTypes.func.isRequired,
	validationMessages: PropTypes.object,
};

StartRound.defaultProps = {
	validationMessages: {},
}

export default StartRound;
