import React, { Component } from 'react';
import {
	Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem,
	ListGroupItemHeading, ListGroupItemText, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { postponeInvitation, acceptInvitation, rejectInvitation } from '../../ducks/appProfile';
import { toggleInvitationsModal } from '../../ducks/appState';
import T from 'modules/i18n';

class Invitations extends Component {

	constructor(props) {
		super(props);
		this.state = {
			pending: [],
			hasRejectionBeenConfirmed: [],
		};
	}

	componentDidMount() {
		this.initialize();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.invitations !== this.props.invitations || prevProps.postponed !== this.props.postponed)
			this.initialize();
		if (!prevProps.isOpen && this.props.isOpen)
			this.initialize();
	}

	initialize = (excludePostponed=this.props.excludePostponed) => {
		if (!this.props.isOpen) return;
		const pending = excludePostponed
			? this.props.invitations.filter(e => !this.props.postponed.includes(e.identifier+e.role))
			: this.props.invitations;
		const hasRejectionBeenConfirmed = pending.map(e => e.identifier+e.role).reduce((obj, key) => ({...obj, [key]: false}), {});
		this.setState({pending, hasRejectionBeenConfirmed});
		if (pending.length === 0)
			this.toggle();
	}

	postpone = ({identifier, role}) => {
		this.props.dispatch(postponeInvitation(identifier, role));
	}

	toggle = () => {
		this.props.dispatch(toggleInvitationsModal());
	}

	acceptInvitation = (invitation) => {
		this.props.dispatch(acceptInvitation(invitation));
	}

	rejectInvitation = (invitation) => {
		const id = invitation.identifier + invitation.role;
		const confirmForInvitation = this.state.hasRejectionBeenConfirmed[id];
		if (confirmForInvitation) {
			this.props.dispatch(rejectInvitation(invitation));
		} else {
			this.toggleRejectInvitationModal(invitation);
		}
	}

	toggleRejectInvitationModal = ({identifier, role}) => {
		const id = identifier + role;
		this.setState({hasRejectionBeenConfirmed: {...this.state.hasRejectionBeenConfirmed, [id]: !this.state.hasRejectionBeenConfirmed[id]}})
	}

	render() {
		return (
			<Modal size="lg" isOpen={this.props.isOpen}>
				<ModalHeader toggle={this.toggle}><T>pending invitations</T></ModalHeader>
				<ModalBody>
					<ListGroup>
						{this.state.pending.map(invitation => (
							<ListGroupItem key={`invitation_item_${invitation.identifier}`} className="invitation-list-item">
								<ListGroupItemHeading>
									{invitation.name}
								</ListGroupItemHeading>
								<ListGroupItemText>
									<T>you have been invited to represent as</T> <b><T lettercase="lower">{invitation.role}</T></b> <T>the organization</T> <b>{invitation.name}</b> <T>with vat</T> <b>{invitation.identifier}</b> <T>located at</T> <em>{invitation.address}</em>.
								</ListGroupItemText>
								<ListGroupItemText>
									<T>contact details</T>: <a href={`mailto: ${invitation.email}`}>{invitation.email}</a>
								</ListGroupItemText>
								<div className="d-flex justify-content-evenly">
									<Button color="warning" outline onClick={() => this.postpone(invitation)} active={this.props.postponed.includes(invitation.identifier+invitation.role)}>
										<i className="fa fa-hourglass-start m-2"/><T>postpone</T>
									</Button>
									<Button color="danger" outline onClick={() => this.rejectInvitation(invitation)}><i className="fa fa-times m-2"/><T>reject</T></Button>
									<Button color="success" outline onClick={() => this.acceptInvitation(invitation)}><i className="fa fa-check m-2"/><T>accept</T></Button>
								</div>
								<Modal
									size="xs"
									isOpen={this.state.hasRejectionBeenConfirmed[invitation.identifier+invitation.role]}
									toggle={() => this.toggleRejectInvitationModal(invitation)}
								>
									<ModalBody>
										<p><T placeholders={{org: invitation.name}}>confirm invitation reject</T></p>
										<p className="text-muted"><T>confirm invitation reject note</T>.</p>
										<ModalFooter className="d-flex justify-content-evenly">
											<Button onClick={() => this.rejectInvitation(invitation)} color="danger">
												<T>continue</T>
											</Button>
											<Button onClick={() => this.toggleRejectInvitationModal(invitation)} outline color="success">
												<T>cancel</T>
											</Button>
										</ModalFooter>
									</ModalBody>
								</Modal>
							</ListGroupItem>
						))}
					</ListGroup>
				</ModalBody>
			</Modal>
		);
	}
}

Invitations.propTypes = {
	invitations: PropTypes.array.isRequired,
	excludePostponed: PropTypes.bool.isRequired,
	postponed: PropTypes.array.isRequired,
	dispatch: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
}

export default Invitations;
