import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, CustomInput as Input, Row, Col, Button } from 'reactstrap';

import T from 'modules/i18n';

class FieldSelection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            values: [...props.values]
        };
    }
    
    handleCheckboxChange = (event) => {
        const { name, checked, value } = event.target;
        if (checked) {
            this.setState({values: [...this.state.values, {name, label: value}]});
        } else {
            this.setState({values: [...this.state.values.filter(e => e.name !== name)]});
        }
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.props.setAdditionalFieldsCallback(this.state.values);
    }

    render() {
        const { values } = this.state;

        return (
            <Modal size="xl" isOpen={this.props.isOpen} scrollable className="field-selection">
                <ModalHeader toggle={this.props.toggle}><T>select fields</T></ModalHeader>
                <ModalBody>
                    <Form onSubmit={this.handleFormSubmit}>
                        { Object.entries(this.props.fields).map(([node, {node_label, fields}]) => (
                            <FormGroup key={`field_sel_fg_${node}`} tag="fieldset">
                                <legend>{node_label}</legend>
                                { fields.map(({name, label}) => (
                                    <FormGroup key={`field_sel_f_${node}_${name}`} check>
                                        <Input id={`switch_${node}_${name}`} type="switch" name={name} checked={values.map(e => e.name).includes(name)} value={label} onChange={this.handleCheckboxChange} label={label}/>
                                    </FormGroup>
                                ))}
                            </FormGroup>
                        ))}
                        <Row>
                            <Col className="d-flex justify-content-end m-5">
                                <Button color="success"><T>save</T></Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        );
    }
}

FieldSelection.propTypes = {
	isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
    setAdditionalFieldsCallback: PropTypes.func.isRequired,
    values: PropTypes.array.isRequired,
}

export default FieldSelection;
