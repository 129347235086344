import React, { Component } from 'react';
import { connect } from 'react-redux';
import BellIcon from 'react-bell-icon';

import { openInvitationsModal } from '../../ducks/appState';

class InvitationsBell extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isActive: false,
			pending: [],
			clickedByUser: false,
		};
	}

	componentDidMount() {
		this.checkToActivate();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.invitations !== this.props.invitations || prevProps.postponed !== this.props.postponed)
			this.checkToActivate();
		if (this.state.isActive && !prevProps.isInvitationsModalOpen && this.props.isInvitationsModalOpen) {
			this.setState({clickedByUser: true});
		}
	}

	checkToActivate = () => {
		const pending = this.props.invitations.filter(e => !this.props.postponed.includes(e.identifier+e.role));
		this.setState({
			pending,
			isActive: pending.length > 0,
			clickedByUser: false,
		});
	}

	handleClick = () => {
		if (!this.state.isActive) return;
		this.setState({clickedByUser: true});
		this.props.dispatch(openInvitationsModal());
	}

	render() {

		if (!this.state.isActive) return null;

		const { clickedByUser } = this.state;

		return (
			<BellIcon
				className="p-2 mx-2 mt-1"
				color={clickedByUser ? 'var(--cui-info)' : 'var(--cui-danger)'}
				active={true}
				animate={!clickedByUser}
				animationSpeed="2"
				onClick={this.handleClick}
				role="link"
			/>
		);
	}
}

const mapStateToProps = (state) => ({
	invitations: state.appProfile.invitations,
	postponed: state.appProfile.postponedInvitations,
	isInvitationsModalOpen: state.appState.isInvitationsModalOpen,
});

InvitationsBell = connect(mapStateToProps)(InvitationsBell);

export default InvitationsBell;
