import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Row, Col, Button } from 'reactstrap';

import { getData, postData, updateData } from 'core/ducks/update';
import { Table, Tbody, Td, Thead, Tr } from 'css-table';
import { getValidation, validate } from 'core/ducks/forms';
import { StartRoundModal, EditRoundModal } from '../modals';
import T from 'modules/i18n';

class Rounds extends Component {

	constructor(props) {
		super(props);
		this.state = {
			roundList: {},
			workflows: {},
			isStartRoundModalOpen: false,
			isEditModalOpen: false,
			valuesToEdit: {},
			underEdit: null
		};
	}

	componentDidMount() {
		this.props.dispatch( getValidation('round') );
		this.props.dispatch( getData('flows/workflow/type/public/hidden/false') ).then(workflows => {
			this.setState({workflows});
			this.fetchRounds(workflows);
		})
	}

	fetchRounds = (workflows) => {
		this.props.dispatch(
			getData('flows/round')
		).then(response => {
			const roundList = Object.entries(response).reduce((obj, [identifier, values]) => ({
				...obj,
				[identifier]: {...values, workflow: workflows[values.workflow].name, description: workflows[values.workflow].description}
			}), {});
			this.setState({roundList});
		});
	}

	handleNewRoundSubmit = (data) => {
		this.props.dispatch( validate(data, this.props.rules, 'round') ).then(() => {
			if (this.props.valid)
				this.props.dispatch( postData('flows/round', data) ).then(() => {
					this.fetchRounds(this.state.workflows);
					this.setState({isStartRoundModalOpen: false});
				});
		});
	}

	handleEditRoundSubmit = (data) => {
		if (Object.keys(data).length > 0) {
			this.props.dispatch( updateData(`flows/round/mname/${this.state.underEdit}`, data) ).then(() => {
				this.fetchRounds(this.state.workflows);
				this.closeEditModal();
			});
		} else {
			this.closeEditModal();
		}
	}

	closeEditModal = () => {
		this.setState({
			isEditModalOpen: false,
			underEdit: null,
			valuesToEdit: {},
		});
	}

	editRound = (underEdit, valuesToEdit) => {
		this.setState({
			isEditModalOpen: true,
			valuesToEdit,
			underEdit
		});
	}

	render() {
		const { roundList, isStartRoundModalOpen, isEditModalOpen, workflows } = this.state;
		const headers = ['workflow', 'label', 'starts', 'ends', 'active'];
		return (
			<Card>
				<CardHeader>
					<T>rounds</T>
				</CardHeader>
				<CardBody>
					<Row>
						<Col className="text-right">
							<Button color="info" className="m-3" onClick={() => this.setState({isStartRoundModalOpen: true})}><T lettercase="capitalize">init round</T></Button>
						</Col>
					</Row>
					<Table>
						<Thead>
							<Tr>
								<Td/>
								{ headers.map(h => <Td key={`rounds_table_header_${h}`}><T lettercase="capitalize">{h}</T></Td>) }
							</Tr>
						</Thead>
						<Tbody>
							{ Object.entries(roundList).map(([identifier, values]) => (
								<Tr key={`rounds_table_row_${identifier}`}>
									<Td>
										{ !values.passed && <i role="button" className="fa fa-pencil-square-o text-info mr-2" onClick={() => this.editRound(identifier, values)}/> }
										{ !values.started && <i role="button" className="fa fa-trash-o text-danger mr-2"/> }
									</Td>
									{ headers.map(key => (
										<Td key={`rounds_table_row_${identifier}_cell_${key}`} title={key === 'workflow' ? values.description : ''}>
											{key === 'active' ? (values[key] ? <i className="fa fa-check text-success"/> : '') : values[key]}
										</Td>
									))}
								</Tr>
							))}
						</Tbody>
					</Table>
				</CardBody>
				<StartRoundModal
					isOpen={isStartRoundModalOpen}
					toggle={() => this.setState({isStartRoundModalOpen: !isStartRoundModalOpen})}
					workflows={workflows}
					onNewRoundSubmit={this.handleNewRoundSubmit}
					validationMessages={this.props.validation_msgs}
				/>
				<EditRoundModal
					isOpen={isEditModalOpen}
					toggle={this.closeEditModal}
					data={this.state.valuesToEdit}
					onEditRoundSubmit={this.handleEditRoundSubmit}
				/>
			</Card>
		);
	}
}

const mapStateToProps = (state) => ({
	rules: state.forms.validation.rules,
	validation_msgs: state.forms.validation_msgs,
	valid: state.forms.valid,
});

Rounds = connect(mapStateToProps)(Rounds);

export default Rounds;
