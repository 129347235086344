import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { getRoute } from 'core/model/lib/urlTools';
import { postData } from 'core/ducks/update';
import T from 'modules/i18n';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			error: null,
		};
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({ hasError: true, error });
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, info);
		// console.error(error, info);
	}

	componentDidUpdate(prevProps, prevState) {
		if (process.env.NODE_ENV === 'production') {
			const { path, routes, role } = this.props;
			if (!prevState.hasError && this.state.hasError) {
				const report = {error: this.state.error.stack, path, component: getRoute(path, routes, role).component};
				this.props.dispatch(postData('errorReport', report))
				setTimeout(() => {window.location.href = '/';}, 8000);
			}
		}
	}

	render() {
		if (this.state.hasError) {
			return (
				<Container className="mt-5">
					<Row>
						<Col>
							<h1 className="text-center text-info"><T>application error</T></h1>
						</Col>
					</Row>
					<Row>
						<Col>
							<p className="text-center text-muted text-info"><T>application error header</T></p>
							<p className="text-center text-muted text-info"><b><T>application error text</T></b></p>
						</Col>
					</Row>
					<Row>
						<Col className="d-flex justify-content-evenly">
							<img src="/img/logo.png" alt="logo"/>
						</Col>
					</Row>
					{ process.env.NODE_ENV !== 'production' &&
						<Row>
							<Col>
								<p className="p-2 border border-warning rounded text-center text-danger">{process.env.NODE_ENV} environment detected. Please,<b onClick={() => window.location.reload()} role="link">refresh</b>manually.</p>
							</Col>
						</Row>
					}
				</Container>
			);
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
