import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardBody, Form, FormGroup, FormText, Label, Input, Button, Row, Col } from 'reactstrap';

import { getData, updateData } from 'core/ducks/update';
import { Loading } from 'core/components';

import T from 'modules/i18n';

class OAuthSettings extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: null,
			values: {},
			isReady: false
		};
	}

	componentDidMount() {
		this.props.dispatch(
			getData('oauth/settings')
		).then(form => {
			const values = Object.keys(form)
				.reduce((obj, group) => ({
					...obj,
					[group]: Object.keys(form[group]).reduce((obj2, name) => ({
						...obj2,
						[name]: form[group][name].value
					}), {})
				}), {});
			this.setState({form, values, ready: true});
		});
	}

	handleFormSubmit = (e) => {
		e.preventDefault();
		this.props.dispatch(updateData('oauth/settings', this.state.values));
	}

	handleChange = (e, scope) => {
		const { name, type, value, checked } = e.target;
		this.setState({
			values: {
				...this.state.values,
				[scope]: {
					...this.state.values[scope],
					[name]: type === 'checkbox' ? checked : value,
				}
			}
		});
	}

	render() {
		if (!this.state.ready)
			return (<Loading/>);

		const { form, values } = this.state;
		return (
			<Card>
				<CardHeader>OAuth2</CardHeader>
				<CardBody>
					<Form onSubmit={this.handleFormSubmit}>
						{ Object.keys(form).map(scope => (
							<FormGroup key={`fieldset_${scope}`} tag="fieldset">
								{ Object.keys(form[scope]).map(name => {
									const details = form[scope][name];
									return (
										<FormGroup row key={`group_${scope}_${name}`}>
											<Label htmlFor={`input_${name}`} sm={2}>
												{details.type !== 'checkbox' ? details.label : ''}
												{details.required === true ? <sup>*</sup> : null}
											</Label>
											{ details.type === 'checkbox' ?
												<Col sm={10}>
													<FormGroup check>
														<Label check>
															<Input
																id={`input_${name}`}
																type="checkbox"
																name={name}
																checked={values[scope][name]}
																onChange={(e) => this.handleChange(e, scope)}
															/>{' '}
															{details.label}
														</Label>
													</FormGroup>
												</Col>
												:
												<Col>
													<Input
														disabled={!values.details.oauth_enabled}
														id={`input_${name}`}
														type={details.type}
														name={name}
														value={values[scope][name]}
														required={details.required}
														placeholder={details.default}
														onChange={(e) => this.handleChange(e, scope)}
													>
														{ details.type === 'select' ?
															details.options.map(opt =>
																<option key={`select_option_${name}_${opt}`} value={opt}>{opt}</option>
															) : null
														}
													</Input>
													<FormText color="muted">{details.hint || ''}</FormText>
												</Col>
											}
										</FormGroup>
									);
								})}
							</FormGroup>
						))}
						<Row>
							<Col className="text-right">
								<sup>*</sup><T>required</T>
							</Col>
						</Row>
						<Row>
							<Col className="text-center">
								<Button color="success"><T>submit</T></Button>
							</Col>
						</Row>
					</Form>
				</CardBody>
			</Card>
		);
	}
}

const mapStateToProps = (state) => ({});

OAuthSettings = connect(mapStateToProps)(OAuthSettings);

export default OAuthSettings;
