import React, { Component } from 'react';
import { Form, FormGroup, Row, Col, Button } from 'reactstrap';
import 'tinymce/themes/modern';
import 'editor/langs/el';
import 'tinymce/plugins/link';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/textcolor';
import { Editor } from '@tinymce/tinymce-react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

import T from 'modules/i18n';

import '../scss/style.scss';

const detectFileType = (filename) => {
	const extension = filename.split('.').pop().toLowerCase();
	if (extension === 'pdf')
		return '-pdf';
	if (['jpg', 'jpeg', 'png', 'tiff', 'tif', 'gif', 'raw', 'eps', 'bmp'].includes(extension))
		return '-image';
	if (extension === 'zip' || extension === 'gz')
		return '-archive';
	if (extension === 'doc' || extension === 'docx' || extension === 'ods')
		return '-word';
	if (extension === 'xls' || extension === 'xlsx' || extension === 'odt')
		return '-excel';
	if (extension === 'txt')
		return '-text';
	return '';
}

const getFileSize = (files) => {
	let totalSize = files.map(file => file.size).reduce((partialSum, a) => partialSum + a, 0);
	let units = ['b', 'kb', 'Mb', 'Gb'];
	let multiplier = 0;
	units.forEach(unit => {
		if (totalSize / 1024 < 1)
			return;
		multiplier += 1;
		totalSize = totalSize / 1024;
	});
	return `${Math.round(totalSize*10)/10} ${units[multiplier]}`;
}

const FileItem = ({file, className}) => (
	<div
		className={className}
		onClick={() => {
			const fileUrl = URL.createObjectURL(file);
			window.open(fileUrl, "_blank");
		}}
		role="link"
	>
		<i className={`d-block fa fa-file${detectFileType(file.name)}-o fa-4x`}/>
		<span>{file.name}</span>
	</div>
);

class NewMessage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			message: '',
			files: [],
		};
	}

	onDrop = (files) => {
		this.setState({
			files: [
				...this.state.files,
				...files,
			],
		});
	}

	handleEditorChange = (message) => {
		this.setState({message});
	}

	render() {
		return (
			<Form className="app-messages">
				<FormGroup className="mb-0">
					<Editor
						style={{borderWidth: 0}}
						className="border-0"
						init={{
							height: 350,
							theme: 'modern',
							relative_urls: false,
							remove_script_host : false,
							plugins: ['link', 'autolink', 'lists'],
							toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
							menubar: false,
							statusbar: false,
							toolbar_location: 'bottom',
							entity_encoding: 'raw',
						}}
						value={this.state.message}
						onEditorChange={this.handleEditorChange}
						disabled={this.props.disabled}
					/>
					<Dropzone onDrop={this.onDrop} disabled={this.props.disabled}>
						{({getRootProps, getInputProps}) => (
							<section className="files-container p-2" title="Drag&Drop">
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									<p className="border-bottom" role="link">
										<T>attachments</T> <i className="fa fa-paperclip mr-2"/>
										{ this.state.files.length > 0 &&
											<span>({getFileSize(this.state.files)})</span>
										}
									</p>
									<Row className="files-list-container">
										{ this.state.files.map((file, index) => (
											<Col key={`file_image_${index}`}>
												<FileItem className="p-2 m-1" file={file}/>
											</Col>
										))}
									</Row>
								</div>
							</section>
						)}
					</Dropzone>
					<Row className="button-area m-0 p-2 border-top">
						<Col className="d-flex justify-content-end">
							{ this.props.onCancel &&
								<Button disabled={this.props.disabled} color="warning" outline onClick={() => this.props.onCancel()} className="mr-2">
									<i className="fa fa-times mr-1"/><T>cancel</T>
								</Button>
							}
							<Button disabled={this.props.disabled} color="success" outline onClick={() => this.props.onMessageSend(this.state)}>
								<i className="fa fa-paper-plane fa-lg mr-2"/><T>send</T>
							</Button>
						</Col>
					</Row>
				</FormGroup>
			</Form>
		);
	}
}

NewMessage.propTypes = {
	onMessageSend: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	disabled: PropTypes.bool,
};

NewMessage.defaultProps = {
	disabled: false,
}

export default NewMessage;
