import { Api } from '../../api';
import { checkCookie } from '../profile';
import { pushNotification } from '../notifications';

// Actions
const SETTINGS_REQUEST = 'ui/REQUEST_SETTINGS';
const SETTINGS_FETCHED = 'ui/FETCHED_SETTINGS';
const SETTINGS_FAILED = 'ui/REQUEST_SETTINGS_FAILED';
const SETTINGS_UPDATING = 'ui/UPDATING_SETTINGS';
const SETTINGS_UPDATED = 'ui/UPDATED_SETTING';
const SETTINGS_UPDATE_FAILED = 'ui/FAILED_UPDATE_SETTINGS';


// Reducers
const initialState = {
	pending: true,
	values: {},
	status: 200,
	updateStatus: '',
};

export default (state = initialState, action ) => {
	switch (action.type) {
		case SETTINGS_REQUEST:
		case SETTINGS_UPDATING:
			return {
				...state,
				pending: action.pending,
			};

		case SETTINGS_FETCHED:
			return {
				...state,
				pending: action.pending,
				values: {
					...action.values
				},
				status: action.status,
			}

		case SETTINGS_FAILED:
			return {
				...state,
				pending: action.pending,
				status: action.status,
			}

		case SETTINGS_UPDATED:
		case SETTINGS_UPDATE_FAILED:
			return {
				...state,
				pending: action.pending,
				updateStatus: action.status,
			}

		default:
			return {
				...state
			};
	}
};


// Action creators
const requestingSettings = () => ({
	type: SETTINGS_REQUEST,
	pending: true,
});

const gotSettings = (values) => ({
	type: SETTINGS_FETCHED,
	pending: false,
	values,
	status: 200,
});

const failedSettings = (status) => ({
	type: SETTINGS_FAILED,
	pending: false,
	status
});

const updatingSettings = () => ({
	type: SETTINGS_UPDATING,
	pending: true,
});

const updatedSettings = (status) => ({
	type: SETTINGS_UPDATED,
	pending: false,
	status
});

const updateFailed = (status) => ({
	type: SETTINGS_UPDATE_FAILED,
	pending: false,
	status
});


//Thunk action creators
export const requestSettings = () => (dispatch, getState) => {
	// if (getState().ui.settings.values[field])
	// 	return;
	dispatch( checkCookie() ).then(() => {
		dispatch( requestingSettings() );
		const locale = getState().i18n.locale;
		let a = new Api(`settings/locale/${locale}`);
		let status = '';
		a.Get().then(response => {
			status = response.status;
			return response.json();
		}).then(json => {
			if (status === 200) {
				dispatch( gotSettings(json) );
			} else {
				dispatch( failedSettings(status) );
			}
		});
	});
};

export const updateSettings = (scope, data) => (dispatch) => {
	const warnings = {400: 'missing parameters', 415: 'wrong mimemtype', 503: 'could not write to filesystem'};
	let promise = new Promise((resolve, reject) => {
		dispatch( checkCookie() ).then(() => {
			dispatch(updatingSettings());
			let a = new Api(`settings/scope/${scope}`);
			let status = '';
			a.Post(data, 'upload').then((response) => {
				status = response.status;
				return response.json();
			}).then((json) => {
				if (status === 200) {
					dispatch(updatedSettings(status));
					dispatch(pushNotification({body: 'settings updated', type: 'success'}));
					resolve(json);
				} else {
					dispatch(updateFailed(status, json.Error));
					if (warnings[status]) {
						dispatch(pushNotification({body: warnings[status], type: 'warning'}));
					} else {
						dispatch(pushNotification({body: 'missing parameters', type: 'warning'}));
					}
					reject(json.Error);
				}
			});
		});
	});

	return promise;
}
