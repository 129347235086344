import * as roles from 'core/model/roles';

/**
 * Static routes
 */

// Admin
const Settings = '/admin/settings';
const Edit = '/admin/edit';
const SettingsMessages = Settings + '/messages';
const PdfTemplates = Edit + '/pdf_templates';
const RegisterFields = Settings + '/register_fields';
const EditForms = Edit + '/forms';
const EditModals = Edit + '/modals';
const EditWorkflows = Edit + '/draw';
const Rounds = '/admin/rounds';

export const StaticRoutes = {
	Settings,
	SettingsMessages,
	PdfTemplates,
	RegisterFields,
	Edit,
	EditForms,
	EditWorkflows,
	EditModals,
	Rounds,
};

/**
 * Dynamic routes
 */
const EditFormsDynamic = EditForms + '/form/:form';
const EditWorkflowsDynamic = EditWorkflows + '/workflow/:workflow';
const PdfTemplateDynamic = PdfTemplates + '/workflow/:workflow/:template';

export const DynamicRoutes = {
	EditWorkflows: EditWorkflowsDynamic,
	EditForms: EditFormsDynamic,
	PdfTemplates: PdfTemplateDynamic,
};

export const routes = [
	{
		url: Settings,
		name: 'settings',
		to: SettingsMessages,
		exact: true,
	},
	{
		url: SettingsMessages,
		name: 'messages',
		component: 'SettingsMessages',
		role: roles.ADMIN,
	},
	{
		url: PdfTemplates,
		name: 'pdf templates',
		description: 'Pdf Templates settings page',
		title: 'links.settings.pdf_templates',
		defaultTitle: 'Pdf Templates',
		contextComponent: 'PdfTemplatesSidebar',
		component: 'PdfTemplates',
		exact: true,
		role: roles.ADMIN,
	},
	{
		url: PdfTemplateDynamic,
		name: 'pdf templates',
		description: 'Admin Pdf Template settings',
		contextComponent: 'PdfTemplatesSidebar',
		component: 'PdfTemplates',
		role: roles.ADMIN,
	},
	{
		url: RegisterFields,
		name: 'register fields',
		component: 'RegisterFields',
		role: roles.ADMIN,
	},
	{
		url: Edit,
		name: 'edit',
		description: 'Admin Edit Menu',
		title: 'links.edit',
		defaultTitle: 'Admin Edit Menu',
		to: EditForms,
		exact: true,
	},
	{
		url: EditForms,
		name: 'forms',
		description: 'Admin Edit Forms',
		title: 'links.edit.forms',
		defaultTitle: 'Admin Edit Forms',
		contextComponent: 'EditForms',
		component: 'EditForms',
		role: roles.ADMIN,
		exact: true,
	},
	{
		url: EditModals,
		name: 'modals',
		contextComponent: 'EditModals',
		component: 'EditModals',
		role: roles.ADMIN,
		exact: true,
	},
	{
		url: EditFormsDynamic,
		name: 'forms',
		description: 'Admin Edit Forms',
		contextComponent: 'EditForms',
		component: 'EditForms',
		role: roles.ADMIN,
	},
	{
		url: EditWorkflows,
		name: 'workflows',
		description: 'Admin Draw Workflows',
		title: 'links.edit.draw',
		defaultTitle: 'Admin Draw Workflows',
		contextComponent: 'EditWorkflows',
		component: 'EditWorkflows',
		role: roles.ADMIN,
		exact: true,
	},
	{
		url: EditWorkflowsDynamic,
		name: 'workflows',
		description: 'Admin Draw Workflows',
		contextComponent: 'EditWorkflows',
		component: 'EditWorkflows',
		role: roles.ADMIN,
		exact: true,
	},
	{
		url: Rounds,
		name: 'rounds',
		description: 'Admin Edit workflow rounds',
		component: 'Rounds',
		role: roles.EDITOR,
		exact: true,
	},
];

const combined = {StaticRoutes, DynamicRoutes, routes};

export default combined;
