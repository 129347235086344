import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AsyncPaginate } from 'react-select-async-paginate';

class AsyncPaginateWrapper extends Component {

	constructor(props) {
		super(props);
		this.state = {
			hiddenInputValue: props.value.value,
		};
	}

	onChange = (opt) => {
		const hiddenInputValue = opt === null ? '' : Array.isArray(opt) ? {value: opt} : opt.value;
		this.setState({hiddenInputValue});
		this.props.onChange(opt);
	};

	render() {
		const { inputProps, disabled, readOnly, isMulti, value, ...otherProps } = this.props;
		return (
			<React.Fragment>
				<AsyncPaginate {...otherProps} isMulti={isMulti} value={typeof value === 'object' || value === '' || value === null ? value : JSON.parse(value)} onChange={this.onChange} isSearchable={!disabled && !readOnly} isDisabled={disabled || readOnly}/>
				<input
					tabIndex={-1}
					autoComplete="off"
					style={{
						opacity: 0,
						width: "100%",
						height: 0,
						position: "absolute"
					}}
					value={this.state.hiddenInputValue}
					onChange={() => {}}
					{...inputProps}
				/>
			</React.Fragment>
		)
	}
}

AsyncPaginateWrapper.propTypes = {
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([''])]),
	inputProps: PropTypes.object,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

AsyncPaginateWrapper.defaultProps = {
	value: {value: ''},
	inputProps: {},
	disabled: false,
	readOnly: false,
}

export default AsyncPaginateWrapper;
