import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Col, Form, FormGroup, Label, Card, CardHeader, CardBody, Button, Input,
} from 'reactstrap';

import { getData, postData, updateData, deleteData } from 'core/ducks/update';
import { initContext, setContext } from 'core/ducks/context';
import { Alert } from 'core/views/modals';
import { Editor } from 'editor';
import T from 'modules/i18n';

class ContentEdit extends Component {

	constructor(props) {
		super(props);
		this.state = {
			content: '',
			label: '',
			locale: props.locales[0],
			selected: null,
			method: null,
			isDeleteAlertOpen: false,
			forceUpdate: false,
		};
	}

	componentDidMount() {
		this.props.dispatch(initContext({...this.state, set: this.setContext}));
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.locale !== this.state.locale)
			this.fetchData();
		if (prevState.selected !== this.state.selected)
			this.fetchData();
	}

	setContext = (newContext) => {
		this.setState({...newContext});
		this.props.dispatch(setContext({...newContext}));
	}

	fetchData = () => {
		if (this.state.selected) {
			this.props.dispatch(
				getData(`appcontent/content/item/${this.state.selected}/locale/${this.state.locale}`)
			).then(response => {
				if (!response.content && !response.locale) {
					this.setState({content: '', label: '', method: 'post'});
				} else {
					const { content, label } = response
					this.setState({content, label, method: 'put'})
				}
			});
		} else {
			this.setState({content: '', label: '', method: null});
		}
	}

	handleContentSubmit = (e) => {
		e.preventDefault();
		const { selected, content, label, method, locale } = this.state;
		if (method === 'post') {
			this.props.dispatch (
				postData('appcontent/content', {item: selected, content, label, locale})
			);
		} else {
			this.props.dispatch(
				updateData(`appcontent/content/item/${this.state.selected}/locale/${this.state.locale}`, {content, label})
			);
		}
	}

	handleDelete = (item) => {
		this.props.dispatch(
			deleteData(`appcontent/items/uuid/${item}`)
		).then(() => {
			this.fetchData();
			this.setContext({forceUpdate: true});
		});
	}

	handlePublish = () => {
		this.props.dispatch(
			postData('appcontent/publish')
		);
	}

	render() {
		const { content, label, locale } = this.state;
		const { messages } = this.props.i18n || {messages: {}};

		return (
			<>
				<div className="w-100 text-right">
					<Button color="primary" className="m-2" title="synchronize with live version" onClick={this.handlePublish}>
						<i className="fa fa-gear mr-2"/><T>publish</T>
					</Button>
				</div>
				<Card>
					<CardHeader><T>content</T></CardHeader>
					<CardBody>
						<Form onSubmit={this.handleContentSubmit}>
							<FormGroup tag="fieldset" className="border p-4" disabled={!this.state.selected}>
								<FormGroup row>
									<Label htmlFor="locale_selector" sm="2"><T>select locale</T></Label>
									<Col sm="10">
										<Input type="select" value={locale} onChange={(e) => this.setState({locale: e.target.value})}>
											{this.props.locales.map(loc => (
												<option value={loc} key={`locale_option_${loc}`}>{messages[loc] || loc}</option>
											))}
										</Input>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label htmlFor="label_input" sm="2"><T>label</T></Label>
									<Col sm="10">
										<Input required type="text" minLength="3" maxLength="55" value={label} onChange={e => this.setState({label: e.target.value})}/>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label htmlFor="front_text_input" sm="2"><T>content</T></Label>
									<Col sm="10">
										<Editor
											id="front_text_input"
											value={content}
											locale={this.props.i18n.locale}
											onChange={(content) => this.setState({content})}
											disabled={!this.state.selected}
										/>
									</Col>
								</FormGroup>
								<div className="text-right">
									<Button color="danger" className="mr-2" onClick={() => this.setState({isDeleteAlertOpen: true})}><T>delete</T></Button>
									<Button color="success" className="mr-2"><T>submit</T></Button>
								</div>
							</FormGroup>
						</Form>
					</CardBody>
					{ this.state.isDeleteAlertOpen &&
						<Alert
							title="drop confirm"
							message="drop confirm"
							toggle={() => this.setState({isDeleteAlertOpen: false})}
							onConfirm={() => this.handleDelete(this.state.selected)}
						/>
					}
				</Card>
			</>
		);
	}

}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
	locales: state.ui.settings.values.locales,
});

ContentEdit = connect(mapStateToProps)(ContentEdit);

export default ContentEdit;
