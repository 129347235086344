import React, { Component } from 'react';
import { Collapse, Card, CardHeader, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

import '../scss/collapsingCard.scss';

class CollapsingCard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isCardBodyOpen: props.initiallyOpen,
		};
		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		this.setState({isCardBodyOpen: !this.state.isCardBodyOpen});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.initiallyOpen !== this.props.initiallyOpen)
			this.setState({isCardBodyOpen: this.props.initiallyOpen});
	}

	render() {
		const { isCardBodyOpen } = this.state;
		const className = `collapsing-card ${this.props.className || ''}`
		const disabled = this.props.disabled ? 'disabled' : '';
		return (
			<Card className={className}>
				<CardHeader tag="h4" className="d-flex justify-content-between">
					<span className="header-title">{this.props.title}</span>
					<i role="link" className={`header-icon fa fa-angle-up ${isCardBodyOpen ? 'open' : 'close'} ${disabled}`} onClick={() => this.toggle()}/>
				</CardHeader>
				<Collapse isOpen={isCardBodyOpen}>
					<CardBody>
						{this.props.children}
					</CardBody>
				</Collapse>
			</Card>
		);
	}

}

CollapsingCard.propTypes = {
	title: PropTypes.string,
	initiallyOpen: PropTypes.bool,
	disabled: PropTypes.bool,
};

CollapsingCard.defaultProps = {
	title: '',
	initiallyOpen: true,
	disabled: false,
};

export default CollapsingCard;
