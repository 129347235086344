import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Card, CardHeader, CardTitle, CardBody, Row, Col } from 'reactstrap';

const getListStyle = isDraggingOver => ({
	background: isDraggingOver ? 'rgba(173,216,230,0.2)' : 'inherit',
	minHeight: 100 + 'px'
});

class List extends Component {

	constructor(props) {
		super(props);
		this.state = {
			items: props.items,
		};
	}

	render() {
		const classes = this.props.disabled ? {className: 'disabled'} : {};
		return (
			<Card {...classes} style={{marginBottom: '10px'}}>
				<CardHeader>
					<CardTitle className="text-uppercase font-weight-bold" lang="el">
						{this.props.title}
					</CardTitle>
				</CardHeader>
				<CardBody className="p-2">
					<Row>
						<Col xs={(this.props.members && this.props.members.length > 0) ? 9 : 12} >
							<Droppable droppableId={this.props.droppableId} isDropDisabled={this.props.readOnly}>
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										style={getListStyle(snapshot.isDraggingOver)}
										className="px-5 py-3"
									>
										{React.Children.map(this.props.children, (item, index) => (
											<Draggable
												key={item.props.id}
												draggableId={item.props.id}
												index={index}
											>
												{(provided, snapshot) => {
													return (
														<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
															{item}
														</div>
													);
												}}
											</Draggable>
										))}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</Col>
						{ (this.props.members && this.props.members.length > 0) &&
							<Col xs="3" className="text-right border border-top-0 border-right-0 border-bottom-0">
								<ul className="list-group">
									{this.props.members.map(member =>
										<li key={`member_${member.username}`} className="list-group-item d-flex justify-content-between align-items-center small px-2 py-1">
											<mark>@{member.username}</mark> {member.firstname[0] + '. ' + member.lastname}
										</li>
									)}
								</ul>
							</Col>
						}
					</Row>
				</CardBody>
			</Card>
		);
	}
}

List.propTypes = {
	droppableId: PropTypes.string.isRequired,
	items: PropTypes.array,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
};

export default List;
