import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routes } from 'modules';

import { updateMenuItems } from 'core/ducks/ui/menu';
import { getRoute } from 'core/model/lib/urlTools';
import { getDefaultProfile } from '../../ducks/appProfile';
import Forbidden from '../../components/forbidden';
import InvitationsModal from '../modals/invitations';

const checkPermissions = (userRoles, routeRole) => {
	if (!routeRole)
		return true;
	if (Array.isArray(routeRole))
		return routeRole.some(elem => userRoles.includes(elem));
	return userRoles.includes(routeRole);
}

function withApp(WrappedComponent) {
	class App extends Component {

		constructor(props) {
			super(props);
			this.state = {
				hasPermission: null,
			};
		}

		componentDidMount() {
			this.getDefaultProfile();
		}

		componentDidUpdate(prevProps) {
			if (!prevProps.shouldUpdate && this.props.shouldUpdate)
				this.getDefaultProfile(true);

			if (prevProps.profileRequestStatusCode !== this.props.profileRequestStatusCode && this.props.profileRequestStatusCode) {
				const { menuItems } = this.props;
				const newMenuItems = menuItems.map(item => ({ ...item, disabled: !checkPermissions(this.props.appRoles, item.appRole) }));
				this.props.dispatch(updateMenuItems(newMenuItems));
			}
		}

		getDefaultProfile = (force=false, callback=() => {}) => {
			let { appRole } = getRoute(this.props.match.path, routes, this.props.role);
			this.props.dispatch(getDefaultProfile(force)).then(() => {
				const hasPermission = checkPermissions(this.props.appRoles, appRole);
				this.setState({hasPermission});
				callback();
			});
		}

		render() {
			const { hasPermission } = this.state;

			if (hasPermission === null)
				return null;

			if (!hasPermission)
				return <Forbidden/>

			const { isInvitationsModalOpen, invitations, postponed, ...otherProps } = this.props;

			return (
				<>
					<InvitationsModal
						isOpen={isInvitationsModalOpen}
						invitations={invitations}
						postponed={postponed}
						dispatch={this.props.dispatch}
						excludePostponed={this.props.excludePostponed}
					/>
					<WrappedComponent {...otherProps} getDefaultProfile={this.getDefaultProfile}/>
				</>
			);
		}
	}

	const mapStateToProps = (state) => ({
		role: state.profile.user.role,
		shouldUpdate: state.appProfile.shouldUpdate,
		appRoles: state.appProfile.roles,
		profileRequestStatusCode: state.appProfile.request.status,
		menuItems: state.ui.menu.items,
		i18n: state.i18n.messages,
		invitations: state.appProfile.invitations,
		postponed: state.appProfile.postponedInvitations,
		messages: state.messages,
		isInvitationsModalOpen: state.appState.isInvitationsModalOpen,
		excludePostponed: state.appState.excludePostponed,
		upload: state.upload,
	});

	return connect(mapStateToProps)(App);
}

export default withApp;
