import pathToRegexp from 'path-to-regexp';
import { hasRights } from './permissions';
// import { routes as coreRoutes } from '../routes';
// import * as moduleRoutes from 'modules/routes';
// import moduleRoutes from 'modules/routes';

// const routes = {...coreRoutes, ...moduleRoutes};

export const getBaseUrl = () => {
	const href = window.location.href;
	let parts = href.split('/');
	return parts[0] + '//' + parts[2];
};

/**
 * Find a route by its path e.g. /Dashboard
 *
 * @export
 * @param {string} path - the route path
 * @returns the route properties
 */
export function getRoute(path, routes, userRole) {
	const url = matchRoute(path, routes);

	let route = routes.find(elem => (elem.url === url && hasRights(userRole, elem.role)));

	return route;
}

/**
 * Matches the given path to an existing route and returns the route or null
 * if no match is found
 *
 * @export
 * @param {any} path - the route path to match
 * @returns the route that matched the given path or null if no match is found
 */
export function matchRoute(path, routes) {
	for (let route of routes) {
		let re = pathToRegexp(route.url);
		if (re.test(path)) {
			return route.url;
		}
	}

	return null;
}

export function getParameters(path, routes) {
	let route = matchRoute(path, routes);
	let keys = [];
	let re = pathToRegexp(route, keys);
	let matches = re.exec(path);
	let params = {};
	keys.forEach((entry, index) => {
		params[entry.name] = matches[index+1];
	});
	return params;
}

/**
 * Build a path given a route and optional parameters
 *
 * @export
 * @param {string} path - The route name
 * @param {string[]|object} params - Optional parameters to bind
 */
export function buildPath(path, params) {
	let result = path || '/';
	let re = /(:[^/()]+)\([^/)]+\)[?]{0,1}/g;
	result = result.replace(re, '$1');

	if (params) {
		if (Array.isArray(params)) {
			re = /:\w+[?]{0,1}/i;
			for (const value of params) {
				result = result.replace(re, encodeURI(value));
			}
		} else if (params instanceof Object) {
			Object.keys(params).forEach(key => {
				const re1 = new RegExp(`\\(${key}\\)\\?`, 'i');
				const re2 = new RegExp(`:${key}[\\?]{0,1}`, 'i');
				if (params[key]) {
					result = result.replace(re1, key).replace(re2, encodeURI(params[key]));
				} else {
					result = result.replace(re1, '').replace(re2, '');
				}
			});
			re = /[\/]{2,}/g;
			result = result.replaceAll(re, '/');
		} else {
			let toPath = pathToRegexp.compile(path);
			result = toPath(params);
		}
	}

	re = /\/[^/]+\/\?:[^/]+\?[\/]{0,1}/g;
	result = result.replaceAll(re, '');

	re = /[^/]+\?[\/]{0,1}/g;
	result = result.replaceAll(re, '');

	re = /\(([^/)]+)\)[?]{0,1}/g;
	result = result.replace(re, '$1');

	return result;
}

/**
 * Get the named parameter values of the path
 * 
 * @export
 * @param {string} path	- The actual path 
 * @param {string} route - Route definition
 * @returns {object} - Parameters
 */
export function getNamedParams(path, route) {
	let re = /\(([a-zA-Z0-9]*)\)\?/g;
	const groups = Array.from(route.matchAll(re), (m) => m[1]);
	const params = {};
	groups.forEach(group => {
		re = new RegExp(`/${group}/(?<${group}>[^/]+)`, 'i');
		const match = path.match(re);
		const value = match ? match.groups[group] : null;
		params[group] = value;
	});
	return params;
}
