import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, InputGroup } from 'reactstrap';
import inside from 'point-in-polygon';

import { Input } from 'core/components';

import Map from './map.js';

class Address extends Component {

	constructor(props) {
		super(props);
		this.initialValues = {
			street: '',
			house_number: '',
			town: '',
			zip: '',
		};
		this.state = {
			values: {
				...this.initialValues,
			},
			coordinates: '',
			geoCoding: {},
			geoCodeID: -1
		};
		this.fields = ['street', 'house_number', 'town', 'zip'];
		this.handleChange = this.handleChange.bind(this);
		this.handlePositionChange = this.handlePositionChange.bind(this);
	}

	componentDidMount() {
		let { address, location } = this.props.value;
		const values = address.split('+');
		if (typeof location == 'string') {
			location = location.split(';');
			location.lat = parseFloat(location[0]);
			location.lng = parseFloat(location[1]);
		}

		this.setState({
			values: {
				...this.state.values,
				street: values[0] || '',
				house_number: values[1] || '',
				town: values[2] || '',
				zip: values[3] || '',
			},
			geoCoding: location
		});
	}

	handleChange(event) {
		const { target } = event;
		const values = {
			...this.state.values,
			[target.name]: target.value
		};
		this.setState({values});

		const value = this.fields.map(field => values[field].trim()).join('+');
		const completed = Object.values(values).filter(elem => elem.trim().length > 0).length === 4;
		if (completed) {
			if (this.state.geoCodeID !== -1) {
				clearTimeout(this.state.geoCodeID);
			}
			const geoCodeID = setTimeout(() => {
				const address = encodeURI(value);
				fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBIRAoofCxFJ7ALT8nXqZt-VZWoiUq1Sxk`, {
					method: 'GET'
				}).then(response => {
					return response.json()
				}).then(json => {
					const { lat, lng } = json.results[0].geometry.location;
					this.setState({geoCoding: {lat, lng}});
				});
				this.setState({geoCodeID: -1});
			}, 700);
			this.setState({geoCodeID});
		}
		if (typeof this.props.onChange == 'function')
			this.props.onChange({target: {name: this.props.name, value: {address: value, location: this.state.coordinates}}});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.coordinates !== this.state.coordinates) {
			if (typeof this.props.onChange == 'function') {
				const address = this.fields.map(field => this.state.values[field].trim()).join('+');
				this.props.onChange({target: {name: this.props.name, value: {address, location: this.state.coordinates}}});
			}
		}
	}

	handlePositionChange(event) {
		let filledIn = Object.values(this.state.values).filter(elem => elem.length > 0).length > 0;
		const coordinates = event.target.value;

		// Validate coordinates
		let point = coordinates.split(';');
		point = point.reverse();
		const polygon = this.props.boundary;
		const isInside = polygon ? inside(point, polygon) : true;
		if (!isInside)
			return;

		let values = {};
		if (filledIn) {
			this.setState({coordinates});
		} else {
			const latlng = Object.values(event.target.latlng).join(',');
			fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=AIzaSyBIRAoofCxFJ7ALT8nXqZt-VZWoiUq1Sxk&language=el`, {
				method: 'GET'
			}).then(response => {
				return response.json()
			}).then(json => {
				const { results } = json;
				const types = {street: 'route', town: 'locality', house_number: 'street_number', zip: 'postal_code'};
				results[0].address_components.forEach(result => {
					Object.keys(types).forEach(key => {
						let type = types[key];
						if (result.types.indexOf(type) !== -1)
							values[key] = result.long_name;
					});
				});
				this.setState({
					coordinates,
					values: {
						...this.state.values,
						...values
					},
				});
			});
		}
	}

	render() {
		let { onChange, name, value, id, locale, showCoordinates, messages, ...inputProps } = this.props;
		locale = locale || 'en';
		const { values, coordinates } = this.state;

		return (
			<FormGroup>
				<FormGroup tag="fieldset">
					<Row form >
						<Col md="10" lg="6">
							<Input
								id="address_street"
								{...inputProps}
								placeholder={messages.street || 'Street'}
								name="street"
								value={values.street}
								onChange={this.handleChange}
								pattern="[a-zA-Zα-ωΑ-Ωέύϋΰίϊΐόάήώ.\ ]+"
							/>
						</Col>
						<Col md="2" lg="1">
							<Input
								id="address_house_number"
								{...inputProps}
								placeholder={messages.number || 'Number'}
								name="house_number"
								value={values.house_number}
								onChange={this.handleChange}
								pattern="[0-9]+[ΑΒΓΔαβγδA-Fa-f]{0,1}"
							/>
						</Col>
						<Col md="9" lg="3">
							<Input
								id="address_town"
								{...inputProps}
								placeholder={messages.town || 'Town'}
								name="town"
								value={values.town}
								onChange={this.handleChange}
								pattern="[a-zA-Zα-ωΑ-Ωέύϋΰίϊΐόάήώ.\ ]+"
							/>
						</Col>
						<Col md="3" lg="2">
							<InputGroup>
								<Input
									id="address_zip"
									{...inputProps}
									placeholder={messages.zip || 'Zip'}
									name="zip"
									value={values.zip}
									onChange={this.handleChange}
									pattern="[0-9]{3}[\ ]{0,1}[0-9]{2}"
								/>
								<i
									className="fa fa-times pt-2 px-2 cursor-pointer"
									onClick={() => {
										this.setState({
											values: {...this.initialValues}
										})
									}}
								/>
							</InputGroup>
						</Col>
					</Row>
				</FormGroup>
				<Map
					value={coordinates}
					name="map"
					options={{zoom:this.props.zoom || 17, position: this.props.center || [38.021331, 23.798630]}}
					required={true}
					readOnly={false}
					onChange={this.handlePositionChange}
					geoCoding={this.state.geoCoding}
					showCoordinates={this.props.showCoordinates}
				/>
			</FormGroup>
		);
	}
}

Address.propTypes = {
	required: PropTypes.bool,
	name: PropTypes.string.isRequired,
	value: PropTypes.object.isRequired,
	onChange: PropTypes.func,
	readOnly: PropTypes.bool,
	locale: PropTypes.string,
	showCoordinates: PropTypes.bool,
	boundary: PropTypes.array,
	center: PropTypes.array,
	zoom: PropTypes.number,
};

export default Address;
