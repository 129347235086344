import React, { Component } from 'react';
import { Input, FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';

const checkVAT = (vat) => {
	if (!vat) return undefined;
	if (isNaN(vat) || vat.length !== 9)
		return false
	const digits = vat.split('');
	const d = digits.map((e, i) => 2**(8 - i)*parseInt(e));
	const sum = d.reduce((a, b) => a + b, 0) - d[8];
	const modulo = sum%11 === 10 ? 0 : sum%11;
	return modulo === d[8]
}

const getVATType = (vat) => {
	let type = null;
	const first = parseInt(vat.charAt(0));
	const second = parseInt(vat.charAt(1));
	if ([1, 2, 3, 4].includes(first)) {
		type = 'natural';
	} else if ([7, 8, 9].includes(first)) {
		type = 'legal';
	} else if (first === 0) {
		type = second === 9 ? 'legal' : 'natural';
	}
	return type;
}

class VatNumber extends Component {

	constructor(props) {
		super(props);
		this.state = {
			valid: undefined,
			message: ''
		};
	}

	handleChange = (e) => {
		const { value } = e.target;
		const valid = checkVAT(value);
		if (!valid) {
			this.setState({valid, message: "Η τιμή δεν αντιστοιχεί σε έγκυρο ΑΦΜ"});
		} else if (this.props.personal) {
			const vat_type = getVATType(value);
			const personal = vat_type === 'natural';
			this.setState({valid: personal, message: personal ? "" : "Η τιμή δεν αντιστοιχεί σε ΑΦΜ φυσικού προσώπου"});
		} else {
			this.setState({valid, message: ""});
		}

		this.props.onChange(e)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.valid !== this.state.valid) {
			this.props.onValidationChange(this.state.valid);
		}
	}

	render() {
		const { personal, value, onValidationChange, ...otherProps } = this.props;
		const { valid } = this.state;

		return (
			<>
				<Input
					{...otherProps}
					onChange={this.handleChange}
					value={value}
					minLength="9"
					maxLength="9"
					valid={valid}
					invalid={valid === undefined ? undefined : !valid}
				/>
				{ !valid &&
					<FormFeedback>{this.state.message}</FormFeedback>
				}
			</>
		);
	}
}

VatNumber.propTypes = {
	personal: PropTypes.bool,
	onValidationChange: PropTypes.func,
};

VatNumber.defaultProps = {
	personal: true,
	onValidationChange: () => {}
}

export default VatNumber;
