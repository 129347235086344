import React, { Component } from 'react';
import { Button, Row, Col } from 'reactstrap';

import withOrganizations from './withOrganizations';

class Organizations extends Component {

	constructor(props) {
		super(props);
		this.state = {
			query: '',
			membership: {...props.appProfile.membership},
			isSearchVisible: false,
		};
		this.searchRef = React.createRef();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.query !== this.state.query) {
			const { query } = this.state;
			if (query === '') {
				this.setState({membership: {...this.props.appProfile.membership}});
			} else {
				let { membership } = this.state.query.startsWith(prevState.query) ? this.state : this.props.appProfile;
				const regex_query = new RegExp(query, "i");
				membership = Object.entries(membership)
					.map(([identifier, entry]) => ({identifier, ...entry}))
					.filter(item => item.name.search(regex_query) !== -1 || item.identifier.search(regex_query) !== -1)
					.reduce((obj, item) => {
						const { identifier, ...other } = item;
						return {
							...obj,
							[identifier]: other
						};
					}, {});
				this.setState({membership});
			}
		}
	}

	handleQueryChange = ({target}) => {
		this.setState({query: target.value});
	}

	toggleSearch = (visibility) => {
		if (!visibility && this.state.query)
			return;
		this.setState({isSearchVisible: visibility}, () => {
			if (this.state.isSearchVisible)
				this.searchRef.current.focus();
		});
	}

	render() {
		const { membership, query, isSearchVisible } = this.state;
		const { active } = this.props.appProfile;

		return (
			<>
				{ Object.keys(this.props.appProfile.membership).length > 3 &&
					<Row>
						<Col className="d-none d-lg-flex"/>
						<Col className="text-right">
							{ isSearchVisible ?
								<input
									ref={this.searchRef}
									className="animated fadeIn form-control"
									autoComplete="off"
									type="search"
									name="query"
									onChange={this.handleQueryChange}
									value={query}
									onMouseLeave={() => this.toggleSearch(false)}
									onMouseEnter={() => this.searchRef.current.focus()}
								/>
								:
								<i className="fa fa-search mr-1" onMouseEnter={() => this.toggleSearch(true)}/>
							}

						</Col>
					</Row>
				}
				{ Object.entries(membership).map(([identifier, item]) => (
					<Button
						key={`organization_list_${identifier}`}
						className="select-btn d-block w-100 my-2"
						color="primary"
						outline
						active={active===identifier}
						onClick={() => this.props.handleSelect(identifier)}
					>
						<div className="text-left mx-5">
							<i className={item.type === 'person' ? 'mr-2 fa fa-user' : 'mr-2 fa fa-industry'}/>{item.name}
						</div>
						<div className="text-right fw-semibold"><i className="fa fa-id-card-o mr-2"/>{identifier}</div>
						{ item.address &&
							<div className="text-left"><i className="fa fa-map-marker"/>{' '}{item.address}</div>
						}
					</Button>
				))}
			</>
		);
	}
}

export default withOrganizations(Organizations);
