import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Button, Form,
} from 'reactstrap';

import { getData, updateData } from 'core/ducks/update';

class AssignModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			reviewers: {},
			pending: true,
			reviewer: props.data.reviewer_uuid
		};
	}

	componentDidMount() {
		this.props.dispatch(getData('users/limit/100/sort/lastname/sort_method/asc/fq/role:reviewer;active:true'))
			.then(reviewers => this.setState({reviewers, pending: false}))
			.catch(err => {
				this.setState({pending: false});
				console.warn(err);
				this.props.toggle();
			});
	}

	handleChange = (event) => {
		const { checked, value } = event.target;
		if (checked)
			this.setState({
				reviewer: value,
			})
	}

	handleSubmit = (event) => {
		event.preventDefault();
		const { dispatch, toggle, data } = this.props;
		const { reviewer } = this.state;
		dispatch(
			updateData('flows/application/bulk/assign', { reviewer, applications: data })
		).then(toggle);
	}

	render() {

		const { isOpen, toggle, data } = this.props;
		const { reviewers, pending } = this.state;

		if (pending)
			return null;

		return (
			<Modal isOpen={isOpen}>
				<ModalHeader toggle={toggle}>{data.status === 'progressing' ? 'Ανάθεση' : 'Ανάθεση'}</ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleSubmit}>
						{Object.entries(reviewers.values).map(([uuid, reviewer]) => (
							<FormGroup key={`reviewer_${uuid}`} check >
								<Label check >
									<Input type="radio" checked={this.state.reviewer === uuid} value={uuid} onChange={this.handleChange} />{' '}
									{`${reviewer.lastname} ${reviewer.firstname}`}
								</Label>
							</FormGroup>
						))}
						<div className="text-right">
							<Button type="submit" color="warning" disabled={!this.state.reviewer}>Ανάθεση</Button>
						</div>
					</Form>
				</ModalBody>
			</Modal>
		);
	}
}

AssignModal = connect(null)(AssignModal);

export default AssignModal;
