import React, { Component } from 'react';
import { Row, Col, Button, Card, CardBody, CardTitle } from 'reactstrap';
import PropTypes from 'prop-types';

import T from 'modules/i18n';

class Boolean extends Component {

	handleSubmit = (value) => {
		const { node, onSubmit } = this.props;
		const formdata = new FormData();
		formdata.append(node.mname, value);
		onSubmit(formdata);
	}

	render() {
		const { node, className, disabled, values } = this.props;
		const value = Object.keys(values).includes(node.mname) ? (values[node.mname] === true || values[node.mname] === 'true' ? 1 : -1) : 0;

		return (
			<Card className={className}>
				<CardBody>
					<CardTitle className="fw-semibold" dangerouslySetInnerHTML={{__html: node.content_text}}/>
					<Row>
						<Col className="d-flex justify-content-evenly">
							<Button
								className="mr-2"
								style={{opacity: value === 1 ? 1 : 0.2}}
								disabled={disabled}
								color="primary"
								size="lg"
								onClick={() => this.handleSubmit(true)}
							>
								<T>yes</T>
							</Button>
							<Button
								className="mr-2"
								style={{opacity: value === 1 ? 0.2 : 1}}
								disabled={disabled}
								size="lg"
								onClick={() => this.handleSubmit(false)}
							>
								<T>no</T>
							</Button>
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	}
}

Boolean.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	node: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	values: PropTypes.object,
	className: PropTypes.string,
};

Boolean.defaultProps = {
	disabled: false,
	values: {},
	className: '',
}

export default Boolean;
