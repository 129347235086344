import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import truncate from 'truncate-html';

import T from 'modules/i18n';

class Recent extends Component {

	render() {

		const { messages, onMessageClick } = this.props;

		return messages.map(message => (
			<div className="border-bottom py-3 px-1 mb-2" key={`list_${message.uuid}`}>
				<small className="text-muted">{message.created_at}</small>
				<article dangerouslySetInnerHTML={{__html: truncate(message.body, 40, {byWords: true})}}/>
				<Row>
					<Col className="d-flex justify-content-between">
						{ message.attachments > 0 &&
							<div className="d-inline-block ml-2">
								<i className="fa fa-paperclip fa-lg"/>{` ${message.attachments}`}
							</div>
						}
						<Button outline color="info" className="border-0 d-inline-block mr-2" onClick={() => onMessageClick(message.uuid)}>...<T>more</T></Button>
					</Col>
				</Row>
			</div>
		));

	}
}

Recent.propTypes = {
	messages: PropTypes.array.isRequired,
	onMessageClick: PropTypes.func.isRequired,
}

export default Recent;
