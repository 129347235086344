import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Button, Label
} from 'reactstrap';

import { Loading, Input } from 'core/components';
import { default as T, getLocale } from 'modules/i18n';
import { Editor } from 'editor'
import { initContext } from 'core/ducks/context';
import { getData, updateData } from 'core/ducks/update';
import { ErrorPage } from 'core/views/pages';

class PdfTemplates extends Component {

	constructor(props) {
		super(props);

		this.state = {
			subject: '',
			html: null,
			httpStatus: 200,
			pending: false,
			fieldlist: {}
		};
		this.submitRef = React.createRef();
		this.menuEntries = ['primary_registry', 'created', 'serial', 'licence_number', 'organization'];
	}

	componentDidMount() {
		const { workflow, template } = this.props.match.params;
		this.props.dispatch(initContext({currentWorkflow: workflow, currentTemplate: template}));
		if (workflow && template)
			this.getContent(workflow, template);
	}

	componentDidUpdate(prevProps, prevState) {
		const { workflow, template } = this.props.match.params;
		if ( (prevProps.match.params.workflow && !workflow) || (prevProps.match.params.template && !template) ) {
			this.setState({html: null})
		} else if ( prevProps.match.params.workflow !== workflow || prevProps.match.params.template !== template ) {
			this.getContent(workflow, template);
		}
	}

	setContext = (newContext) => {
		this.setState({...newContext});
	}

	handleSubmit = (event) => {
		event.preventDefault();
		const { workflow, template } = this.props.match.params;
		const { subject, html } = this.state;
		this.props.dispatch(updateData(`admin/pdftemplate/workflow/${workflow}/name/${template}`, {subject, html}));
	}

	getContent = (workflow, template) => {
		this.setState({pending: true}, () => {
			this.props.dispatch( getData(`admin/pdftemplate/workflow/${workflow}/name/${template}`) )
				.then(({subject, html}) => {
					this.props.dispatch( getData(`admin/fieldlist/workflow/${workflow}`) )
						.then(fieldlist => this.setState({subject, html, fieldlist, pending: false}));
				})
				.catch(httpStatus => this.setState({httpStatus, pending: false}));
		});
	}

	render() {
		if (this.state.pending)
			return <Loading/>;
		if (this.state.httpStatus !== 200)
			return <ErrorPage status={this.state.httpStatus}/>;

		const { template } = this.props.match.params;

		const locale = this.props.dispatch(getLocale());

		return (
			<Row>
				<Col>
					<Card>
						<CardHeader className="font-weight-bold">
								<T>pdf templates</T>
								{ template && template !== '' && (
									<>
										<span className="mx-3">-</span>
										<span>{template}</span>
									</>
								)}
						</CardHeader>
						<CardBody>
							<Form onSubmit={this.handleSubmit}>
								<Row>
									<Col>
										<FormGroup>
											<Label htmlFor="certificate_subject"><T>subject</T></Label>
											<Input
												id="certificate_subject"
												name="subject"
												value={this.state.subject}
												onChange={({target}) => this.setState({subject: target.value})}
												disabled={this.state.html === null}
											/>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col>
										<FormGroup>
											<Editor
												locale={locale}
												value={this.state.html || ''}
												onChange={(html) => this.setState({html})}
												name="html"
												setup={(ed) => {
													ed.on('KeyDown', (e) => {
														if ((e.metaKey || e.ctrlKey) && ( String.fromCharCode(e.which).toLowerCase() === 's')) {
															e.preventDefault();
															this.submitRef.current.click();
														}
													});
													ed.addButton('forms', {
														type: 'menubutton',
														text: this.props.i18n.insert_field,
														icon: false,
														menu: [
															{
																text: this.props.i18n.general_fields,
																menu: this.menuEntries.map((field) => ({
																	text: field,
																	onClick: () => ed.insertContent(`[[::${field}]]`)
																}))
															},
															{
																text: this.props.i18n.round,
																menu: ['label', 'starts', 'ends', 'public_date'].map(field => ({
																	text: this.props.i18n[field],
																	onClick: () => ed.insertContent(`[[:round::${field}]]`)
																}))
															},
															{
																text: this.props.i18n.organization,
																menu: ['identifier', 'name', 'address', 'phonenumber', 'email'].map(field => ({
																	text: this.props.i18n[field],
																	onClick: () => ed.insertContent(`[[:org::${field}]]`)
																}))
															},
															{
																text: this.props.i18n.date,
																menu: [{
																	text: 'custom date',
																	onClick: () => ed.insertContent('[[:date::replace-with-sql-to_char-arguments]]')
																}]
															},
															...Object.entries(this.state.fieldlist).map(([key, {label, fields}]) => ({
																text: label,
																menu: Object.entries(fields).map(([name, label]) => ({
																	text: name + ': ' + (label.length > 30 ? label.substring(0,27) + '...' : label),
																	onClick: () => ed.insertContent(`[[${key}:${name}]]`),
																}))
															}))
														]
													});
												}}
												disabled={this.state.html===null}
											/>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col className="text-right">
										<Button type="submit" color="success" ref={this.submitRef}>
											 <i className="fa fa-save mr-2"/><T>save</T>
										</Button>
									</Col>
								</Row>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n.messages
});

PdfTemplates = connect(mapStateToProps)(PdfTemplates);

export default PdfTemplates;