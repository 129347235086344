import { hierarchy } from 'core/model/roles';

export const hasRights = (userRole, role) => {
	if (!role)
		return true;
	if (!userRole)
		return false;
	if (Array.isArray(role))
		return role.includes(userRole);
	if (hierarchy.indexOf(role) === -1)
		return false;
	if (hierarchy.indexOf(role) === hierarchy.length - 1)
		return role === userRole;
	return (hierarchy.indexOf(role) >= hierarchy.indexOf(userRole));
}