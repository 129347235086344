import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import * as roles from 'core/model/roles';
import {
	Filter, FilterGroup, Table, Title, Button, Tbody, Thead, Toolbox, Tr, Search, Pagination, Checkbox
} from 'table';
import { StaticRoutes, DynamicRoutes } from '../../model/routes';
import { buildPath } from 'core/model/lib/urlTools';
import { requestData, getData, getInfo } from 'core/ducks/list';
import { Loading } from 'core/components';
import { ErrorPage } from 'core/views/pages';
import { ExportToCSV } from 'core/model/lib';
import { applicationStatus, applicationStatusStyle } from '../../model/applicationStatus';
import AssignModal from '../modals/assignModal';
import SetDelayModal from '../modals/setDelay';
import T from 'modules/i18n';

class Dams extends Component {

	constructor(props) {
		super(props);
		this.initialState = {
			query: '',
			status: '',
			round: '',
			sort: 'created',
			sort_method: 'desc',
			httpStatus: 200,
			allChecked: false,
			included: [],
			isSetDelayModalOpen: false,
			selectedApplication: {},
		};
		const { params } = props.match;
		const initialize = {
			status: (params && params.status) ? params.status : '',
			round: (params && params.round) ? params.round : '',
		};
		this.state = { ...this.initialState, ...initialize, page: 1, refreshing: false, mounted: true, assign: null };

		this.fields = props.profile.role === roles.AUTHORIZED
			? ['registry_number', 'created', 'last_action', /*'status',*/]
			: ['organization', 'organization_type', 'registry_number', 'created', 'last_action', /*'reviewer', 'status',*/];

		this.layout = {
			organization_type: { type: 'translatable' },
			registry_number: { sortable: true },
			created: { type: 'date', sortable: true },
			last_action: { type: 'date', sortable: true },
			status: { type: 'translatable' },
		};
		if (props.profile.role !== roles.AUTHORIZED) {
			this.layout.reviewer = { sortable: true };
		}

		this.badge_colors = Object.keys(applicationStatusStyle).reduce((obj, key) => ({
			...obj,
			[key]: applicationStatusStyle[key].badge,
		}), {});

		this.fetchData = this.fetchData.bind(this);
		this.createUrl = this.createUrl.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleToolboxReset = this.handleToolboxReset.bind(this);
		this.handleCSVExport = this.handleCSVExport.bind(this);
		this.handleSortChange = this.handleSortChange.bind(this);
	}

	componentDidMount() {
		this.fetchData();

		this.props.dispatch(
			requestData('round', 'flows/round')
		).catch(httpStatus => {
			this.setState({ httpStatus });
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const { params } = this.props.match;
		if (
			params.status !== prevProps.match.params.status ||
			params.round !== prevProps.match.params.round ||
			prevState.query !== this.state.query
		) {
			let state = { page: 1 };
			if (prevState.query !== this.state.query)
				state = Object.assign(state, { query: this.state.query });
			if (params.status !== prevProps.match.params.status)
				state = Object.assign(state, { status: params.status })
			if (params.round !== prevProps.match.params.round)
				state = Object.assign(state, { round: params.round })
			this.setState(state, this.fetchData);
		}
	}

	fetchData() {
		const url = this.createUrl();
		this.props.dispatch(
			requestData('application', url)
		).catch(httpStatus => {
			this.setState({ httpStatus });
		});
	}

	createUrl(scope = '') {
		let { query, sort, sort_method } = this.state;
		let url = `flows/application/page/${this.state.page}/sort/${sort}/sort_method/${sort_method}`;
		query = (query !== '') ? '/query/' + query : '';
		const filters = { status: this.state.status, round: this.state.round };
		let fq = [];
		['status', 'round'].forEach(filter => {
			if (filters[filter] && filters[filter] !== '' && filters[filter] !== 'round') {
				fq.push(`${filter}:${filters[filter]}`);
			}

		});
		if (this.props.onlyPending) {
			fq.push('status:under_review');
			if (this.props.user.role === roles.REVIEWER) {
				fq.push(`reviewer:${this.props.user.uuid}`);
			} else {
				fq.push('status:progressing');
			}
		}
		if (fq.length > 0) {
			fq = fq.join(';');
			fq = `/fq/${fq}`;
		} else {
			fq = '';
		}
		url += query + fq;
		if (scope === 'csv')
			url += '/limit/100000';

		return url + '/fq/round:fragma';
	}

	handlePageChange(page) {
		this.setState({ page }, this.fetchData);
	}

	handleFilterChange(event) {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
			page: 1
		},
			() => {
				let { status, round } = this.state;
				let url = StaticRoutes.Applications;
				if (this.props.profile.role !== roles.AUTHORIZED) {
					url = StaticRoutes.AdminApplications;
				}
				status = status && status !== 'round' ? status : undefined;
				url += status ? `/status/${status}` : '';
				round = round ? round : undefined;
				url += round ? `/round/${round}` : '';
				// this.props.history.push(url);
			});
	}

	handleToolboxReset() {
		this.setState({
			...this.initialState,
			page: 1
		}, this.fetchData);
		let url = StaticRoutes.Applications;
		if (this.props.profile.role !== roles.AUTHORIZED) {
			url = StaticRoutes.AdminApplications;
		}
		// this.props.history.push(url);
	}

	handleCSVExport() {
		const { messages } = this.props.i18n || { messages: {} };
		const url = this.createUrl('csv');
		this.props.dispatch(requestData('csv', url)).then(() => {
			let data = Object.keys(this.props.list.csv.data.values).map((index) => {
				let row = this.props.list.csv.data.values[index];
				return this.fields.map((col) => {
					return (this.layout[col] && this.layout[col].type === 'translatable' && messages[row[col]]) ? messages[row[col]] : row[col];
				});
			});
			let fields = this.fields.map((field) => {
				return messages[field] ? messages[field] : field;
			});
			let csv = new ExportToCSV('submits.csv', fields, data);
			csv.createLink();
		});
	}

	handleSortChange(sort) {
		if (sort === this.state.sort) {
			this.setState({ sort_method: this.state.sort_method === 'asc' ? 'desc' : 'asc' }, this.fetchData);
		} else {
			this.setState({ sort }, this.fetchData);
		}
	}

	viewDetails({ round, index }) {
		let url = DynamicRoutes.Apply;
		if (this.props.profile.role !== roles.AUTHORIZED) {
			url = DynamicRoutes.AdminApply;
		}
		const path = buildPath(url, [round, index]);
		this.props.history.push(path);
	}

	downloadAttachments = ({ index }) => {
		const url = `/api/flows/applicationFiles/application/${index}`;
		window.open(url);
	}

	handleCheckboxChange = (args) => {
		const { index: application_uuid, checked } = args;
		if (this.props.data[application_uuid].status === applicationStatus[3] || this.props.data[application_uuid].status === applicationStatus[4]) {
			const { included } = this.state;
			const state = {};
			const index = included.indexOf(application_uuid);
			if (checked) {
				if (index === -1) {
					state.included = [...included, application_uuid];
				}
			} else {
				if (index !== -1) {
					state.included = [
						...included.slice(0, index),
						...included.slice(index + 1),
					];
				}
			}
			this.setState({ ...state });
		}
	}

	handleAssign = () => {
		if (this.state.included.length > 0)
			this.setState({ assign: this.state.included })
	}

	setDelay = (selectedApplication) => {
		this.setState({
			isSetDelayModalOpen: true,
			selectedApplication
		});
	}

	render() {

		const { data, info, list } = this.props;
		if (this.state.httpStatus !== 200)
			return (<ErrorPage status={this.state.httpStatus} />);
		if (list.application.status !== 200 && list.application.status !== '')
			return (<ErrorPage status={list.application.status} />);

		const { messages } = this.props.i18n || { messages: {} };

		return (
			<Row>
				<Col>
					<Table>
						<Title>
							<T>dams</T>
							<Button type="toolbox" title="filters" className="float-right" />
							{
								// (this.props.user.role === roles.ADMIN || this.props.user.role === roles.EDITOR) &&
								// <Button type="assign" title={messages.assignation || 'Ανάθεση'} className="float-right" onClick={this.handleAssign} />
							}
						</Title>
						<Toolbox onReset={this.handleToolboxReset}>
							<Row>
								<Col xs="12" lg="12" className="form-group text-right">
									<Search placeholder={`${messages.organization} ή ${messages.dam || 'search'}`} onChange={this.handleFilterChange} name="query" />
									<Button type="csv" title={`${messages.export || 'export'} csv`} onClick={this.handleCSVExport}>
										<T>export</T> csv
									</Button>
									<Button type="resetFilters" title={messages['reset filters'] || 'reset filters'}><T>reset</T></Button>
								</Col>
								{
									// <Col xs="12" lg="4">
									// 	<FilterGroup>
									// 		<Filter onChange={this.handleFilterChange} name="round" defaultValue={this.state.round} >
									// 			<option value="">{`${messages.choose || 'choose'} ${messages.round_status || 'round'}`}</option>
									// 			{Object.keys(list.round.data).map((r) => <option key={`option_${r}`} value={r}>{list.round.data[r].label || 'round'}</option>)}
									// 		</Filter>
									// 		<Filter onChange={this.handleFilterChange} name="status" defaultValue={this.state.status} >
									// 			<option value="">{`${messages.choose || 'choose'} ${messages.status || 'status'}`}</option>
									// 			{applicationStatus.map((status) => (
									// 				<option key={`option_${status}`} value={status}>{messages[status] || 'status'}</option>
									// 			))}
									// 		</Filter>
									// 	</FilterGroup>
									// </Col>
								}
							</Row>
						</Toolbox>
						<Thead>
							<Tr
								className="text-capitalize"
								data={['', ...this.fields]}
								layout={this.layout}
								sortBy={this.state.sort}
								sortMethod={this.state.sort_method}
								onClick={this.handleSortChange}
								dam={true}
							/>
						</Thead>
						{
							this.props.pending || !data ? <Loading /> :
								<Tbody refreshing={this.state.refreshing}>
									<Tr
										data={data ? data : {}}
										layout={this.layout}
										order={this.fields}
										badge_colors={this.badge_colors}
									>
										{
											// (this.props.user.role === roles.ADMIN || this.props.user.role === roles.EDITOR) &&
											// <Checkbox allChecked={this.state.allChecked} list={this.state.included} onChange={this.handleCheckboxChange} className="mr-2" />
										}
										<Button type="view" title={messages.view || 'view'} onClick={(data) => { this.viewDetails(data) }} />
										{[roles.ADMIN, roles.EDITOR, roles.REVIEWER].includes(this.props.user.role) &&
											<Button type="file-archive-o" title={messages.attachments || 'attachments'} onClick={(data) => { this.downloadAttachments(data) }} />
										}
										{
											// [roles.ADMIN, roles.EDITOR].includes(this.props.user.role) &&
											// <Button type="hourglass-half" title={messages.delay || 'delay'} onClick={(data) => { this.setDelay(data) }} />
										}
									</Tr>
								</Tbody>
						}
						<Pagination
							className="mx-auto"
							page={info.page}
							total={info.total_pages}
							onClick={(page) => {
								if (page !== info.page)
									this.handlePageChange(page);
							}}
						/>
					</Table>
				</Col>
				{this.state.assign &&
					<AssignModal
						isOpen={this.state.assign ? true : false}
						toggle={() => {
							this.setState({ assign: null });
							this.fetchData();
						}}
						data={this.state.assign}
					/>
				}
				{this.state.isSetDelayModalOpen && (
					<SetDelayModal
						isOpen={this.state.isSetDelayModalOpen}
						toggle={() => this.setState({ isSetDelayModalOpen: false, selectedApplication: {} })}
						application={this.state.selectedApplication}
					/>
				)}
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	profile: state.profile.user,
	list: state.list,
	pending: state.list.application.pending,
	data: getData(state, 'application'),
	info: getInfo(state, 'application'),
	round: getData(state, 'round'),
	viewData: state.update.response,
	http_status: state.update.status,
	i18n: state.i18n,
	user: state.profile.user
});

Dams.propTypes = {
	onlyPending: PropTypes.bool, // Displays only pending applications for logged in reviewer
	withoutToolBox: PropTypes.bool // Does not display the toolbox
};

Dams = connect(mapStateToProps)(Dams);

export default Dams;
