import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { updateData } from 'core/ducks/update';
import { pushNotification } from 'core/ducks/notifications';
import { StaticRoutes } from 'app/model/routes';
import { updateProfile } from 'app/ducks/appProfile';

function withOrganizations(WrappedComponent) {
	class WrapperClass extends Component {

		handleSelect = (identifier) => {
			const { appProfile } = this.props;
			if (identifier === appProfile.active)
				return;
			this.props.dispatch(updateData(`app/profile/set_active/${identifier}`, {}, false))
				.then((reply) => {
					this.props.dispatch(updateProfile())
					if (reply === '') {
						this.props.dispatch({body: 'without active organization', type: 'success'});
					} else {
						this.props.dispatch(
							pushNotification({body: 'active organization changed', placeholders: {org: reply.name}, type: 'success'})
						);
					}
					this.props.history.push(StaticRoutes.App);
				})
				.catch(() => this.props.dispatch(
					pushNotification({body: 'organization update failed', type: 'danger'})
				));
		}

		render() {
			return <WrappedComponent {...this.props} appProfile={this.props.appProfile} handleSelect={this.handleSelect}/>
		}

	}

	const mapStateToProps = (state) => ({
		appProfile: state.appProfile,
		role: state.profile.user.role,
	});

	WrapperClass = connect(mapStateToProps)(WrapperClass);

	return withRouter(WrapperClass);
}

export default withOrganizations;
