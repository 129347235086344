import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class Page404 extends Component {
	render() {
		return (
			<Row className="justify-content-center">
				<Col md="6">
					<span className="clearfix">
						<h1 className="float-left display-3 mr-4">404</h1>
						<h4 className="pt-3">Oops! You're lost.</h4>
						<p className="text-muted float-left">The page you are looking for was not found.</p>
					</span>
				</Col>
			</Row>
		);
	}
}

export default Page404;
