import { StaticRoutes } from './model/routes';
import * as roles from './model/roles';

const menu = [
	{
		name: 'dashboard',
		url: StaticRoutes.AdminDashboard,
		icon: 'fa fa-home',
		role: roles.REVIEWER,
		domain: 'admin',
	},
	{
		name: 'settings',
		url: StaticRoutes.Settings,
		icon: 'fa fa-sliders',
		role: roles.EDITOR,
		domain: 'admin',
		children: [
			{
				name: 'general',
				url: StaticRoutes.GeneralSettings,
				icon: 'fa fa-wrench',
				role: roles.ADMIN,
				domain: 'admin',
			},
			{
				name: 'frontpage',
				url: StaticRoutes.FrontPageSettings,
				icon: 'fa fa-home',
				role: roles.ADMIN,
				domain: 'admin',
			},
			{
				name: 'policy',
				url: StaticRoutes.PolicySettings,
				icon: 'fa fa-lock',
				role: roles.ADMIN,
				domain: 'admin',
			},
			{
				name: 'email templates',
				url: StaticRoutes.Templates,
				icon: 'fa fa-envelope-o',
				role: roles.ADMIN,
				domain: 'admin',
			},
		],
	},
];

export default menu;
