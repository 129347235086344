import React, { Component } from 'react';
import { Row, Col, Container, Badge } from 'reactstrap';

import { applicationStatusStyle } from '../model/constants';
import { Table, Tbody, Tr, Td } from 'css-table';
import T from 'modules/i18n';

class QuickView extends Component {

	render() {
		const { primary_registry, licence_number, round, status, uuid, ...otherProps } = this.props;

		if (!status)
			return null;

		return (
			<Container className="text-light">
				<Row>
					<Col className="d-flex justify-content-end">
						<Badge color={applicationStatusStyle[status].badge}><T>{status}</T></Badge>
					</Col>
				</Row>
				{ licence_number &&
					<Row className="mb-1">
						<Col className="d-flex justify-content-end">
							<span>{licence_number}</span>
						</Col>
					</Row>
				}
				<Row>
					<Col className="d-flex justify-content-between">
						<div>{round}</div>
						<div>{primary_registry}</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table className="text-info">
							<Tbody>
								{ Object.entries(otherProps).map(([label, value], index) => (
									<Tr key={`row_${index}_${uuid}`}>
										<Td className="py-1">{label}</Td>
										<Td className="py-1">{value}</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default QuickView;
