import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Row, Col} from 'reactstrap';
import { bindActionCreators } from 'redux';

import {
	Filter,
	FilterGroup,
	Table,
	Title,
	Button,
	Tbody,
	Thead,
	Toolbox,
	Tr,
	Search,
	Pagination
} from 'table';
import EditUser from '../modals/editUser';
import Alert from 'core/views/modals/alert';
import { ViewUser as View } from '../modals/';
import { Loading, Error } from 'core/components';
import { requestData, getData, getInfo } from 'core/ducks/list';
import { deleteData, updateData, getData as getAdditionalData } from 'core/ducks/update';
import { requestProfile } from 'core/ducks/profile';
import { toggleModal } from 'core/ducks/ui/modal';
import { ExportToCSV } from 'core/model/lib';
import { rolesStyle, rolesList } from '../../model/constants';
import { StaticRoutes } from '../../model/routes';
import * as roles from 'core/model/roles';
import T from 'modules/i18n';

class Users extends Component {

	constructor(props) {
		super(props);

		this.initialState = {
			query: '',
			sort: 'lastname',
			sort_method: 'asc',
			role: '',
			active: ''
		};
		this.state = {...this.initialState, page: 1, refreshing: false};

		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleToolboxReset = this.handleToolboxReset.bind(this);
		this.handleCSVExport = this.handleCSVExport.bind(this);
		this.handleSortChange = this.handleSortChange.bind(this);
		this.changeRole = this.changeRole.bind(this);
		this.changeStatus = this.changeStatus.bind(this);
		this.createUrl = this.createUrl.bind(this);

		const { dispatch } = props;
		this.actions = bindActionCreators({toggleModal, deleteData, updateData, requestProfile}, dispatch);
		this.layout = {
			user_id: {sortable: true},
			firstname: {sortable: true},
			lastname: {sortable: true},
			email: {sortable: true},
			role: {type: 'translatable', select: rolesList, onClick: this.changeRole},
			created: {type: 'date', sortable: true},
			active: {type: 'boolean', select: ['active', 'inactive'], onClick: this.changeStatus},
			address: {type: 'address', sortable: true},
		};
		this.fields = Object.keys(this.layout);
		this.badge_colors = {
			true: 'success',
			false: 'danger',
			...rolesStyle,
		}
	}

	handleFilterChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
			page: 1
		}, () => {
			let { active, role } = this.state;
			let url = StaticRoutes.UsersList;
			role = role ? role : undefined;
			active = active ? active : undefined;
			url += role ? `/role/${role}` : '';
			url += active ? `/status/${active}` : '';
			this.props.history.push(url);
		});
	}

	handleToolboxReset() {
		this.setState({
			...this.initialState,
			page: 1
		}, this.requestData);
		this.props.history.push(StaticRoutes.UsersList);
	}

	handleCSVExport() {
		const { messages } = this.props.i18n || {messages: {}};
		const url = this.createUrl() + '/limit/10000';
		this.props.dispatch(requestData('csv', url)).then(() => {
			let elem = ['', ...this.fields];
			elem.shift();
			let data = Object.keys(this.props.list.csv.data.values).map((index) => {
				let row = this.props.list.csv.data.values[index];
				return elem.map((col) => {
					if ( col === 'status' )
						row[col] = row['active'] ? (messages.active || 'active') : (messages.inactive || 'inactive');
					return (this.layout[col] && this.layout[col].type === 'translatable') ? messages[row[col]] : row[col];
				});
			});
			let fields = elem.map((field) => {
				return messages[field] ? messages[field] : field;
			});
			let csv = new ExportToCSV('users.csv', fields, data);
			csv.createLink();
		});
	}

	handlePageChange(page) {
		this.setState({ page }, this.requestData);
	}

	handleSortChange(sort) {
		if (sort===this.state.sort) {
			this.setState({ sort_method: this.state.sort_method==='asc' ? 'desc' : 'asc'}, this.requestData);
		} else {
			this.setState({ sort }, this.requestData);
		}
	}

	changeRole(index, role) {
		this.actions.updateData(`users/uuid/${index}`, {role}).then(() => {
			this.requestData().then(() => {
				if (this.props.user.uuid === index)
					this.actions.requestProfile();
			});
		});
	}

	changeStatus(index, status) {
		if (this.props.user.uuid === index)
			return;
		this.actions.updateData(`users/uuid/${index}`, {active: status==='active' ? 'true' : 'false'}).then(() => {
			this.requestData();
		});
	}

	requestData() {
		const url = this.createUrl();
		let promise = new Promise((resolve, reject) => {
			this.setState({refreshing: true}, () => {
				this.props.dispatch(requestData('users', url))
					.then(() => {
						this.setState({refreshing: false});
						resolve();
					}).catch(err=> {
						reject();
					});
			});
		});
		return promise;
	}

	createUrl() {
		let { query, sort, sort_method, role, active } = this.state;
		active = active ? (active==='active' ? 'true' : 'false') : '';
		let url = `users/page/${this.state.page}/sort/${sort}/sort_method/${sort_method}`;
		query = (query !== '') ? '/query/' + query : query;
		let fq = '';
		fq += (role && role !== '') ? 'role:' + role + ';' : '';
		fq += (active && active !== '') ? 'active:' + active : '';
		fq = (fq !== '') ? '/fq/' + fq : fq;
		url += query + fq;

		return url;
	}

	componentDidMount() {
		if (this.props.match) {
			let state = {};
			const { params } = this.props.match;
			if (params.status)
				state = {active: params.status};
			if (params.role && params.role !== 'status')
				state = {...state, role: params.role};
			this.setState({...state}, this.requestData);
		} else {
			this.requestData();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.modalOpen !== this.props.modalOpen && !this.props.modalOpen)
			this.requestData();
		const { params } = this.props.match;
		if (
			params.role !== prevProps.match.params.role ||
			params.status !== prevProps.match.params.status ||
			prevState.query !== this.state.query
		) {
			let state = {};
			if (prevState.query !== this.state.query)
				state = Object.assign(state, {query: this.state.query})
			if (params.role !== prevProps.match.params.role)
				if (params.role !== 'status') {
					state = Object.assign(state, {role: params.role});
				} else {
					state = Object.assign(state, {role: ''});
				}
			if (params.status !== prevProps.match.params.status)
				state = Object.assign(state, {active: params.status});
			this.setState({
				...state,
				page: 1
			}, this.requestData);
		}
	}

	render() {
		const { pending, info, status, errorMsg } = this.props;
		const { messages } = this.props.i18n || {messages: {}};
		if (pending) {
			return <Loading />;
		} else if (status !== 200 && status !== '') {
			return <Error status={status} errorMsg={errorMsg} />;
		}

		const data = Object.entries(this.props.data).reduce((obj, [uuid, details]) => ({
			...obj,
			[uuid]: Object.entries(details).filter(([key, value]) => this.fields.indexOf(key) !== -1).reduce((obj2, [key, value]) => ({
				...obj2,
				[key]: value,
			}), {})
		}), {});

		return (
			<div className="animated fadeIn">
				<Row>
					<Col xs="12" lg="12">
						<Table>
							<Title>
								<T>users</T>
								<Button type="toolbox" title="filters" className="float-right"/>
							</Title>
							<Toolbox onReset={this.handleToolboxReset}>
								<Row>
									<Col xs="12" lg="8" className="form-group text-right">
										<Search placeholder={`${messages.search || 'search'}...`} onChange={this.handleFilterChange} name="query" />
										<Button type="csv" title={`${messages.export || 'export'} csv`} onClick={this.handleCSVExport}>
											<T>export</T> csv
										</Button>
										<Button type="resetFilters" title={messages['reset filters'] || 'reset filters'}><T>reset</T></Button>
									</Col>
									<Col xs="12" lg="4">
										<FilterGroup>
											<Filter onChange={this.handleFilterChange} name="active" defaultValue={this.state.active} >
												<option value="">{`${messages.choose || 'choose'} ${messages.status || 'status'}`}</option>
												<option value="active">{messages.active || 'active'}</option>
												<option value="inactive">{messages.inactive || 'inactive'}</option>
											</Filter>
											<Filter onChange={this.handleFilterChange} name="role" defaultValue={this.state.role} >
												<option value="">{`${messages['choose role'] || 'choose role'}`}</option>
												{ Object.keys(rolesList).map((index) =>
													<option key={`option_${rolesList[index]}`} value={rolesList[index]}>{messages[rolesList[index]] || rolesList[index]}</option>
												)}
											</Filter>
										</FilterGroup>
									</Col>
								</Row>
							</Toolbox>
							<Thead>
								<Tr data={['', ...this.fields]} sortBy={this.state.sort} sortMethod={this.state.sort_method} onClick={this.handleSortChange} layout={this.layout} />
							</Thead>
							<Tbody refreshing={this.state.refreshing}>
								<Tr data={data} layout={this.layout} badge_colors={this.badge_colors} order={this.fields}>
									<Button
										type="edit"
										onClick={
											(data) => {
												this.props.dispatch(getAdditionalData(`users/uuid/${data.index}`)).then(() => {
													const joined = {...data, ...this.props.additionalData};
													let {index, ...complete_data} = joined;
													this.actions.toggleModal(true, <EditUser toggle={() => {this.actions.toggleModal()}} data={complete_data} edit={true}/>);
												});
											}
										}
										title={messages.edit || 'edit'}
									/>
									<Button
										type="view"
										onClick={
											(data) => {
												this.props.dispatch(getAdditionalData(`users/uuid/${data.index}`)).then(() => {
													const joined = {...data, ...this.props.additionalData};
													let {index, ...complete_data} = joined;
													this.actions.toggleModal(true, <View data={complete_data}/>);
												});
											}
										}
										title={messages.view || 'view'}
									/>
									{ [roles.ADMIN, roles.EDITOR].includes(this.props.user.role) &&
										<Button
											type="drop"
											onClick={
												(index) => this.actions.toggleModal(true,
													<Alert
														toggle={() => {this.actions.toggleModal()}}
														title="drop confirm"
														message="do you wish to continue"
														onConfirm={() => this.actions.deleteData(`users/uuid/${index}`)}
													/>
												)
											}
											title={messages.drop || 'drop'}
										/>
									}
								</Tr>
							</Tbody>
							<Pagination
								className="mx-auto"
								page={info.page}
								total={info.total_pages}
								onClick={(page) => {
									if (page !== info.page)
										this.handlePageChange(page);
								}}
							/>
						</Table>
					</Col>
				</Row>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	pending: state.list.users.pending,
	data: getData(state, 'users'),
	info: getInfo(state, 'users'),
	status: state.list.users.status,
	errorMsg: state.list.users.errorMsg,
	i18n: state.i18n,
	list: state.list,
	modalOpen: state.ui.modal.modalOpen,
	additionalData: state.update.response,
	user: state.profile.user,
});

Users = connect(mapStateToProps)(Users);

export default Users;
