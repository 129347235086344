import React, { Component } from 'react';

class Td extends Component {

	render() {

		const { className, ...otherProps } = this.props;

		return (
			<div className={`td ${className}`} {...otherProps}>
				{this.props.children}
			</div>
		);
	}
}

export default Td;
