import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import * as roles from 'core/model/roles';
import {
	Table, Title, Button, Tbody, Thead, Toolbox, Tr, Search, Pagination
} from 'table';
import { StaticRoutes } from '../../model/routes';
import { requestData, getData, getInfo } from 'core/ducks/list';
import { Loading } from 'core/components';
import { ErrorPage } from 'core/views/pages';
import { ExportToCSV } from 'core/model/lib';
import T from 'modules/i18n';

class Certificates extends Component {

	constructor(props) {
		super(props);
		this.initialState = {
			query: '',
			status: '',
			sort: 'created',
			sort_method: 'desc',
			httpStatus: 200
		};
		const { params } = props.match;
		const initialize = {
			status: (params && params.status) ? params.status : '',
		};
		this.state = { ...this.initialState, ...initialize, page: 1, refreshing: false };

		this.fields = props.profile.role === roles.AUTHORIZED
			? ['round', 'primary_registry', 'serial', 'subject', 'external_url', 'created']
			: ['round', 'organization_name', 'organization_type', 'primary_registry', 'serial', 'subject', 'external_url',  'created'];

		this.layout = {
			round: { sortable: true },
			organization_name: { sortable: true },
			organization_type: { type: 'translatable', sortable: true },
			primary_registry: { sortable: true },
			serial: { sortable: true },
			subject: { sortable: true },
			external_url: { type: 'link', sortable: true },
			created: { type: 'date', sortable: true },
		};
		if (props.profile.role !== roles.AUTHORIZED) {
			this.layout.reviewer = { sortable: true };
		}

		this.certificateStatus = ['rejected', 'approved', 'completed', 'suspended'];

		this.fetchData = this.fetchData.bind(this);
		this.createUrl = this.createUrl.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleToolboxReset = this.handleToolboxReset.bind(this);
		this.handleCSVExport = this.handleCSVExport.bind(this);
		this.handleSortChange = this.handleSortChange.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		const { params } = this.props.match;
		if (
			params.status !== prevProps.match.params.status ||
			prevState.query !== this.state.query
		) {
			let state = { page: 1 };
			if (prevState.query !== this.state.query)
				state = Object.assign(state, { query: this.state.query });
			if (params.status !== prevProps.match.params.status)
				state = Object.assign(state, { status: params.status })
			this.setState(state, this.fetchData);
		}
	}

	fetchData() {
		const url = this.createUrl();
		this.props.dispatch(
			requestData('certificate', url)
		).catch(httpStatus => {
			this.setState({ httpStatus });
		});
	}

	createUrl(scope = '') {
		let { query, sort, sort_method } = this.state;
		let url = `flows/certificate/page/${this.state.page}/sort/${sort}/sort_method/${sort_method}`;
		query = (query !== '') ? '/query/' + query : '';
		const filters = { status: this.state.status };
		let fq = [];
		['status'].forEach(filter => {
			if (filters[filter] && filters[filter] !== '')
				fq.push(`${filter}:${filters[filter]}`);
		});
		if (fq.length > 0) {
			fq = fq.join(';');
			fq = `/fq/${fq}`;
		} else {
			fq = '';
		}
		url += query + fq;
		if (scope === 'csv')
			url += '/limit/100000';

		return url;
	}

	handlePageChange(page) {
		this.setState({ page }, this.fetchData);
	}

	handleFilterChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
			page: 1
		}, () => {
			let { status } = this.state;
			let url = StaticRoutes.Certificates;
			if (this.props.profile.role !== roles.AUTHORIZED) {
				url = StaticRoutes.AdminCertificates;
			}
			status = status ? status : undefined;
			url += status ? `/status/${status}` : '';
			this.props.history.push(url);
		});
	}

	handleToolboxReset() {
		this.setState({
			...this.initialState,
			page: 1
		}, this.fetchData);
		let url = StaticRoutes.Certificates;
		if (this.props.profile.role !== roles.AUTHORIZED) {
			url = StaticRoutes.AdminCertificates;
		}
		this.props.history.push(url);
	}

	handleCSVExport() {
		const { messages } = this.props.i18n || { messages: {} };
		const url = this.createUrl('csv');
		this.props.dispatch(requestData('csv', url)).then(() => {
			let data = Object.keys(this.props.list.csv.data.values).map((index) => {
				let row = this.props.list.csv.data.values[index];
				return this.fields.map((col) => {
					return (this.layout[col] && this.layout[col].type === 'translatable' && messages[row[col]]) ? messages[row[col]] : row[col];
				});
			});
			let fields = this.fields.map((field) => {
				return messages[field] ? messages[field] : field;
			});
			let csv = new ExportToCSV('certificates.csv', fields, data);
			csv.createLink();
		});
	}

	handleSortChange(sort) {
		if (sort === this.state.sort) {
			this.setState({ sort_method: this.state.sort_method === 'asc' ? 'desc' : 'asc' }, this.fetchData);
		} else {
			this.setState({ sort }, this.fetchData);
		}
	}


	render() {

		const { data, info, list } = this.props;
		if (this.state.httpStatus !== 200)
			return (<ErrorPage status={this.state.httpStatus} />);
		if (list.certificate.status !== 200 && list.certificate.status !== '')
			return (<ErrorPage status={list.certificate.status} />);

		const { messages } = this.props.i18n || { messages: {} };

		return (
			<Row>
				<Col>
					<Table>
						<Title>
							<T>certificates</T>
							<Button type="toolbox" title="filters" className="float-right" />
						</Title>
						<Toolbox onReset={this.handleToolboxReset}>
							<Row>
								<Col xs="12" lg="12" className="form-group text-right">
									<Search placeholder={`${messages.search || 'search'}...`} onChange={this.handleFilterChange} name="query" />
									<Button type="csv" title={`${messages.export || 'export'} csv`} onClick={this.handleCSVExport}>
										<T>export</T> csv
									</Button>
									<Button type="resetFilters" title={messages['reset filters'] || 'reset filters'}><T>reset</T></Button>
								</Col>
								<Col xs="12" lg="4">
								{
									// <FilterGroup>
									// 	<Filter onChange={this.handleFilterChange} name="status" defaultValue={this.state.status} >
									// 		<option value="">{`${messages.choose || 'choose'} ${messages.status || 'status'}`}</option>
									// 		{this.certificateStatus.map((status) => (
									// 			<option key={`option_${status}`} value={status}>{messages[status] || 'status'}</option>
									// 		))}
									// 	</Filter>
									// </FilterGroup>
								}
								</Col>
							</Row>
						</Toolbox>
						<Thead>
							<Tr
								className="text-capitalize"
								data={[...this.fields]}
								layout={this.layout}
								sortBy={this.state.sort}
								sortMethod={this.state.sort_method}
								onClick={this.handleSortChange}
							/>
						</Thead>
						{
							this.props.pending || !data ? <Loading /> :
								<Tbody refreshing={this.state.refreshing}>
									<Tr
										data={data ? data : {}}
										layout={this.layout}
										order={this.fields}
										badge_colors={this.badge_colors}
									>
									</Tr>
								</Tbody>
						}
						<Pagination
							className="mx-auto"
							page={info.page}
							total={info.total_pages}
							onClick={(page) => {
								if (page !== info.page)
									this.handlePageChange(page);
							}}
						/>
					</Table>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	profile: state.profile.user,
	list: state.list,
	pending: state.list.certificate.pending,
	data: getData(state, 'certificate'),
	info: getInfo(state, 'certificate'),
	viewData: state.update.response,
	http_status: state.update.status,
	i18n: state.i18n,
	user: state.profile.user
});

Certificates.propTypes = {
	withoutToolBox: PropTypes.bool // Does not display the toolbox
};

Certificates = connect(mapStateToProps)(Certificates);

export default Certificates;

