import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, Form, FormText, FormGroup, Label, Row, Col, Button } from 'reactstrap';

import { GeneralInput as Input, DateInput } from 'input-fields';
import T from 'modules/i18n';

class EditRound extends Component {
	constructor(props) {
		super(props);
		const { label, starts, ends, open } = props.data;
		this.initialValues = {label, starts, ends, open};
		this.state = {
			values: {
				...this.initialValues,
			}
		};
	}

	handleInputChange = (e) => {
		const { name, type, value, checked } = e.target;
		this.setState({values: {
			...this.state.values,
			[name]: type==='checkbox' ? checked : value,
		}});
	}

	handleFormSubmit = (e) => {
		e.preventDefault();
		const values = Object.entries(this.state.values)
			.filter(([key, value]) => this.initialValues[key] !== value)
			.reduce((obj, [key, value]) => ({
				...obj,
				[key]: value,
			}), {});
		this.props.onEditRoundSubmit(values);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.isOpen && this.props.isOpen) {
			const { label, starts, ends, open } = this.props.data;
			this.initialValues = {label, starts, ends, open};
			this.setState({values: {...this.initialValues}});
		}
	}

	render() {

		const { isOpen, toggle, data } = this.props;
		const { values } = this.state;

		if (Object.keys(data).length === 0)
			return null;

		return (
			<Modal isOpen={isOpen} toggle={toggle}>
				<ModalHeader toggle={toggle}>
					<T>edit round</T>
				</ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleFormSubmit}>
						<FormGroup tag="fieldset" className="text-center">
							<FormText className="font-weight-bold">
								{data.workflow}
							</FormText>
							<FormText>
								{data.description}
							</FormText>
						</FormGroup>
						<FormGroup row>
							<Label htmlFor="new_round_label" sm="2">
								<T>label</T>
							</Label>
							<Col sm="10">
								<Input disabled={data.started} id="new_round_label" name="label" onChange={this.handleInputChange} value={values.label}/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label htmlFor="new_round_starting_date" sm="2">
								<T>starting date</T>
							</Label>
							<Col sm="10">
								<DateInput
									id="new_round_starting_date"
									disabled={data.active || data.passed}
									name="starts"
									onChange={this.handleInputChange}
									value={values.starts}
									showTimeSelect={true}
									dateFormat="d MMMM yyyy (HH:mm)"
									internalDateFormat="yyyy-MM-dd HH:mm:ss"
									startDate={values.starts}
									endDate={values.ends}
									minDate={values.starts}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label htmlFor="new_round_ending_date" sm="2">
								<T>ending date</T>
							</Label>
							<Col sm="10">
								<DateInput
									id="new_round_ending_date"
									disabled={values.starts==='' || data.passed}
									name="ends"
									onChange={this.handleInputChange}
									value={values.ends}
									showTimeSelect={true}
									dateFormat="d MMMM yyyy (HH:mm)"
									internalDateFormat="yyyy-MM-dd HH:mm:ss"
									startDate={values.starts}
									endDate={values.ends}
									minDate={values.starts}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label htmlFor="new_round_open" sm="2">
								<T>open</T>
							</Label>
							<Col sm="10">
								<Input
									type="checkbox"
									id="new_round_open"
									disabled={data.active}
									className="ml-3"
									name="open"
									onChange={this.handleInputChange}
									value={values.open}
									checked={values.open}
								/>
							</Col>
						</FormGroup>
						<Row>
							<Col className="text-right">
								<Button color="info" className="mr-2"><T>submit</T></Button>
							</Col>
						</Row>
					</Form>
				</ModalBody>
			</Modal>
		);
	}
}

EditRound.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	onEditRoundSubmit: PropTypes.func.isRequired,
}

export default EditRound;
