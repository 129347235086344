import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import { format, parseISO } from 'date-fns';
import { el } from 'date-fns/locale';

import "react-datepicker/dist/react-datepicker.css";
registerLocale('el', el);

class DateInput extends Component {

	constructor(props) {
		super(props);
		let value;
		if (props.value && props.value !== '') {
			value = parseISO(props.value);
		}
		this.state = {
			value,
		}

		this.handleChange = this.handleChange.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			let value = this.props.value ? parseISO(this.props.value) : undefined;
			this.setState({value});
		}
	}

	handleChange = (value) => {
		if (value) {
			this.props.onChange({target: {name: this.props.name, value: format(value, this.props.internalDateFormat)}});
		} else {
			this.props.onChange({target: {name: this.props.name, value}});
		}
	}

	render() {

		let { value, minDate, maxDate, startDate, endDate, ...otherProps } = this.props;
		if (startDate && startDate !== '')
			startDate = parseISO(startDate);
		if (endDate && endDate !== '')
			endDate = parseISO(endDate);
		if (minDate && minDate !== '')
			minDate = parseISO(minDate);
		if (maxDate && maxDate !== '')
			maxDate = parseISO(maxDate);

		return (
			<DatePicker
				className="form-control"
				{...otherProps}
				selected={this.state.value}
				onChange={this.handleChange}
				allowSameDay={true}
				startDate={startDate}
				endDate={endDate}
				minDate={minDate}
				maxDate={maxDate}
			/>
		);
	}
}

DateInput.propTypes = {
	value: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	locale: PropTypes.string,
	showTimeSelect: PropTypes.bool,
	dateFormat: PropTypes.string,
	internalDateFormat: PropTypes.string,
};

DateInput.defaultProps = {
	showTimeSelect: false,
	locale: 'el',
	dateFormat: 'd MMMM yyyy',
	internalDateFormat: 'yyyy-MM-dd',
}

export default DateInput;
