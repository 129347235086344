import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';

import { T } from '../../components';
import { toggleLangDropdown } from '../../ducks/ui/menu';
import { changeLocale } from '../../ducks/i18n';
import settings from '../../../../config.json';

class LangDropdown extends Component {

	handleLocaleChange = (locale) => {
		if (locale !== this.props.locale)
			this.props.dispatch(changeLocale(locale));
	}

	render() {
		const { dispatch, multilingual, locales, minimal } = this.props;
		if (!multilingual || locales.length <= 1)
			return null;
		return (
			<Dropdown nav isOpen={this.props.langDropdownOpen} toggle={() => dispatch(toggleLangDropdown())}>
				<DropdownToggle caret size="md" className="no-outline" nav>
					<i className="fa fa-language" style={{fontSize: '130%'}}/>
					<span className="d-none d-sm-inline ml-1">
						{ !minimal && <T>{this.props.locale}</T> }
					</span>
				</DropdownToggle>
				<DropdownMenu>
					{ locales.map(locale => (
						<DropdownItem key={`locale_selector_${locale}`} onClick={() => this.handleLocaleChange(locale)}>
							<i className={`flag-icon flag-icon-${settings.localeFlags[locale]}`}/> <T>{locale}</T>
						</DropdownItem>
					))}
				</DropdownMenu>
			</Dropdown>
		);
	}
}

const mapStateToProps = (state) => ({
	multilingual: state.ui.settings.values.multilingual,
	locale: state.ui.settings.values.locale,
	locales: state.ui.settings.values.locales,
	langDropdownOpen: state.ui.menu.langDropdownOpen,
});

LangDropdown.propTypes = {
	minimal: PropTypes.bool,
};

LangDropdown.defaultProps = {
	minimal: false,
};

LangDropdown = connect(mapStateToProps)(LangDropdown);

export default LangDropdown;
